import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './pages.css';

import logo from '../images/vitaal-vechtdal.svg'


// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';


// login pages that gets exported
export function HRLogin({ setToken, setRenderEmployeeLogin, setRenderHRLogin, setForgotPassword, setRequestInformation }) {

  const navigate = useNavigate();
    const nav_landing_page = () => {
        navigate("/landing_page")
    }

  // state variables definitions and the functions how to assign their values
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");


  // function that calls backend for login (backend path hardecoded for now)
  async function loginUser(credentials) {

    try {
      const user = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
        // convert the data to text. This is either user information if the login is succesful or the login error message.
        .then(data => data.text());
      try {
        // convert to json. This only works if there is user information
        return JSON.parse(user)
      }
      catch(e) {
        // If login has failed. create error
        throw Error(user)
      }
    }
    catch (e) {
      // Log error and send corresponding message to the user
      console.log(e);
      if (e.message === 'noUser') {
        setError(`Onjuiste gebruikersnaam en/of wachtwoord`)
      }
      else if (e.message === 'wrongPassword') {
        setError(`Onjuiste gebruikersnaam en/of wachtwoord`)
      } else {
        setError(`Oeps, er is iets mis gegaan. Neem contact op met de beheerder.`)
      }
    }
  }

  // function that handels the login button and sets the token if login is correct
  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("loginForm").reset()


    const token = await loginUser({
      username,
      password
    });

    setToken(token);
    nav_landing_page()
  }



  return (

<body className='body_login'>

{/* turquoise bar at top of page  */}
<div className="top_bar_login"> </div> 

{/* white bar for logo  */}
<div className="top_bar_logo">
    <img id="theImage_main" src={logo} alt="Vitaal Vechtdal" />

</div>

{/* title of device  */}
<div className="login-page">
    <h1 className="title-login"> Vitaliteit op de werkvloer </h1>
    {/* <button id="button_employee_login" type='button' onClick={() => { setRenderEmployeeLogin(true); setRenderHRLogin(false) }}> Bent u een werknemer? <br></br> Klik hier om uw bedrijfscode in te voeren! </button> */}
</div> 



<div class="login-group">
{/* <div className="login-wrapper"> */}
{/* <div className="main_wrap" > */}


  
{/* white block of information about the device  */}
<div className="wrap_login">
    <div className="info-login-background_hr">
  <div>

  <div className="info_login_position">
  <h3 className="headings-login-description"> Hoe vitaal is jouw bedrijf? </h3><br></br>
    <p className="info-login"> Bedrijven die een vitale en gezonde werkomgeving creëren voor hun medewerkers ervaren niet 
    alleen minder verzuim, maar ook meer werkplezier, tevreden klanten en betere dienstverlening. Een vitale organisatie 
    begint met een dialoog tussen medewerker en werkgever. 
    <br></br> <br></br>
    Het dashboard ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van jouw organisatie, de vitaliteit van de 
    medewerkers en in mogelijke verbeterpunten. </p> 
      {/* <br></br> */}
  <h3 className="headings-login-description"> Meer weten of hulp nodig? </h3>
    <p className="info-login"> 
      {/* Neem contact met ons op */}
      <button id="button_request_information"  type='button' onClick={() => { setRequestInformation(true) }}>Neem contact met ons op</button>
    </p>
    {/* Mail Mirjam Lubbers (<a href = "mailto: m.lubbers@vitaalvechtdal.nl">m.lubbers@vitaalvechtdal.nl</a>), projectleider Werk en Vitaliteit voor meer informatie. </p> */}
  </div>
</div>
</div> 

{/* blue block of login options  */}
<div className="login-background_hr center_items">
{/* <div className="wrap_hr_login">  */}
  <h1 className="login-box-title_hr">  Bedrijf inloggen </h1>
  <form onSubmit={handleSubmit} className="login-box-details" id="loginForm">
        <label>
          <h3 className="login-box-size" style={{ 'textAlign': 'center' }}>Gebruikersnaam</h3>
          <input placeholder="Gebruikersnaam" type="text" autoFocus required={true} onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <h3 className="login-box-size"style={{ 'textAlign': 'center' }}>Wachtwoord</h3>
          <input placeholder="Wachtwoord" type="password" required={true} onChange={e => setPassword(e.target.value)} />
        </label>

          <br />
          <button className='button_login' type="submit"> Inloggen</button>

      </form>
          
      {/* </div> */}
  
     
    <button id="button_forgot_password" type='button' onClick={() => { setRenderEmployeeLogin(false); setRenderHRLogin(false); setForgotPassword(true) }}>Wachtwoord vergeten?</button>
    <button id="button_employee_login" type='button' onClick={() => { setRenderEmployeeLogin(true); setRenderHRLogin(false) }}> Ben je een werknemer? <br></br> Klik hier om je bedrijfscode in te voeren! </button> 
  </div>
  </div>


    {/* error message */}
    {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
    <div  className="marginBottom"  style={{ "marginBottom": "-9vw" }} >   </div>

    {(error !== "") ? (<div className='error'>
      <h3 className="login-error">{error}</h3>
    </div>) : ""}

{/* </div> */}
{/* </div>  */}
</div>
</body>



  )
}

HRLogin.propTypes = {
  setToken: PropTypes.func.isRequired
}