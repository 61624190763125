// DEPENDECIES
// -----------

// npm install survey-react
// https://surveyjs.io/Examples/Library/?id=questiontype-matrix&platform=Reactjs&theme=modern

// necessary imports
import React, { Component } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import './components.css'

Survey.StylesManager.applyTheme("darkblue"); // setting predefined theme

// Survey.settings.titleTags.survey = "h1";
// Survey.settings.titleTags.page = "h2";
// Survey.settings.titleTags.panel = "h3";
// Survey.settings.titleTags.question = "h4";


// creating the class
export class SurveyComponent extends Component {
  constructor(props) {
    super(props);

    //// state and complete component only needed if customary final message/page is desired
    this.state = { isCompleted: false };
    this.myRef = React.createRef()   // Create a ref object 
    this.onCompleteComponent = this.onCompleteComponent.bind(this); // binding is for scoping (it can be accesed in larger scope)
  }
  onCompleteComponent() {
    this.setState({ isCompleted: true });
  }


  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
  }


  render() {

    //// get the passed arguments
    const json = this.props.json; // survey questions as json
    let userId = this.props.userId; // organisation id
    let surveyId = this.props.surveyId; // survey Id (must match the one in the database)
    let employee = this.props.employee; // Whether employee filled in the survey or not (otherwise HR)
    const survey_data = this.props.surveyData; // prefilled in survey data
    const employeeCode = this.props.employeeCode; // employeeCode which HR has to distribute (if chosen that employees fill in the survey)
    const businessSurvey = this.props.businessSurvey; // business survey is handled differently

    // create the survey model using the json input
    const survey = new Survey.Model(json);
    this.survey = survey;
    // if (userId === 14) {
    //   survey.mode = 'display';
    // }

    // some styling adjustments
    survey.onUpdateQuestionCssClasses.add(function (survey, options) {
      var classes = options.cssClasses
      //classes.root = "sq-root";
      classes.title = "sq-title"
      //classes.item = "sq-item";
      classes.label = "sq-label";

      if (options.question.isRequired) {
        classes.title += " sq-title-required";
        classes.root += " sq-root-required";
      }
      if (options.question.getType() === "checkbox") {
        classes.root += " sq-root-cb";
      }
      if (options.question.getType() === "rating") {
        classes.root += " sq-rating";
      }

    });

    // set tool language to NL: https://github.com/surveyjs/survey-library/blob/master/src/localization/dutch.ts
    survey.locale = "nl";

    // posting survey results to backend (and database)
    survey.onComplete.add(function (sender, options) {

      // console.log(JSON.stringify(sender.data));
      // console.log(sender.surveyId);

      // if survey pre-data existed: update DB
      let update = false; // default =  post new entry
      if (survey_data) {

        var length_predata = Object.keys(survey_data).length;
        // console.log(survey_data);
        // console.log(length_predata);

        if (length_predata != 0) {
          update = true;
        }
      }
      let hr = false
      if (employee === false)  {
        hr = true
      }

      const body = {
        surveyResults: sender.data,
        surveyId: surveyId,
        organizationId: userId,
        updateSurvey: update,
        HR: hr
      }

      // console.log(body);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };

      // business survey is handled differently
      if (businessSurvey) {
        fetch('/business_update', requestOptions)
          .then(response => response.json())
          .then(data => data.json());
      }
      else {
        fetch('/survey_post', requestOptions)
          .then(response => response.json())
          .then(data => data.json());
      }

      // scroll to the top after completing
      window.scrollTo({ top: 0, behavior: "smooth" });
    });

    // if data is provided, prefilled data will be shown
    if (survey_data) {

      // console.log("survey with pre-data!");

      survey.data = survey_data;
    }

    // option to show filled in survey before sending the answers.
    // survey.showPreviewBeforeComplete = 'showAnsweredQuestions';


    // create the survey render component
    var surveyRender = !this.state.isCompleted ? (
      <Survey.Survey
        model={survey}
        showCompletedPage={true}
        // isSinglePage={true}
        onComplete={this.onCompleteComponent}
      />
    ) : null;

    window.scrollTo(0,0)
    if (employeeCode) { // thank you message for HR including employeecode
      // create component that gets rendered after completing the survey
      var onCompleteComponent = this.state.isCompleted ? (
        <div ref={this.myRef}>
          <div className="thank_you_background_medewerker">
            <h1 className="thanks_survey_message_medewerker" style={{ textAlign: "center" }}>
              Dank voor je medewerking! <br></br>
              Vergeet niet om de vragenlijst in te laten vullen door je medewerkers. <br></br>
              Zie code en link bovenaan de pagina.
            </h1>
          </div>


          <div style={{ "marginBottom": "5cm" }} >   </div>

        
        </div>
      ) : null;
    }
    else if (employee === true) {  // thank you message for employees todo
      // create component that gets rendered after completing the survey
      var onCompleteComponent = this.state.isCompleted ? (
        <div ref={this.myRef}>
          <div className="thank_you_background_employee">
            <h1 className="thanks_survey_message_employee" style={{ textAlign: "center" }}>Dank voor je medewerking!</h1>
          </div>


          <div style={{ "marginBottom": "5cm" }} >   </div>

        
        </div>
      ) : null;
    }
    else {  // thank you message for HR
      // create component that gets rendered after completing the survey
      var onCompleteComponent = this.state.isCompleted ? (
        <div ref={this.myRef}>
          <div className="thank_you_background">
            <h1 className="thanks_survey_message" style={{ textAlign: "center" }}>Dank voor je medewerking!</h1>
          </div>


          <div style={{ "marginBottom": "5cm" }} >   </div>

        
        </div>
      ) : null;
    }

    





// POP-UP  DESCRIPTION   FUNCTION 
//     test Nathalia popup 
// Add a custom 'popupdescription' property to questions of all types and to pages
Survey.Serializer.addProperty("question", "popupdescription:text");
Survey.Serializer.addProperty("page", "popupdescription:text");

 function showDescription(questionDescription) {
  document.getElementById("questionDescriptionText").innerHTML = questionDescription.popupdescription;
  // $("#questionDescriptionPopup").modal(); //nathalia help 
}

    
survey.onAfterRenderQuestion.add(function (survey, options) {
  // Do nothing if a question contains no description to show in a modal popup
  if (! options.question.popupdescription) 
    return;
  
  // Create a 'More Info' button to invoke a modal popup
  var btn = document.createElement("button");
  btn.type = "button";
  btn.className = "btn btn-info btn-xs";
  btn.innerHTML = "Meer Info";
  var question = options.question;
  btn.onclick = function () {
    showDescription(question);
  }

  // Insert the created 'More Info' button into the rendered question's header
  var header = options.htmlElement.querySelector("h5");
  if (! header) 
    header = options.htmlElement;
  
  var span = document.createElement("span");
  span.innerHTML = "  ";
  header.appendChild(span);
  header.appendChild(btn);
});


survey.onAfterRenderPage.add(function (survey, options) {
  // Do nothing if a page contains no description to show in a modal popup
  if (! options.page.popupdescription) 
    return;
  
  // Create a 'More Info' button to invoke a modal popup
  var btn = document.createElement("button");
  btn.type = "button";
  btn.className = "btn btn-info btn-xs";
  btn.innerHTML = "Meer Info";
  btn.onclick = function () {
    showDescription(survey.currentPage);
  }
  // Insert the created 'More Info' button into the rendered page's header
  var header = options.htmlElement.querySelector("h4");
  var span = document.createElement("span");
  span.innerHTML = "  ";
  header.appendChild(span);
  header.appendChild(btn);
});




    return (
      <div ref={this.myRef}>
        
        {surveyRender}
        
        {onCompleteComponent} 

      </div>
      
    );

  }
}