export const verzuim = [
    {
        name: 'Oktober 2021',
        'eigen bedrijf': 3.5,
        regio: 1.5,
        "jouw branche": 1.0,
    },
    {
        name: 'November 2021',
        'eigen bedrijf': 3.5,
        regio: 1.5,
        "jouw branche": 1.0,
    },
    {
        name: 'December 2021',
        'eigen bedrijf': 4.0,
        regio: 2.5,
        "jouw branche": 2.0,
    },
    {
        name: 'Januari 2022',
        'eigen bedrijf': 3.0,
        regio: 2.1,
        "jouw branche": 1.0,
    },
    {
        name: 'Februari 2022',
        'eigen bedrijf': 2.5,
        regio: 2.0,
        "jouw branche": 2.0,
    },
    {
        name: 'Maart 2022',
        'eigen bedrijf': 2.0,
        regio: 1.5,
        "jouw branche": 1.0,
    },
];