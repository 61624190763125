import './pages.css'

// import data
import * as thuiswerken from '../dummy_data/flexibleWorking' // static data
import * as verzuim from '../dummy_data/verzuim' // static data

// import graph modules
// import { SimpleLineChart_B,  HorizontalBarChart_B, SingleBarChart_B, SimpleBarChart_B } from '../components/graphs'
// import { SimpleLineChart,  HorizontalBarChart, SingleBarChart, SimpleBarChart } from '../components/graphs'
import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";


import { HorizontalBarChart_B, SimpleBarChart_B, SingleBarChart_B , SimpleLineChart_B} from '../components/graphs'
import * as standard_questions from '../survey_input/survey_questions'


// define different dataKeys, changable by onClick
const colors = ["#3CB9D5","#41337A", "#3897AC"]

const regionDict = {
  'all': null,
  'Vechtdal': [7000, 8000],
  'test1': [7700, 7720],
  'test2': [7720, 7800]
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

// S T A R T   O F   T H E   F U N C T I O N 
// -----------------------------------------

// the function which will be rendered
export function History_benchmark_page(props) {

  const navigate = useNavigate();
    const benchmark = () => {
        navigate("/benchmark")
    }

  // state variables definitions and the functions how to assign their values
  const [forms, setForms] = React.useState(null); // forms related to global Id from DB
  const [results, setResults] = React.useState(null);
  const [dashboard, setDashboard] = React.useState(null);
  const [benchMarkData, setBenchMarkData] = React.useState(null);
  const [benchMarkData_1, setBenchMarkData_1] = React.useState(null);
  const [benchMarkData_2, setBenchMarkData_2] = React.useState(null);
  const [benchMarkData_3, setBenchMarkData_3] = React.useState(null);
  const [dataKeys, setDataKeys] = React.useState(['benchmark']);
  const [publish, setPublish] = React.useState(null);
  const [publishButton, setPublishButton] = React.useState(null);
  const [surveyClosed, setSurveyClosed] = React.useState(null);
  const [closeSurveyButton, setCloseSurveyButton] = React.useState(null);
  const [companiesFilledIn, setCompaniesFilledIn] = React.useState(null); // the companies and counts of them that filled in the survey
  const [companiesFilledInTable, setCompaniesFilledInTable] = React.useState(null); // the companies and counts of them that filled in the survey
  const location = useLocation(); // also rerender results when switching pages

  // Initialize region and industry
  const [region, setRegion] = React.useState(null);
  const [industry, setIndustry] = React.useState(null);
  const [availableIndustries, setAvailableIndustries] = React.useState(null)
  const [formSelect, setFormSelect] = React.useState(null)
  const [availableForms, setAvailableForms] = React.useState(null)

  let originalGlobalFormID = props.globalFormID;
  // use globalFormID as an copy of the original so that its global state cannot be altered when selecting a form
  const [globalFormID, setGlobalFormID] = React.useState(originalGlobalFormID)


  // importing state variables from the passed arguments
  let userID = props.userID;

  function RegionSelect(props) {
    let region = props.region
    return(
  <div className="select">
    {/* region selector */}
    <label className="select_region" htmlFor="region">Selecteer Een Regio </label>
    <select className="benchmark-filter_1" key="region" name="region" defaultValue= {region} id="region" onChange={handleRegionSelect}>
      <option key="all" value="all">Alle Regio's</option>
      <option key="vechtdal" value="Vechtdal">Vechtdal</option>
      {/* <option value="test1">Test 1</option>
      <option value="test2">Test 2</option> */}
    </select>
  </div>
    )
  }

  function IndustrySelect(props) {
    let industry = props.industry
    let availableIndustries = props.availableIndustries
    if (availableIndustries !== null) {
      let options = []
      options.push(<option key="null" value={null}>Alle branches</option>)
      for (let i in availableIndustries) {
        let industry = availableIndustries[i]
        options.push(<option key={industry} value={industry}>{industry}</option>)
      }
      // let defaultValue = industry
      // if (defaultValue === null) {
      //   defaultValue = 
      // }
      return (
        <div className='select'>
          <div className="benchmark-filter_2">
          <label htmlFor="industry"> Selecteer een branche </label>
          <select key="industry" name="industry" defaultValue={industry} id="industry" onChange={handleIndustrySelect}>
            {options}
          </select>
          </div>
        </div>
      )
    }
  }


  // fetch survey IDs from data base (through api)
  React.useEffect(() => {
    setPublish(null)
    if (globalFormID !== null & globalFormID.length !== 0) {
      fetch(`/survey_forms?globalFormId=${globalFormID}&standard=true`)
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => setForms(json.result)); // this is the object retrieved (provided by the response in the server)
    }
}, [globalFormID]); // excecutes hook when arguments is [] changes
  
// update publish_results
React.useEffect(() => {
  if (publish === true) {
    fetch(`/publish_results?globalFormId=${globalFormID}&publish=true`)
  }
  else if (publish === false) {
    fetch(`/publish_results?globalFormId=${globalFormID}&publish=false`)
  }
}, [publish]); // executes hook when arguments is [] changes

// update close_survey
React.useEffect(() => {
  if (surveyClosed === true) {
    fetch(`/close_survey?globalFormId=${globalFormID}&closed=true`)
  }
  else if (surveyClosed === false) {
    fetch(`/close_survey?globalFormId=${globalFormID}&closed=false`)
  }
}, [surveyClosed]); // executes hook when arguments is [] changes

  // fetch possible industries from database (through api)
  React.useEffect(() => {
    let zipCodes
    if (region !== null) {
      zipCodes = regionDict[region]  
    }
    if (globalFormID !== null & globalFormID.length !== 0) {
      if (region !== null) {
        fetch(`/industries?zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&globalFormId=${globalFormID}&history=true`)
          .then((res) => res.json()) // this is the http response (required)
          .then((json) => setAvailableIndustries(json.industries)); // this is the object retrieved (provided by the response in the server)
      }
      else {
        fetch(`/industries?globalFormId=${globalFormID}&history=true`)
          .then((res) => res.json()) // this is the http response (required)
          .then((json) => setAvailableIndustries(json.industries)); // this is the object retrieved (provided by the response in the server)
      }
    }
  }, [region, globalFormID]); // excecutes hook when arguments is [] changes

  // fetch possible forms from database (through api)
  React.useEffect(() => {
    if (originalGlobalFormID !== null) {
      fetch(`/availableForms?globalFormId=${globalFormID}&vvAdmin=true`)
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => {
          setAvailableForms(json.availableForms)
        }); // this is the object retrieved (provided by the response in the server)
    }
  }, [globalFormID]); // excecutes hook when arguments is [] changes

  // fetch possible forms from database (through api)
  React.useEffect(() => {
    let benchmarkName = 'benchmark'
    if (region !== null & industry !== null) {
      benchmarkName = region + ', ' + industry
    }
    else if (region !== null) {
      benchmarkName = region
    }
    else if (industry !== null) {
      benchmarkName = industry
    }
    setDataKeys([benchmarkName])
  }, [region, industry]); // excecutes hook when arguments is [] changes

  // fetch benchmark from data base (through api) 
  const updateBenchMarkData = async (globalFormID, region, industry) => {
    // industry should be string, region should be [<zipLower>,<zipUpper>]
    // If globalFormID, region and industry are provided
    let zipCodes
    if (region !== null) {
      zipCodes = regionDict[region]  
    }
    if (globalFormID != null & region !== null & industry !== null & globalFormID.length !== 0) {
      await fetch(`/benchmarkapi?globalFormId=${globalFormID}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
      await fetch(`/benchmarkapi?globalFormId=${globalFormID-1}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_1(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
      await fetch(`/benchmarkapi?globalFormId=${globalFormID-2}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_2(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
      await fetch(`/benchmarkapi?globalFormId=${globalFormID-3}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_3(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
    }
    // If globalFormID and region are provided
    else if (globalFormID != null & region !== null & globalFormID.length !== 0) {
      await fetch(`/benchmarkapi?globalFormId=${globalFormID}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
        await fetch(`/benchmarkapi?globalFormId=${globalFormID-1}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_1(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
        await fetch(`/benchmarkapi?globalFormId=${globalFormID-2}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_2(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
        await fetch(`/benchmarkapi?globalFormId=${globalFormID-3}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_3(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
    }
    // If globalFormID and industry are provided
    else if (globalFormID != null & industry !== null & globalFormID.length !== 0) {
      await fetch(`/benchmarkapi?globalFormId=${globalFormID}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
        await fetch(`/benchmarkapi?globalFormId=${globalFormID-1}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_1(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
        await fetch(`/benchmarkapi?globalFormId=${globalFormID-2}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_2(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
        await fetch(`/benchmarkapi?globalFormId=${globalFormID-3}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_3(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
    }
    // If only globalFormID is provided
    else if (globalFormID !== null & globalFormID.length !== 0) {
      await fetch(`/benchmarkapi?globalFormId=${globalFormID}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
      }); // this is the object retrieved (provided by the response in the server)
      await fetch(`/benchmarkapi?globalFormId=${globalFormID-1}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_1(json.benchmark);
      }); // this is the object retrieved (provided by the response in the server)
      await fetch(`/benchmarkapi?globalFormId=${globalFormID-2}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_2(json.benchmark);
      }); // this is the object retrieved (provided by the response in the server)
      await fetch(`/benchmarkapi?globalFormId=${globalFormID-3}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData_3(json.benchmark);
      }); // this is the object retrieved (provided by the response in the server)
    }
  }

  React.useEffect(() => {
    if (benchMarkData === null) {
      updateBenchMarkData(globalFormID, region, industry)
    }

  }, [globalFormID]); // execute hook when arguments in [] changes
  
  // setRegion when region is selected with the select box
  function handleRegionSelect(e) {
    let newRegion = e.target.value
    if (newRegion === 'all') {
      newRegion = null
    }
    setRegion(newRegion)
    updateBenchMarkData(globalFormID, newRegion, industry)
  }

  // setIndustry when region is selected with the select box
  function handleIndustrySelect(e) {
    let newIndustry = e.target.value
    if (newIndustry === 'Alle branches') {
      newIndustry = null
    }
    setIndustry(newIndustry)
    updateBenchMarkData(globalFormID, region, newIndustry)
  }


  // function that creates the dashboard with graphs based on the results in the DB
  React.useEffect(() => {
    // check if results is not full of empty lists. This happens when a survey has not been filled in yet
    // if noResults remains true the survey hasn't been filled in yet
    // if there are results (present AND loaded) and there is benchmarkdata loaded, create json_dashboard
    if (benchMarkData === null  || forms === null) {
      setDashboard(
        <div className="tot">
          {<IndustrySelect industry={industry} availableIndustries={availableIndustries} />}
          {<h1> Er is nog geen data voor de monitor beschikbaar </h1>}
        </div>
      )
    }
    else if ((!benchMarkData && Object.keys(benchMarkData).length === 0) && (!benchMarkData_1 && Object.keys(benchMarkData_1).length === 0) && (!benchMarkData_2 && Object.keys(benchMarkData_2).length === 0) && (!benchMarkData_3 && Object.keys(benchMarkData_3).length === 0)) {
      setDashboard(
        <div className="tot">
          {<IndustrySelect industry={industry} availableIndustries={availableIndustries} />}
          {<h1> Er is nog geen data voor de monitor beschikbaar </h1>}
        </div>
      )
    }
    else {
      // TO DO
      // check results and benchmarks! 
      // size and form + question IDs!



      // C R E A T E   D A S H B O A R D   G R A P H S   G R O U P E D   P E R   T H E M E   A N D   Q U E S T I O N   T Y P E
      // ---------------------------------------------------------------------------------------------------------------------

      let json_dashboard = {};
      let formTitle = forms[0].name; // only title from first question in the form is needed
      let theDashboardPage = [];  // array that contains all dashboard components 
      let graph = null
      let margin = 0
      let key = null
      let formSectionCounter = 1
      let graphCounter = 0  // Counts the number of graphs. Helps with ensuring graphs get unique ids (Because graphs are limited to three questions)

      // let benchmarkName = 'benchmark'
      // if (filter !== null) {
      //   benchmarkName = filter
      // }
      let benchmarkName = 'benchmark'
      if (region !== null & industry !== null) {
        benchmarkName = region + ', ' + industry
      }
      else if (region !== null) {
        benchmarkName = region
      }
      else if (industry !== null) {
        benchmarkName = industry
      }
      setDataKeys([benchmarkName])

      if (forms[0]['published'] === 1) {
        setPublish(true)
      }
      else {
        setPublish(false)
      }

      if (forms[0]['closed'] === 1) {
        setSurveyClosed(true)
      }
      else if (forms[0]['closed'] === 0) {
        setSurveyClosed(false)
      }

      // // add subform title to dashboard
      // loop over de subforms
      for (const iQuestion in forms) {
        const question = forms[iQuestion]

        const questionType = question.questionType; // question type relates to type of graph
        const questionId = question.idQuestion; // the id of the question
        const questionTopic = question.topic;   // the topic of the question
        let questionTitle = question.questionHR;  // the question for HR people
        const resultFormat = question.questionType;
        const answers_json = question.answers_json; // only non-null for specific question types
        const QuestionFormSection = question.FormSection  // the formSection of the current question. e.g. 'Standaard vragen' or 'Thema vragen'
        const formTitle = question.name

        // ============================= CREATE GRAPHS =============================

        // ========= Likert & percentage questions ===============
        if (questionType === 'likert' | questionType === 'percentage') {
          // graph = <HorizontalBarChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
          let dataQuest = []
          
          // dataQuest.push({  //testing
          //   'name': '90',
          //   [benchmarkName]: Math.round(benchMarkData[questionId]*100)/100,
          //   'benchmarkName': benchmarkName
          // }) // testing
          if (benchMarkData_3) {  // 3 surveys back
            if (Object.keys(benchMarkData_3).length !== 0) {
              let date = null
              for (let i in availableForms) {
                if (availableForms[i]['idForm'] === globalFormID - 3) {
                  let dateParsed = new Date(availableForms[i]['startDate'])
                  date = `${dateParsed.getMonth()+1}-${dateParsed.getFullYear()}`
                }
              }
              dataQuest.push({
                'name': date,
                [benchmarkName]: Math.round(benchMarkData_3[questionId]*100)/100,
                'benchmarkName': benchmarkName
              })
            }
          }
          if (benchMarkData_2) {  // 2 surveys back
            if (Object.keys(benchMarkData_2).length !== 0) {
              let date = null
              for (let i in availableForms) {
                if (availableForms[i]['idForm'] === globalFormID - 2) {
                  let dateParsed = new Date(availableForms[i]['startDate'])
                  date = `${dateParsed.getMonth()+1}-${dateParsed.getFullYear()}`
                }
              }
              dataQuest.push({
                'name': date,
                [benchmarkName]: Math.round(benchMarkData_2[questionId]*100)/100,
                'benchmarkName': benchmarkName
              })
            }
          }
          if (benchMarkData_1 !== null) {  // last one
            if (Object.keys(benchMarkData_1).length !== 0) {
              let date = null
              for (let i in availableForms) {
                if (availableForms[i]['idForm'] === globalFormID-1) {
                  let dateParsed = new Date(availableForms[i]['startDate'])
                  date = `${dateParsed.getMonth()+1}-${dateParsed.getFullYear()}`
                }
              }
              dataQuest.push({
                'name': date,
                [benchmarkName]: Math.round(benchMarkData_1[questionId]*100)/100,
                'benchmarkName': benchmarkName
              })
            }
          }
          if (benchMarkData !== null) {  // last one
            let date = null
            for (let i in availableForms) {
              if (availableForms[i]['idForm'] === globalFormID) {
                let dateParsed = new Date(availableForms[i]['startDate'])
                date = `${dateParsed.getMonth()+1}-${dateParsed.getFullYear()}`
              }
            }
            dataQuest.push({  // current
              'name': date,
              [benchmarkName]: Math.round(benchMarkData[questionId]*100)/100,
              'benchmarkName': benchmarkName
            })
          }
          graph = <SimpleLineChart_B xAxis="name" xYaxis="" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
        }

        // ========= multiple_result questions ===============
        else if (questionType === 'multiple_result') {
          let dataQuest = []
          if (benchMarkData_3 !== null) {
            let date = null
            for (let i in availableForms) {
              if (availableForms[i]['idForm'] === globalFormID-3) {
                date = new Date(availableForms[i]['startDate'])
              }
            }
            questionTitle = Object.keys(JSON.parse(question.questionHR))[0]
            let titles = Object.values(JSON.parse(question.questionHR))[0]
            // fill the list of dicts with with question results. Only one question (multiple columns)
            // goes into this list before it gets cleared
            for (let i in Object.keys(titles)) {
              if (benchMarkData_3 && benchMarkData_3[questionId]) {
                i = parseInt(i)
                dataQuest.push({
                  'name': standard_questions.monthTitle(titles[i], date),
                  [benchmarkName]: Math.round(benchMarkData_3[questionId][i]*100)/100,
                  'benchmarkName': benchmarkName
                })
              }
            }
            graph = <SimpleLineChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
          }
          if (benchMarkData_2 !== null) {
            let date = null
            for (let i in availableForms) {
              if (availableForms[i]['idForm'] === globalFormID-2) {
                date = new Date(availableForms[i]['startDate'])
              }
            }
            questionTitle = Object.keys(JSON.parse(question.questionHR))[0]
            let titles = Object.values(JSON.parse(question.questionHR))[0]
            // fill the list of dicts with with question results. Only one question (multiple columns)
            // goes into this list before it gets cleared
            for (let i in Object.keys(titles)) {
              if (benchMarkData_2 && benchMarkData_2[questionId]) {
                i = parseInt(i)
                dataQuest.push({
                  'name': standard_questions.monthTitle(titles[i], date),
                  [benchmarkName]: Math.round(benchMarkData_2[questionId][i]*100)/100,
                  'benchmarkName': benchmarkName
                })
              }
            }
            graph = <SimpleLineChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
          }
          if (benchMarkData_1 !== null) {
            let date = null
            for (let i in availableForms) {
              if (availableForms[i]['idForm'] === globalFormID-1) {
                date = new Date(availableForms[i]['startDate'])
              }
            }
            questionTitle = Object.keys(JSON.parse(question.questionHR))[0]
            let titles = Object.values(JSON.parse(question.questionHR))[0]
            // fill the list of dicts with with question results. Only one question (multiple columns)
            // goes into this list before it gets cleared
            for (let i in Object.keys(titles)) {
              if (benchMarkData_1 && benchMarkData_1[questionId]) {
                i = parseInt(i)
                dataQuest.push({
                  'name': standard_questions.monthTitle(titles[i], date),
                  [benchmarkName]: Math.round(benchMarkData_1[questionId][i]*100)/100,
                  'benchmarkName': benchmarkName
                })
              }
            }
            graph = <SimpleLineChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
          }
          if (benchMarkData !== null) {  // last one
            let date = null
            for (let i in availableForms) {
              if (availableForms[i]['idForm'] === globalFormID) {
                date = new Date(availableForms[i]['startDate'])
              }
            }
            questionTitle = Object.keys(JSON.parse(question.questionHR))[0]
            let titles = Object.values(JSON.parse(question.questionHR))[0]
            // fill the list of dicts with with question results. Only one question (multiple columns)
            // goes into this list before it gets cleared
            for (let i in Object.keys(titles)) {
              if (benchMarkData && benchMarkData[questionId]) {
                i = parseInt(i)
                dataQuest.push({
                  'name': standard_questions.monthTitle(titles[i], date),
                  [benchmarkName]: Math.round(benchMarkData[questionId][i]*100)/100,
                  'benchmarkName': benchmarkName
                })
              }
            }
            graph = <SimpleLineChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
          }
        }
        

        //============ construct html ==============
        if (graph) {
          // add graph to dashboard
          key = `${questionTopic}_${questionType}_${questionTitle}`;
          // theDashboardPage.push(<RegionSelect region={region}/>)
          theDashboardPage.push(<IndustrySelect industry={industry} availableIndustries={availableIndustries} />)
          theDashboardPage.push(
            <div className='whiteblock' key={key}>
              <h3 className="dashboard-h3">{questionTitle}</h3>
              <div  className="marginBottom" style={{ "marginBottom": `${margin}vw` }} >   </div>
              {graph}
            </div>);


          // add spacing between questions
          key = `${questionTopic}_${questionType}_${questionTitle}_spacing`;
          theDashboardPage.push(
            <div  className="marginBottom"  key={key} style={{ "marginBottom": "2vw" }} ></div>
          )
          graph = null
          margin = 0
          // reset list of questions when topic has changed and graph has been created
        }

        



      } // end of question loop


      // add spacing at the end
      theDashboardPage.push(
        <br key="last_spacing" />
      )

      // set the dashboard state
      setDashboard(theDashboardPage);

    } // end of if "results" is defined
  }, [results, benchMarkData, benchMarkData_1, benchMarkData_2, benchMarkData_3, region, industry, availableIndustries, forms, globalFormID]); // end of hook to create dashboard

  // if there is no dashboard created yet, show loading message
  var dashboardPage = dashboard;
  if (!dashboard) {
    dashboardPage = <h1>Dashboard wordt geladen</h1>
  }


  // used for creating an html select for selecting a form from the available forms
  React.useEffect(() => {
    if (availableForms !== null) {
      let options = []
      // make an option for each available option
      for (let i in availableForms) {
        let form = availableForms[i]
        options.push(<option key={form.startDate} value={form.idForm}>{`${form.name} ${new Date(form.startDate).getFullYear()}`}</option>)
      }
    }
  }, [availableForms]); // executes hook when arguments is [] changes


  
  // return the dashboard in a div
  return (
    <div>
    <div className="tot">
      <div className="view_employee_survey_history">
        Terug naar de benchmark-pagina
        <br></br>
        <a className="button_back_benchmark_history" onClick={benchmark}> ←  Ga terug  </a>
      </div>

      {dashboardPage}

      
      <button className="Afdrukken_history" onClick={window.print}>Afdrukken</button>

    </div>

    <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

    </div>

    
    </div>

  )
}