// import survey modules
import { SurveyComponent } from '../components/survey'
import * as json from '../survey_input/surveys/survey1';
import * as bedrijf from '../survey_input/surveys/bedrijfgegevens1';
import React, { useEffect } from 'react';

// the function which will be rendered

//json2 renders after json1, so it means by default the page will jump to questionaire 2

export function survey_page() {

    return (
        <div className="survey" >
            <SurveyComponent json={json.survey_json1} />
            <SurveyComponent json={json.survey_json2} />
        </div>
    )

};

// the function which will be rendered
export function bedrijfsgegevens_page() {

    return (
        // <div style="border-type=solid; border-color=white; border-right-width=50pt">
        
        <div className="survey" >
            
            <SurveyComponent json={bedrijf.survey_json1} />
        </div>
        
    )
};

