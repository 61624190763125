import React, { useState } from 'react';
import './pages.css';

import logo from '../images/vitaal-vechtdal.svg'


// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';


// login pages that gets exported
export function Login({ setRenderEmployeeLogin, setRenderHRLogin, setRequestInformation }) {

  // state variables definitions and the functions how to assign their values
  const [error, setError] = useState("");



  return (

    <body className='body_login'>

    {/* turquoise bar at top of page  */}
    <div className="top_bar_login"> </div> 

    {/* white bar for logo  */}
    <div className="top_bar_logo">
        <img id="theImage_main" src={logo} alt="Vitaal Vechtdal" />

    </div>

    {/* title of device  */}
    <div className="login-page">
        <h1 className="title-login"> Vitaliteit op de werkvloer </h1>
    </div> 


    <div class="login-group">

      
    {/* white block of information about the device  */}
    <div className="wrap_login">
        <div className="info-login-background_main">
          <div>

      <div className="info_login_position">
      <h3 className="headings-login-description"> Hoe vitaal is jouw bedrijf? </h3><br></br>
        <p className="info-login"> Bedrijven die een vitale en gezonde werkomgeving creëren voor hun medewerkers ervaren niet 
        alleen minder verzuim, maar ook meer werkplezier, tevreden klanten en betere dienstverlening. Een vitale organisatie 
        begint met een dialoog tussen medewerker en werkgever. 
        <br></br>
        <br></br>
        Het dashboard ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van jouw organisatie, de vitaliteit van de 
        medewerkers en in mogelijke verbeterpunten. </p> 
          {/* <br></br> */}
      <h3 className="headings-login-description"> Meer weten of hulp nodig? </h3>
        <p className="info-login"> 
        {/* Neem contact met ons op */}
        <button id="button_request_information_main"  type='button' onClick={() => { setRequestInformation(true) }}>  Neem contact met ons op </button>
        </p>
        {/* Mail Mirjam Lubbers (<a href = "mailto: m.lubbers@vitaalvechtdal.nl">m.lubbers@vitaalvechtdal.nl</a>), projectleider Werk en Vitaliteit voor meer informatie. </p> */}
      </div>
    </div>
    </div> 

    {/* blue block of login options  */}
    <div className="login-background_main">
      <h1 className="login-box-title_main"> Maak je keuze </h1>
        {/* <button id="button_employee_login_main"  type='button' onClick={() => { setRenderEmployeeLogin(true); setRenderHRLogin(false) }}>Vragenlijst voor medewerkers </button> */}
        <button id="button_employee_login_main"  type='button' onClick={() => { setRenderEmployeeLogin(true); setRenderHRLogin(false) }}> Klik hier om uw bedrijfscode in te voeren! </button>
        
        <button id="button_organisation_login_main"  type='button' onClick={() => { setRenderEmployeeLogin(false); setRenderHRLogin(true) }}>HR-Login</button>
    </div>
    </div>


      {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
      <div  className="marginBottom"  style={{ "marginBottom": "-9vw" }} >   </div>

    {/* error message */}
      {(error !== "") ? (<div className='error'>
        <h3 className="login-error">{error}</h3>
      </div>) : ""}

    </div>
    
    </body>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}





