import React, { useState } from 'react';
import './pages.css';

import logo from '../images/vitaal-vechtdal.svg'


// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';


// login pages that gets exported
export function RequestInformation({ setRequestInformation }) {

  // state variables definitions and the functions how to assign their values
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const [formResponse, setFormResponse] = useState("");
  const [error, setError] = useState("");



  // function that handels the login button and sets the token if login is correct
  const handleRequestInformation = async (e) => {
    e.preventDefault()
    document.getElementById("infoRequestForm").reset()
    const response = await fetch(`/information_request`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'name': name, 'companyname': companyName, 'companycity': companyCity, 'phone': phone, 'emailaddress': emailAddress, 'message': message}),
    })
    //   status =200 means successful
    if (response.status === 200) {
      setSent(true)
    }
    // Else, the current password is wrong, or something went wrong
    else {
        setError("Oeps, er is iets mis gegaan")
    }
  }

  React.useEffect(() => {
    if (sent) {
      setFormResponse(<p className="thank_you_message_request">Bedankt, we nemen binnenkort contact met je op!</p>)
    }
}, [sent]); // re-excecute hook when changes

  return (
    // <body>
    // <div className="login-page">
    // <div class="login-group">
    // <div className="login-wrapper">



    //   <h1 class="logo-vv">
    //     <img id="theImage_forgot" src={logo} alt="Vitaal Vechtdal" />
    //   </h1>

    // <div >
    //     <div className="info-login-background element">
    //   <div>
    //   <h1 className="title-login"> Vitaliteit op de werkvloer </h1>
    //   <br></br>
    //     <h3 className="headings-login-description"> Hoe vitaal is jouw bedrijf? </h3>
    //     <p className="info-login"> Bedrijven die een vitale en gezonde werkomgeving creëren voor hun medewerkers ervaren
    //        niet alleen minder verzuim, maar ook meer werkplezier, tevreden klanten en betere dienstverlening. 
    //        Een vitale organisatie begint met een dialoog tussen medewerker en werkgever. 
    //       De monitor ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van jouw organisatie en 
    //       de vitaliteit van de medewerkers en in mogelijke verbeterpunten. </p> 
    //   </div>
    // </div>


    <body className='body_login_info'>

{/* turquoise bar at top of page  */}
<div className="top_bar_sticky">
<div className="top_bar_login"> </div> 

{/* white bar for logo  */}
<div className="top_bar_logo">
    <img id="theImage_main" src={logo} alt="Vitaal Vechtdal" />
</div>
</div>

{/* title of device  */}
<div className="login-page">
    <h1 className="title-login-info"> Vitaliteit op de werkvloer </h1>
</div> 



<div class="login-group">

<div className="wrap_login">



<div className="container-info">
    <div className="login-background_info">
  <h1 className="login-box-title_info">  Contactformulier </h1>

  {/* <button id="button_back_to_previous_page" type='button' onClick={() => { setRequestInformation(false) }}>  &larr;  Ga terug </button> */}
  {setRequestInformation !== false && (
        <button id="button_back_to_previous_page" type='button' onClick={() => { setRequestInformation(false) }}>  &larr;  Ga terug </button>
      )}
      <form onSubmit={handleRequestInformation} className="login-box-details_organisation_info" id="infoRequestForm">
      <fieldset>
        {/* {formEnabled} */}
          <label>
            <h3 className="contact_input" style={{ 'textAlign': 'center' }}>Je naam</h3>
            <input className="center-block-info" placeholder="Naam" autoFocus type="text" required={true} onChange={e => setName(e.target.value)} />
            <h3 className="contact_input" style={{ 'textAlign': 'center' }}>De naam van je bedrijf</h3>
            <input className="center-block-info" placeholder="Bedrijfsnaam" type="text" required={true} onChange={e => setCompanyName(e.target.value)} />
            <h3 className="contact_input" style={{ 'textAlign': 'center' }}>De plaatsnaam van je bedrijf</h3>
            <input className="center-block-info" placeholder="Plaatsnaam" type="text" required={true} onChange={e => setCompanyCity(e.target.value)} />
            <h3 className="contact_input" style={{ 'textAlign': 'center' }}>Je e-mail adres</h3>
            <input className="center-block-info" placeholder="naam@voorbeeld.nl" type="email" required={true} onChange={e => setEmailAddress(e.target.value)} />
            <h3 className="contact_input" style={{ 'textAlign': 'center' }}>Je telefoonnummer</h3>
            <input className="center-block-info" placeholder="Telefoonnummer" type="tel" required={true} onChange={e => setPhone(e.target.value)} />
            <h3 className="contact_input" style={{ 'textAlign': 'center' }}>Bericht</h3>
            <textarea className="center-block-info" rows="4" cols="20" placeholder="Bericht" type="text" required={true} onChange={e => setMessage(e.target.value)} />
          </label>
          </fieldset>
          <div>
            <br />
            <button className='button_login_organisation_info' type="submit"> Verstuur </button>
          </div>
        </form>
    </div>
    <div  className="marginBottom" style={{ "marginBottom": "-9vw" }} >   </div>

      {(formResponse !== "") ? (<div className='formResponse'>
        <h3 className="formResponse"> {formResponse} </h3>
      </div>) : ""}
    
    {/* </div> */}
    </div>


      {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
      <div  className="marginBottom"  style={{ "marginBottom": "-9vw" }} >   </div>

      {(error !== "") ? (<div className='error'>
        <h3 className="login-error">{error}</h3>
      </div>) : ""}

    </div>
    </div>
    </body>

  )
}

