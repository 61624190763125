import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter> {/* enables router features accesable in app (otherwise <Router></Router> is always needed) */}
    <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);