import './pages.css'

// import data
import * as thuiswerken from '../dummy_data/flexibleWorking' // static data
import * as verzuim from '../dummy_data/verzuim' // static data

// import graph modules
// import { SimpleLineChart_B,  HorizontalBarChart_B, SingleBarChart_B, SimpleBarChart_B } from '../components/graphs'
// import { SimpleLineChart,  HorizontalBarChart, SingleBarChart, SimpleBarChart } from '../components/graphs'
import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import * as standard_questions from '../survey_input/survey_questions'

import { HorizontalBarChart_B, SimpleBarChart_B, SingleBarChart_B , SimpleLineChart_B} from '../components/graphs'


// define different dataKeys, changable by onClick
const colors = ["#3CB9D5","#41337A", "#3897AC"]

const regionDict = {
  'all': null,
  'Vechtdal': [7000, 8000],
  'test1': [7700, 7720],
  'test2': [7720, 7800]
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

// S T A R T   O F   T H E   F U N C T I O N 
// -----------------------------------------

// the function which will be rendered
export function Benchmark_page(props) {

  const navigate = useNavigate();
    const benchmark_history = () => {
        navigate("/benchmarkgeschiedenis")
    }

  // state variables definitions and the functions how to assign their values
  const [data, setData] = React.useState(null); // dict with all data
  const [forms, setForms] = React.useState({current: null}); // forms related to global Id from DB
  const [results, setResults] = React.useState(null);
  const [dashboard, setDashboard] = React.useState(null);
  const [benchMarkData, setBenchMarkData] = React.useState({benchmark: null});
  const [dataKeys, setDataKeys] = React.useState(['benchmark']);
  const [publish, setPublish] = React.useState(null);
  const [publishButton, setPublishButton] = React.useState(null);
  const [surveyClosed, setSurveyClosed] = React.useState(null);
  const [closeSurveyButton, setCloseSurveyButton] = React.useState(null);
  const [companiesFilledInTable, setCompaniesFilledInTable] = React.useState(null); // the companies and counts of them that filled in the survey
  const location = useLocation(); // also rerender results when switching pages
  const [previousLocation, setPreviousLocation] = React.useState(null)

  // Initialize region and industry
  const [region, setRegion] = React.useState(null);
  const [industry, setIndustry] = React.useState(null);
  const [availableIndustries, setAvailableIndustries] = React.useState({current: null})
  const [formSelect, setFormSelect] = React.useState(null)
  const [availableForms, setAvailableForms] = React.useState(null)

  let originalGlobalFormID = props.globalFormID;
  // use globalFormID as an copy of the original so that its global state cannot be altered when selecting a form
  const [globalFormID, setGlobalFormID] = React.useState(originalGlobalFormID)


  // importing state variables from the passed arguments
  let userID = props.userID;

  function RegionSelect(props) {
    let region = props.region
    return(
  <div className="select">
    {/* region selector */}
    <label className="select_region" htmlFor="region">Selecteer Een Regio </label>
    <select className="benchmark-filter_1" key="region" name="region" defaultValue= {region} id="region" onChange={handleRegionSelect}>
      <option key="all" value="all">Alle Regio's</option>
      <option key="vechtdal" value="Vechtdal">Vechtdal</option>
      {/* <option value="test1">Test 1</option>
      <option value="test2">Test 2</option> */}
    </select>
  </div>
    )
  }

  function IndustrySelect(props) {
    let industry = props.industry
    let availableIndustries = props.availableIndustries
    if (availableIndustries !== null) {
      let options = []
      options.push(<option key="null" value={null}>Alle branches</option>)
      for (let i in availableIndustries) {
        let industry = availableIndustries[i]
        options.push(<option key={industry} value={industry}>{industry}</option>)
      }
      // let defaultValue = industry
      // if (defaultValue === null) {
      //   defaultValue = 
      // }
      return (
        <div className='select'>
          <div className="benchmark-filter_2">
          <label className="select_survey" htmlFor="industry">Selecteer een branche </label>
          <select key="industry" name="industry" defaultValue={industry} id="industry" onChange={handleIndustrySelect}>
            {options}
          </select>
          </div>
        </div>
      )
    }
  }


  // fetch survey IDs from data base (through api)
  // React.useEffect(() => {
  const updateForms = async (idForm) => {
    if (globalFormID !== null & globalFormID.length !== 0) {
      // console.log("fetch survey");
      const setFormsQuery = `/survey_forms?globalFormId=${idForm}&hr=true`
      await fetch(setFormsQuery)
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => {
          setForms({current: json.result})
          setSurveyClosed(Boolean(json.result[0]['closed']))
          setPublish(Boolean(json.result[0]['published']))
        }); // this is the object retrieved (provided by the response in the server)
      const setCompaniesFilledInQuery = `/companies_filled_in?globalFormId=${idForm}`
      await fetch(setCompaniesFilledInQuery) // fetch companiesFilledIn
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => {
          setCompaniesFilledInTable([
            <table key={'stats_table'}>
              <tr key={'stats_table_headers'}>
                <th className="table_title">Organisatie</th>
                <th> Aantal keer de vragenlijst ingevuld </th> 
              </tr>
              <tbody>
                {json.result.map((row, key) => (<tr key={row,key}><td className="company_name_list">{row['companyName']}</td><td>{row['count']}</td></tr>))}
              </tbody>
            </table>
          ])
        }); // this is the object retrieved (provided by the response in the server)
    }
  }

  // remove results from dashboard when they might change in questionnaire page. Also reload when going back to dashboard
  React.useEffect(() => {
    if (location.pathname !== "/benchmark") {
      setGlobalFormID(originalGlobalFormID)
      setBenchMarkData({benchmark: null})
      setForms({current: null})
      setRegion(null)
      setIndustry(null)
    }
    else if (previousLocation !== "/benchmark" && location.pathname === "/benchmark") {
      updateBenchMarkData(globalFormID, region, industry)
      updateForms(globalFormID)
    }
    setPreviousLocation(location.pathname)
  }, [location]); // excecutes hook when arguments is [] changes

  // update publish_results 
  const publishResults = async (idForm, publish) => {
    fetch(`/publish_results?globalFormId=${idForm}&publish=${publish}`)
    setPublish(publish)
  }

  // update close_survey  
  const closeSurvey = async (idForm, close) => {
    fetch(`/close_survey?globalFormId=${idForm}&closed=${close}`)
    setSurveyClosed(close)
  }

  // fetch possible industries from database (through api)
  const updateIndustries = async (idForm, region) => {
    let zipCodes
    if (region !== null) {
      zipCodes = regionDict[region]  
    }
    if (idForm !== null & idForm.length !== 0) {
      let setAvailableIndustriesQuery
      if (region !== null) {
        setAvailableIndustriesQuery = `/industries?zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&globalFormId=${idForm}`
      }
      else {
        setAvailableIndustriesQuery = `/industries?globalFormId=${idForm}`
      }
      await fetch(setAvailableIndustriesQuery)
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => {
          setAvailableIndustries({current: json.industries})
        }); // this is the object retrieved (provided by the response in the server)
      
    }
  }

  // Constuct datakeys --> names in legends of graph
  const updateDataKeys = async (region, industry) => {
    let benchmarkName = 'benchmark'
    if (region !== null & industry !== null) {
      benchmarkName = region + ', ' + industry
    }
    else if (region !== null) {
      benchmarkName = region
    }
    else if (industry !== null) {
      benchmarkName = industry
    }
    setDataKeys([benchmarkName])
  }

  // fetch benchmark from data base (through api) 
  const updateBenchMarkData = async (idForm, region, industry) => {
    // industry should be string, region should be [<zipLower>,<zipUpper>]
    // If globalFormID, region and industry are provided
    let zipCodes
    let benchmarkQuery
    if (region !== null) {
      zipCodes = regionDict[region]  
    }
    if (idForm != null & region !== null & industry !== null & idForm.length !== 0) {
      benchmarkQuery = `/benchmarkapi?globalFormId=${idForm}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}&industry=${industry}`
    }
    // If globalFormID and region are provided
    else if (idForm != null & region !== null & idForm.length !== 0) {
      benchmarkQuery = `/benchmarkapi?globalFormId=${idForm}&zipLower=${zipCodes[0]}&zipUpper=${zipCodes[1]}`

    }
    // If globalFormID and industry are provided
    else if (idForm != null & industry !== null & idForm.length !== 0) {
      benchmarkQuery = `/benchmarkapi?globalFormId=${idForm}&industry=${industry}`
    }
    // If only globalFormID is provided
    else if (idForm !== null & idForm.length !== 0) {
      benchmarkQuery = `/benchmarkapi?globalFormId=${idForm}`
    }
    try {
      let res = await fetch(benchmarkQuery);
      let json = await res.json();
      let tempData = {...benchMarkData}
      tempData = json;
      // console.log(tempData);
      setBenchMarkData(tempData);
    } catch (error) {
      console.error(error);
    }
  }

  // initial fetch possible forms from database (through api) --> happens only once
  React.useEffect(() => {
    if (originalGlobalFormID !== null) {
      fetch(`/availableForms?globalFormId=${originalGlobalFormID}&vvAdmin=true`)
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => setAvailableForms(json.availableForms)); // this is the object retrieved (provided by the response in the server)
    }
  }, [originalGlobalFormID]); // excecutes hook when arguments is [] changes

  // initial loading of benchMarkData
  React.useEffect(() => {
    if (!benchMarkData.benchmark) {
      updateBenchMarkData(globalFormID, region, industry)
    }

  }, [benchMarkData]); // execute hook when arguments in [] changes

  // initial loading of forms
  React.useEffect(() => {
    if (!forms.current) {
      updateForms(globalFormID, region, industry)
    }

  }, [forms]); // execute hook when arguments in [] changes

  // initial loading of availableIndustries
  React.useEffect(() => {
    if (!availableIndustries.current) {
      updateIndustries(globalFormID, region)
    }

  }, [availableIndustries]); // execute hook when arguments in [] changes

  //  initial select used for creating an html select for selecting a form from the available forms
  React.useEffect(() => {
    if (availableForms !== null && availableForms.length > 1) {
      let options = []
      // make an option for each available option
      for (let i in availableForms) {
        let form = availableForms[i]
        options.push(<option key={form.startDate} value={form.idForm}>{`${form.name} ${new Date(form.startDate).getFullYear()}`}</option>)
      }
      // create html select with the options created above
      setFormSelect(
        <div className="select">
          {/* form selector */}
          <label htmlFor="region">Selecteer een vragenlijst </label>
          <select key="form" name="form" id=" form" defaultValue={originalGlobalFormID} onChange={handleFormSelect}>
            {options}
          </select>
        </div>
      )
    }
  }, [availableForms]); // executes hook when arguments is [] changes

  

  // setRegion when region is selected with the select box
  function handleRegionSelect(e) {
    if (e.target.value === 'all') {
      setRegion(null)
      updateBenchMarkData(globalFormID, null, industry)
    }
    else {
      setRegion(e.target.value)
      updateBenchMarkData(globalFormID, e.target.value, industry)
    }
  }

  // setIndustry when region is selected with the select box
  function handleIndustrySelect(e) {
    let selectedIndustry = e.target.value
    if (selectedIndustry === "Alle branches") {
      selectedIndustry = null
    }
    setIndustry(selectedIndustry)
    updateBenchMarkData(globalFormID, region, selectedIndustry)
    updateDataKeys(region, selectedIndustry)
  }

  // setGlobalFormID when form is selected with the form select box
  function handleFormSelect(e) {
    setGlobalFormID(e.target.value)
    setIndustry(null)
    setRegion(null)
    setPublish(null)
    setSurveyClosed(null)

    updateBenchMarkData(e.target.value, region, industry)
    updateForms(e.target.value)
    updateIndustries(e.target.value, region)
    updateDataKeys(null, null)
  }

  // voorbeeld van dale
  // function updateData() {
  //   let tempData = [...data];
  //   tempData = await fetch
  //   setDataKeys(tempData)
  // }

  // React.useEffect(() => {
  //   if (!data) {
  //     fetchData()
  //   }
  // },[data])
  // einde voorbeeld van dale

  // function that creates the dashboard with graphs based on the results in the DB
  React.useEffect(() => {
    // check if results is not full of empty lists. This happens when a survey has not been filled in yet
    // if noResults remains true the survey hasn't been filled in yet
    // if there are results (present AND loaded) and there is benchmarkdata loaded, create json_dashboard
    if ((!benchMarkData || benchMarkData.benchmark === null) || !forms || forms.current === null) {
      setDashboard(
        <div className="tot">
          {<h1> Er is nog geen data voor de monitor beschikbaar </h1>}
        </div>
      )
    }
    else if (Object.keys(benchMarkData.benchmark).length === 0) {
      setDashboard(
        <div className="tot">
          {<h1> Er is nog geen data voor de monitor beschikbaar </h1>}
        </div>
      )
    }
    else {
      // TO DO
      // check results and benchmarks! 
      // size and form + question IDs!



      // C R E A T E   D A S H B O A R D   G R A P H S   G R O U P E D   P E R   T H E M E   A N D   Q U E S T I O N   T Y P E
      // ---------------------------------------------------------------------------------------------------------------------

      let json_dashboard = {};
      let formTitle = forms.current[0].name; // only title from first question in the form is needed
      let theDashboardPage = [];  // array that contains all dashboard components 
      let currentTopic = null
      let currentType = ''
      let currentQuestionFormSection = null
      let currentQuestionId = -1
      let currentQuestionTitle = ''
      let graph = null
      let margin = 0
      let key = null
      let dataQuest = []
      let formSectionCounter = 1
      let questionsInGraphCounter = 0   // counts how many questions are in a single graph. Graphs are limited to three questions.
      let graphCounter = 0  // Counts the number of graphs. Helps with ensuring graphs get unique ids (Because graphs are limited to three questions)

      // let benchmarkName = 'benchmark'
      // if (filter !== null) {
      //   benchmarkName = filter
      // }
      let benchmarkName = 'benchmark'
      if (region !== null & industry !== null) {
        benchmarkName = region + ', ' + industry
      }
      else if (region !== null) {
        benchmarkName = region
      }
      else if (industry !== null) {
        benchmarkName = industry
      }
      setDataKeys([benchmarkName])

      // // add subform title to dashboard
      // loop over de subforms
      for (const iQuestion in forms.current) {
        const question = forms.current[iQuestion]

        const questionType = question.questionType; // question type relates to type of graph
        const questionId = question.idQuestion; // the id of the question
        const questionTopic = question.topic;   // the topic of the question
        const questionTitle = question.questionHR;  // the question for HR people
        const resultFormat = question.questionType;
        const answers_json = question.answers_json; // only non-null for specific question types
        const QuestionFormSection = question.FormSection  // the formSection of the current question. e.g. 'Standaard vragen' or 'Thema vragen'
        const formTitle = question.name


        // ============================= CREATE GRAPHS WITH GATHERED DATA (STEP 2) =============================

        if (questionType !== currentType | questionTopic !== currentTopic | questionsInGraphCounter >= 3) {
          // add graph for each questionType once the questionType changes and dataQuest has been filled, 
          if (currentType === 'likert' | currentType === 'percentage') {
            graph = <HorizontalBarChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
          }
        }
        if (questionId !== currentQuestionId) {
          if (currentType === 'multiple_result') {
            currentQuestionTitle = Object.keys(JSON.parse(currentQuestionTitle))[0]
            // line graph for "Verzuim" topic
            if (currentTopic === "Verzuim" | currentTopic === "verzuim" | currentTopic === "Gezond en vitaal werken") {
              graph = <SimpleLineChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
            }
            else {
              margin = -3.5;
              graph = <SimpleBarChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
            }
          }
          else if (currentType === 'option') {
            graph = <SimpleBarChart_B xAxis="name" xYaxis="% bedrijven" data={dataQuest} dataKeys={dataKeys} colors={colors} />
          }
        }
        if (graph) {
          // add graph to dashboard
          key = `${currentTopic}_${currentType}_${currentQuestionTitle}_${graphCounter}`;
          // theDashboardPage.push(<RegionSelect region={region}/>)
          theDashboardPage.push(<IndustrySelect industry={industry} availableIndustries={availableIndustries.current} />)
          theDashboardPage.push(
            <div className='whiteblock' key={key}>
              <h3 className="dashboard-h3">{currentQuestionTitle}</h3>
              <div  className="marginBottom" style={{ "marginBottom": `${margin}vw` }} >   </div>
              {graph}
            </div>);

          // add spacing between questions
          key = `${currentTopic}_${currentType}_${currentQuestionTitle}_${graphCounter}_spacing`;
          theDashboardPage.push(
            <div  className="marginBottom"  key={key} style={{ "marginBottom": "2vw" }} ></div>
          )
          graph = null
          margin = 0
          // reset list of questions when topic has changed and graph has been created
          dataQuest = []
          questionsInGraphCounter = 0 // reset questionsInGraphCounter
          graphCounter += 1 //increment graphCounter
        }

        if (questionTopic !== currentTopic) {
          // add spacing between themes
          key = `${QuestionFormSection}_${questionTopic}_spacing`;
          theDashboardPage.push(
            <div  className="marginBottom"  key={key} style={{ "marginBottom": "5vw" }} ></div>
          )
        }
        

        // ================================= CREATE FORMSECTION TITLE AND TOPIC TITLE =======================
        if (QuestionFormSection !== currentQuestionFormSection) {
          // add topic title to dashboard
          if (QuestionFormSection) {
            theDashboardPage.push(<h2 className="dashboard_main_title" key={QuestionFormSection}>{QuestionFormSection + ' - Deel ' + formSectionCounter}</h2>);
          }
          else {
            theDashboardPage.push(<h2 key={QuestionFormSection}>{'Thema vragen - Deel ' + formSectionCounter}</h2>);
          }
          formSectionCounter += 1
        }

        // if (!currentTopic) {
        //   currentTopic = questionTopic
        //   // add topic to dashboard on first run through loop
        //   theDashboardPage.push(<h1 key={`${QuestionFormSection}_${currentTopic}`}>{currentTopic}</h1>)
        // }
        // // when the topic changes and/or questionType, render previous question(s) graph 
        // if (questionTopic !== currentTopic) {
        //   // add topic to dashboard
        //   theDashboardPage.push(<h1 key={`${QuestionFormSection}_${questionTopic}`}>{questionTopic}</h1>)
        // }

        // =========== UPDATE LAST-QUESTION TRACKERS ==============
        // these are used for maintaining data of the last question
        // this is required for creating the graph, which will only be done once we encounter a new question,
        // indicating that the data is gathered and the graph is ready to be made
        // then, update the 'current' trackers
        currentTopic = questionTopic
        currentType = questionType
        currentQuestionId = questionId
        currentQuestionTitle = questionTitle
        currentQuestionFormSection = QuestionFormSection
        questionsInGraphCounter += 1 // increment questionsInGraphCounter
        

        // ============================= GATHERING DATA FOR GRAPHS (STEP 1) =============================
        // within the following conditions the dataQuest is filled. Questions are already ordered.
        // Once it is filled, the condition above will be triggered and the graphs will be created 
        // with the gathered data.
        // for Likert questions
        if (questionType === 'likert' | questionType === 'percentage') {
          // fill the list of dicts with with question results. One questionType (multiple questions) 
          // goes into this list before it gets cleared
          dataQuest.push({
            'name': questionTitle,
            [benchmarkName]: Math.round(benchMarkData.benchmark[questionId]*100)/100,
            'benchmarkName': benchmarkName
          })
          // these graphs have to be displayed without a title on top
          currentQuestionTitle = null
        }
        else if (questionType === 'multiple_result') {
          // let dataCol = JSON.parse(results_json)
          let titles = Object.values(JSON.parse(questionTitle))[0]
          // fill the list of dicts with with question results. Only one question (multiple columns)
          // goes into this list before it gets cleared
          for (let i in Object.keys(titles)) {
            i = parseInt(i)
            if (benchMarkData.benchmark[questionId]) {
              dataQuest.push({
                'name': standard_questions.monthTitle(titles[i], question.startDate),
                [benchmarkName]: Math.round(benchMarkData.benchmark[questionId][i]*100)/100,
                'benchmarkName': benchmarkName
              })
            }
          }
        }
        else if (questionType === 'option') {
          // fill the list of dicts with with question results. Only one question (multiple columns) 
          // goes into this list before it gets cleared
          let answers = answers_json.slice(2,-2).split(',')
          answers = answers.map(word => { return word.replace('\"', "").trim() })
          for (let i in answers) {
            let answer = answers[i]
            // console.log(Object.keys(benchMarkData[questionId].includes(answer)))
            if (benchMarkData.benchmark && benchMarkData.benchmark[questionId] && Object.keys(benchMarkData.benchmark[questionId]).includes(answer)) {
              dataQuest.push({
                'name': answer,
                [benchmarkName]: Math.round(benchMarkData.benchmark[questionId][answer]*100)/100,
                'benchmarkName': benchmarkName
              })
            }
          }
        }

      } // end of question loop

      // ================== CREATE FINAL GRAPH AFTER LOOP HAS ENDED ========================
      
      // create the last graph because it is not triggered when the loop ends
      if (currentType === 'multiple_result') {
        currentQuestionTitle = Object.keys(JSON.parse(currentQuestionTitle))[0]
        // line graph for "Verzuim" topic
        if (currentTopic === "Verzuim" | currentTopic === "verzuim") {
          graph = <SimpleLineChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
        }
        else {
          margin = -3.5;
          graph = <SimpleBarChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
        }
      }
      else if (currentType === 'likert' | currentType === 'percentage') {
        graph = <HorizontalBarChart_B xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
      }
      else if (currentType === 'option') {
        graph = <SimpleBarChart_B xAxis="name" xYaxis="% bedrijven" data={dataQuest} dataKeys={dataKeys} colors={colors} />
      }
      if (graph) {
        // add graph to dashboard
        key = `${currentTopic}_${currentType}_${currentQuestionTitle}`;
        // theDashboardPage.push(<RegionSelect region={region}/>)
        theDashboardPage.push(<IndustrySelect industry={industry} availableIndustries={availableIndustries.current} />)
        theDashboardPage.push(
          <div className='whiteblock' key={key}>
            <h3 className="dashboard-h3">{currentQuestionTitle}</h3>
            <div  className="marginBottom"  style={{ "marginBottom": `${margin}vw` }} >   </div>
            {graph}
          </div>);


        // add spacing between questions
        key = `${currentTopic}_${currentType}_${currentQuestionTitle}_spacing`;
        theDashboardPage.push(
          <div className="marginBottom" key={key} style={{ "marginBottom": "2vw" }} ></div>
        )
        graph = null
        margin = 0
        // reset list of questions when topic has changed and graph has been created
        dataQuest = []
      }
    

      // add spacing at the end
      theDashboardPage.push(
        <br key="last_spacing" />
      )

      // set the dashboard state
      setDashboard(theDashboardPage);

    } // end of if "results" is defined
  }, [results, benchMarkData, region, industry, publish, surveyClosed, availableIndustries, forms, globalFormID]); // end of hook to create dashboard

  // if there is no dashboard created yet, show loading message
  var dashboardPage = dashboard;
  if (!dashboard) {
    dashboardPage = <h1>Dashboard wordt geladen</h1>
  }

  // used for creating publish sentence + button
  React.useEffect(() => {
    let currentFormName = "de geselecteerde vragenlijst"
    for (let form in availableForms) {
      if (availableForms[form]['idForm'] === originalGlobalFormID) {
        currentFormName = `de ${availableForms[form]['name']} ${new Date(availableForms[form]['startDate']).getFullYear()}`
      }
    }
    if (publish === false && publish !== null) {
      setPublishButton(
        <div className="initiative_bars_box_2" >
        <div className="initiative_bars">
        <div className="container_2">
        <div className="text_content">
          Wil je de resultaten voor {currentFormName} publiceren?
        </div>
          <button id="button_publish_results_2"  type='button' onClick={() => { publishResults(originalGlobalFormID, true) }}>Publiceren</button>
        </div>
        </div>
        </div>

      )
    }
    else if (publish === true) {
      setPublishButton(
        <div className="initiative_bars_box_2" >
        <div className="initiative_bars">
        <div className="container_2">
        <div className="text_content">
          Wil je de publicatie van de resultaten <br></br>  voor {currentFormName} intrekken?
          </div>
          <button id="button_publish_results_2"  type='button' onClick={() => { publishResults(originalGlobalFormID, false) }}>Intrekken</button>
        </div>
        </div>
        </div>

      )
    }
    else {
      setPublishButton(null)
    }
  }, [publish, originalGlobalFormID, availableForms]); // executes hook when arguments is [] changes
  
  // used for creating close survey sentence + button
  React.useEffect(() => {
    let currentFormName = "de geselecteerde vragenlijst"
    for (let form in availableForms) {
      if (availableForms[form]['idForm'] === originalGlobalFormID) {
        currentFormName = `de ${availableForms[form]['name']} ${new Date(availableForms[form]['startDate']).getFullYear()}`
      }
    }
    if (surveyClosed === false & surveyClosed !== null) {
      setCloseSurveyButton(
        <div className="initiative_bars_box" >
          <div className="initiative_bars">
            <div className="top_bar_1">
              <div className="container_1">
              <div className="text_content">
                Wil je {currentFormName} afsluiten?
                </div>
              <button id="button_publish_results_2"  type='button' onClick={() => { closeSurvey(originalGlobalFormID, true) }}>Sluit de vragenlijst</button>
              </div>
            </div>
          </div> 
        </div> 

      )
    }
    else if (surveyClosed === true & surveyClosed !== null) {
      setCloseSurveyButton(
        <div className="initiative_bars_box" >
          <div className="initiative_bars">
            <div className="top_bar_1">
              <div className="container_1">
              <div className="text_content">
                Wil je {currentFormName} publiceren?
                </div>
              <button id="button_publish_results_2"  type='button' onClick={() => { closeSurvey(originalGlobalFormID, false) }}>Publiceer vragenlijst</button>
              </div>
            </div>
          </div>
        </div>

      )
    }
    else {
      setCloseSurveyButton(null)
    }
  }, [surveyClosed, originalGlobalFormID, availableForms]); // executes hook when arguments is [] changes



  // return the dashboard in a div
  return (
    <div>
    <div className="tot">
      {closeSurveyButton}
      {publishButton}

      {formSelect}

      <div className="view_employee_survey_1">
        Je kunt de historie hier bekijken
        <br></br>
        <a className="button-benchmark_history" onClick={benchmark_history}> Bekijk de historische data  </a>
      </div>

      {dashboardPage}

      <div className='benchmark_stats'>
        <h1 className="statistics_title"> Totale statistieken </h1>
        {companiesFilledInTable}
      </div>
      
      <button className="Afdrukken" onClick={window.print}>Afdrukken</button>

    </div>

        {/* <div className="bottom_bar"> </div>   */}
        <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

    </div>
    
    </div>

  )
}