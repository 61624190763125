export const answers_agreement5 = [{
    "value": 1,
    "text": "Sterk mee oneens"
},
{
    "value": 2,
    "text": "Mee oneens"
},
{
    "value": 3,
    "text": "Neutraal"
},
{
    "value": 4,
    "text": "Mee eens"
},
{
    "value": 5,
    "text": "Sterk mee eens"
}];

export const answers_ervaring5 = [{
    "value": 1,
    "text": "Zeer Negatief"
},
{
    "value": 2,
    "text": "Negatief"
},
{
    "value": 3,
    "text": "Neutraal"
},
{
    "value": 4,
    "text": "Positief"
},
{
    "value": 5,
    "text": "Zeer Positief"
}];

export const answers_jaNee = [{
    "value": 1,
    "text": "Ja"
},
{
    "value": 2,
    "text": "Nee"
},
{
    "value": 3,
    "text": "Een deel"
}];

