
// import modules from recharts
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts'; //NP added the 'Cell'
import { BarChart, Bar, LineChart, Line } from 'recharts';
import { useState, useEffect } from 'react';


import React, { FunctionComponent } from "react";

import './components.css' 



// Formats x-axis font size 
let axisFontSize = "0.8vw";

/*get dynamic window size*/
export default function UserWindow() {
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])
    return (

        screenSize.dynamicWidth
    )
}



// vertical axis label
const AxisLabel = ({ axisType, x, y, width, height, stroke, children }) => {
    const isVert = axisType === 'yAxis';
    const cx = isVert ? x : x + (width / 2);
    const cy = isVert ? (height / 2) + y : y + height + 10;
    const rot = isVert ? `270 ${cx} ${cy}` : 0;
    return (
        <div className='wrap_y-axis'> 
        <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle" stroke={stroke} style={{ "fontSize": axisFontSize }} >
            {children}
        </text>
        </div>
    );
};




// axis labels on multiple lines 
// is not being used anymore due to error - everythig still runs as it was 
// const CustomizedYAxisTick = ({ x, y, stroke, payload }) => {

//     // split string in half
//     var s = payload.value;

//     var middle = Math.floor(s.length / 2);
//     var before = s.lastIndexOf(' ', middle);
//     var after = s.indexOf(' ', middle + 5); /*corrects overflow of labels */ 

//     if (middle - before < after - middle) {
//         middle = before;
//     } else {
//         middle = after;
//     }

//     var xp = -UserWindow() * 0.07; /* shifts y-axis labels closer/further to y-axis */ 

//     // var s1 = s.substr(0, middle);
//     // var s2 = s.substr(middle + 1);

//     var listwords = s.match(/("[^"]*")|([^\s"]+)/g);

//     const limit = 20;
//     // const substr_num=(s.length-s.length%limit)/limit+1;

//     const max_word = 5;
//     const sentence_num = (listwords.length - listwords.length % max_word) / max_word + 10;

//     //build substrings
//     //if the sentence is too long then slice the strings into substrings
//     if (middle > limit) {
//         var start = 0;
//         var end = limit;
        
//         let substr = [];

//         for (var i = 0; i < sentence_num; i++) {
//             start = i * max_word;
//             end = start + max_word - 1;
//             let list = [];
//             for (var j = start; j < end; j++) {
//                 list.push(listwords[j]);
//                 list.push(" ");
//             }
//             var yp = -9; /* shifts labels position up/down - center with tick mark */ 
//             var yy = 12 * i; /* manipulates teh space between lines i.e line height */ 

//             substr.push(<tspan textAnchor="middle" className='substr' key={i} x={xp} dy={yp} y={yy}>{list}</tspan>)
//         }

//         return (

//             <g transform={`translate(${x},${y})`}>
//                 <text x={0} y={5} dy={0} fill="#666">
//                     {substr}
//                 </text>

//             </g>
//         );

//     }
//     else {

//         return (
//             <g transform={`translate(${x},${y})`}>
//                 <text x={0} y={0} dy={0} fill="#666">
//                     <tspan textAnchor="middle" x={xp} y="5" >{s}</tspan>
//                 </text>
//             </g>
//         );
//     }

// };

// // axis labels on multiple lines
// const CustomizedXAxisTick = ({ x, y, stroke, payload }) => {

//     // split string in half
//     var s = payload.value;

//     var middle = Math.floor(s.length / 2);
//     var before = s.lastIndexOf(' ', middle);
//     var after = s.indexOf(' ', middle + 1);

//     if (middle - before < after - middle) {
//         middle = before;
//     } else {
//         middle = after;
//     }

//     var s1 = s.substr(0, middle);
//     var s2 = s.substr(middle + 1);

//     if (middle > 5) {
//         return (
//             <g transform={`translate(${x},${y})`}>
//                 <text x={0} y={0} dy={16} fill="#666">
//                     <tspan textAnchor="middle" x="-100" dy="15">{s1}</tspan>
//                     <tspan textAnchor="middle" x="-100" dy="20">{s2}</tspan> <br/>
//                 </text>
//             </g>
//         );
//     } else {

//         return (
//             <g transform={`translate(${x},${y})`}>
//                 <text x={0} y={0} dy={0} fill="#666">
//                     <tspan textAnchor="middle" x="-100" y="20">{s}</tspan>
//                 </text>
//             </g>
//         );
//     }
// };



// S I M P L E   B A R   C H A R T
// ------------------------------- 
export function SimpleBarChart(props) {
    // create as many bars as dataKeys are provided 
    let barList;
    // use map if dataKeys is an array (object)
    if (typeof (props.dataKeys) === 'object') {
        barList = props.dataKeys.map((value, index) => {

            // stacked or not
            if (typeof props.stacked !== 'undefined') {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]}  stackId="a"  barSize={100}/>
                )
            }
            else {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]}   barSize={100} />
                )
            }
        }
        );
    }

    // only add 1 bar, with correct color (can be type string or object)
    else if (typeof (props.dataKeys) === 'string' && typeof (props.colors) === 'string') {

        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors}  stackId="a"  />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors} />
        }
    }
    else {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]} stackId="a" />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]}  />
        }
    }
    return (

        <ResponsiveContainer width="100%" height={600} debounce={2}>
            <BarChart
                // width={500}
                // height={300}
                data={props.data}
                margin={{
                    top: 50,
                    right: 30,
                    left: 150,
                    bottom: 50,
                }}
                barWidth
                background="#D3D3D3"
            // barGap={3} /* gap between bars*/
            // barCategoryGap={20} /* gap between bargroups*/
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.xAxis} tick={{ fontSize: axisFontSize, angle: -10, textAnchor: 'end' }} interval={0} />
                <YAxis label={<AxisLabel axisType='yAxis' x={25} y={125} width={0} height={0} > {props.xYaxis} </AxisLabel>} tick={{ fontSize: axisFontSize }} />
                <Tooltip />
                <Legend layout="vertical" verticalAlign="text-top" align="right" maxWidth="20px" />
                {barList};
            </BarChart>
        </ResponsiveContainer>

    )

};
// S I N G L E  B A R  B A R  C H A R T
// ------------------------------- 
export function SingleBarChart(props) {
    let data = []
    let benchmarkName = 'benchmark'
    let ownChoice = ' & jouw keuze'
    let eigenBedrijfName = benchmarkName + ownChoice
    for (let i in props.data) {
        benchmarkName = props.data[i]['benchmarkName']
        eigenBedrijfName = benchmarkName + ownChoice
        if (props.data[i]['eigen bedrijf'] === 100) {
            data.push({ 'name': props.data[i]['name'], [eigenBedrijfName]: props.data[i][benchmarkName] })
        }
        else {
            data.push({ 'name': props.data[i]['name'], [benchmarkName]: props.data[i][benchmarkName] })
        }
    }
    // create as many bars as dataKeys are provided 
    let barList;
    let dataKeys = [benchmarkName + ownChoice, benchmarkName]
    barList = dataKeys.map((value, index) => {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            return (
                <Bar key={index} dataKey={value} fill={props.colors[index]}  stackId="a" barSize={100}/>
            )
        }
        else {
            return (
                <Bar key={index} dataKey={value} fill={props.colors[index]}   stackId="a" barSize={100}/>
            )
        }
    });
    return (

        <ResponsiveContainer width="100%" height={600} debounce={2}>
            <BarChart
                //width={1000}
                //height={600}
                data={data}
                margin={{
                    top: 50,
                    right: 50,
                    left: 130,
                    bottom: 50,
                }}
                background="#D3D3D3"
            // barGap={3} /* gap between bars*/
            // barCategoryGap={20} /* gap between bargroups*/
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.xAxis} tick={{ fontSize: axisFontSize }} interval={0} />
                <YAxis label={<AxisLabel axisType='yAxis' x={25} y={125} width={0} height={0} > {props.xYaxis} </AxisLabel>} tick={{ fontSize: axisFontSize }}  padding={{top:50}} />
                <Tooltip />
                <Legend layout="vertical" verticalAlign="text-top" align="right" maxWidth="20px" />
                {barList};
            </BarChart>
        </ResponsiveContainer>

    )

};


// H O R I Z O N T A L   B A R   C H A R T
// ----------------------------------- 
export function HorizontalBarChart(props) {
    // create as many bars as dataKeys are provided 
    let barList;
    // use map if dataKeys is an array (object)
    if (typeof (props.dataKeys) === 'object') {
        barList = props.dataKeys.map((value, index) => {
            // stacked or not
            if (typeof props.stacked !== 'undefined') {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]} stackId="a" />
                )
            }
            else {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]}/>
                )
            }
        }
        );
    }
    // only add 1 bar, with correct color (can be type string or object)
    else if (typeof (props.dataKeys) === 'string' && typeof (props.colors) === 'string') {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors}  stackId="a" />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors}  />
        }
    }
    else {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]} stackId="a" />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]}  />
        }
    }

    const len = UserWindow() * 0.25;

    // const YaxistickFormatter = (value, index) => {

    //     const limit = 20; // set your maximum character
    //     if (value.length < limit) return value;//if text length larger than the max character length, break lines
    //     return `${value.substring(0, limit)}${value.substring(limit, value.length)}`
    // };

    return (

        <div className='verticalchart' style={{ width: "90%", height: 300 }} >
            <ResponsiveContainer width="99%" height="100%" maxWidth={50} debounce={2} > 
                <BarChart
                    //width={1000}
                    // height={600}
                    data={props.data}
                    layout="vertical"
                    //barGap="5%"
                    margin={{ top: 50, right: 30, left: 140, bottom: 50 }}
                >
                    <CartesianGrid strokeDasharray="3 3"  />
                    {/* <YAxis dataKey={props.xAxis} type="category" width={len * 0.8} tick={{ fontSize: axisFontSize }} tick={<CustomizedYAxisTick />}/> */}
                    <YAxis dataKey={props.xAxis} type="category" width={len * 0.7} style = {{overflow: 'visible', overflowWrap: 'break-word'}} tick={{ fontSize: axisFontSize}}  />
                    <XAxis type="number" tick={{ fontSize: axisFontSize }} padding={{right: 120}} />
                    <Tooltip />
                    <Legend layout="vertical" verticalAlign="text-bottom" align="right" padding={{ right: 16, left: 0 }} maxWidth="20px" />
                    {barList};

                </BarChart>
            </ResponsiveContainer>
        </div>
    )

};






// S I M P L E   L I N E   C H A R T


export function SimpleLineChart(props) {

    // default arguments
    // props.width = (typeof props.width !== 'undefined') ?  props.width : 800;
    // props.height = (typeof props.height !== 'undefined') ?  props.height : 600;

    // create as many lines as dataKeys are provided 
    let lineList;
    let data = props.data

    // use map if dataKeys is an array (object)
    if (typeof (props.dataKeys) === 'object') {
        lineList = props.dataKeys.map((value, index) => {
            // remove missing values from data to prevent from gaps in line chart
            let data_dropna = []
            for (const i in data) {
                const datapoint = data[i]
                if (!isNaN(datapoint[value])) {
                    data_dropna.push(datapoint)
                }
            }
            data = data_dropna

            if (index === 0) {
                return (
                    <Line key={index} type="monotone" dataKey={value} stroke={props.colors[index]} strokeWidth={props.lineWidth}  activeDot={{ r: 8 }} />
                )
            }
            else {
                return (
                    <Line key={index} type="monotone" dataKey={value} stroke={props.colors[index]} strokeWidth={props.lineWidth} />
                )
            }
        }
        );
    }

    // only add 1 bar, with correct color (can be type string or object)
    else if (typeof (props.dataKeys) === 'string' && typeof (props.colors) === 'string') {

        lineList = <Line type="monotone" dataKey={props.dataKeys} stroke={props.colors} strokeWidth={props.lineWidth} />
    }
    else {

        lineList = <Line type="monotone" dataKey={props.dataKeys} stroke={props.colors[0]} strokeWidth={props.lineWidth} />
    }

    return (

        <ResponsiveContainer width="100%" height={600} debounce={2}>
            <LineChart
                //width={1000}
                //height={600}
                data={data}
                margin={{ top: 50, right: 30, left: 140, bottom: 50 }}
                background="#D3D3D3"
            >
                <CartesianGrid strokeDasharray="3 3"/>
                {/* padding added to make more space on the right to fot the legend label  */}
                <XAxis dataKey={props.xAxis} tick={{ fontSize: axisFontSize }} padding={{right: 100 }} />
                <YAxis label={<AxisLabel axisType='yAxis' x={25} y={125} width={0} height={0}>{props.xYaxis}</AxisLabel>} tick={{ fontSize: axisFontSize }} />
                <Tooltip />
                {/* wrapperStyle added to shift the lagend label */}
                <Legend layout="vertical" verticalAlign="text-bottom" align="right" wrapperStyle={{top:15 ,right:30}} maxWidth="20px" />
                {lineList}
            </LineChart >
        </ResponsiveContainer>

    )
}










// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------- //
/////////////////  BENCHMARK PAGE GRAPHS-includes customized label ///////////////////////



// DISPLAY NUMBERS WITHOUT HOVERING ON BENCHMARK PAGE 

// BAR GRAPH - Customized Label
// ------------------------------- 
// Install create-react-class & set as variable 
// var createReactClass = require('create-react-class');

// // Create class for CustomizedLabel - used to add labels to each data point without needing to hover. 
// const CustomizedLabel_Bar = createReactClass({
//     render: function () {
//       const {x, y, value, width} = this.props;
//     //   const radius=16; 
          
//          return (
//             <g>
//             {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
//             <text 
//                 // x={x + width /2 - radius/1.25} 
//                 // y={y - radius/2} 
//                 x={x + width/2} 
//                 y={y} 
//                 dy={-4} 
//                 fill="black" 
//                 fontSize={9} 
//                 fontWeight="bold" 
//                 textAnchor="middle"  
//                 dominantBaseline="start"
//             > 
//                 {/* {value.toFixed(2)} */}
//                 {value}

//             </text>
//             </g>
//          );
//     }
//   });



  // LINE GRAPH - Customized Label 
  // ------------------------------- 
  // Install create-react-class & set as variable 
// var createReactClass = require('create-react-class');

// // Create class for CustomizedLabel - used to add labels to each data point without needing to hover. 
// const CustomizedLabel_Line = createReactClass({
//     render: function () {
//       const {x, y, value} = this.props;
//       const radius=16; 
          
//          return (
//             <g>
//             {/* <circle cx={x + 2 } cy={y + radius*1.5} r={radius} fill="#8884d8" /> */}
//             <text 
//                 // x={x - radius/1.5 } 
//                 // y={y + radius*2}
//                 x={x - 6} 
//                 y={y + 18} 
//                 dy={-4} 
//                 fill="black" 
//                 fontSize={9} 
//                 fontWeight="bold" 
//                 textAnchor="start"  
//                 dominantBaseline="start"
//             > 
//                 {value.toFixed(2)}
//             </text>
//             </g>
//          );
//     }
//   });


// HORIZONTAL BAR GRAPH - Customized Label 
// ------------------------------- 
// // Install create-react-class & set as variable 
// var createReactClass = require('create-react-class');

// Create class for CustomizedLabel - used to add labels to each data point without needing to hover. 
// const CustomizedLabel_HBar = createReactClass({
//     render: function () {
//       const {x, y, width, height, value} = this.props;
//       const radius=14; 

          
//          return (
//             <g>
//             {/* <circle cx={x + width + radius} cy={y + height - radius*1.5} r={radius} fill="#8884d8" /> */}
//             <text 
//                 x={x + width + 1 } 
//                 // y={y - radius*(-1.7)} 
//                 y={y +height/2}
//                 dy={-4}
//                 // dy={-2} 
//                 // x={x+ width + radius*0.5}
//                 // y={y + height - radius*1.25}
//                 fill="black" 
//                 fontSize={6} 
//                 fontWeight="bold" 
//                 textAnchor="start"  
//                 dominantBaseline="end"
//             > 
//                 {value.toFixed(2)}
//             </text>
//             </g>
//          );
//     }
//   });


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // BENCHMARK PAGE GRAPHS 

// SIMPLE BAR CHART 
export function SimpleBarChart_B(props) {
    // create as many bars as dataKeys are provided 
    let barList;
    // use map if dataKeys is an array (object)
    if (typeof (props.dataKeys) === 'object') {
        barList = props.dataKeys.map((value, index) => {

            // stacked or not
            if (typeof props.stacked !== 'undefined') {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]}  stackId="a"  label={{ fill: "black", fontSize: 15, position: "top" }}  barSize={100} />
                )
            }
            else {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]} label={{ fill: "black", fontSize: 15, position: "top" }} barSize={100}   />
                )
            }
        }
        );
    }

    // only add 1 bar, with correct color (can be type string or object)
    else if (typeof (props.dataKeys) === 'string' && typeof (props.colors) === 'string') {

        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors}  stackId="a"  />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors} />
        }
    }
    else {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]} stackId="a" />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]}  />
        }
    }
    return (

        <ResponsiveContainer width="100%" height={600} debounce={2}>
            <BarChart
                //width={1000}
                //height={600}
                data={props.data}
                margin={{ top: 50, right: 30, left: 140, bottom: 50 }}
                background="#D3D3D3"
            // barGap={3} /* gap between bars*/
            // barCategoryGap={20} /* gap between bargroups*/
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.xAxis} tick={{ fontSize: axisFontSize }} interval={0} />
                <YAxis label={<AxisLabel axisType='yAxis' x={25} y={125} width={0} height={0} > {props.xYaxis} </AxisLabel>} tick={{ fontSize: axisFontSize }} padding={{ top: 50, left: 0 }}  />
                <Tooltip />
                <Legend layout="vertical" verticalAlign="text-top" align="right" maxWidth="20px" />
                {barList};
            </BarChart>
        </ResponsiveContainer>

    )

};


// S I N G L E  B A R  B A R  C H A R T
// ------------------------------- 
export function SingleBarChart_B(props) {
    let data = []
    let benchmarkName = 'benchmark'
    let ownChoice = ' & jouw keuze'
    let eigenBedrijfName = benchmarkName + ownChoice
    for (let i in props.data) {
        benchmarkName = props.data[i]['benchmarkName']
        eigenBedrijfName = benchmarkName + ownChoice
        if (props.data[i]['eigen bedrijf'] === 100) {
            data.push({ 'name': props.data[i]['name'], [eigenBedrijfName]: props.data[i]['regio'] })
        }
        else {
            data.push({ 'name': props.data[i]['name'], [benchmarkName]: props.data[i]['regio'] })
        }
    }
    // create as many bars as dataKeys are provided 
    let barList;
    let dataKeys = [benchmarkName + ownChoice, benchmarkName]
    barList = dataKeys.map((value, index) => {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            return (
                <Bar key={index} dataKey={value} fill={props.colors[index]} label={{ fill: "black", fontSize: 15, position: "top" }}  stackId="a" barSize={100} />
            )
        }
        else {
            return (
                <Bar key={index} dataKey={value} fill={props.colors[index]} label={{ fill: "black", fontSize: 15, position: "top" }}  stackId="a" barSize={100} />
            )
        }
    });
    return (
        <ResponsiveContainer width="99%" height={600} debounce={2}>
            <BarChart
                //width={1000}
                //height={600}
                data={data}
                margin={{ top: 50, right: 50, left: 140, bottom: 50 }}
                background="#D3D3D3"
            // barGap={3} /* gap between bars*/
            // barCategoryGap={20} /* gap between bargroups*/
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.xAxis} tick={{ fontSize: axisFontSize }} interval={0} />
                <YAxis label={<AxisLabel axisType='yAxis' x={25} y={125} width={0} height={0} > {props.xYaxis} </AxisLabel>} tick={{ fontSize: axisFontSize }} />
                <Tooltip />
                <Legend layout="vertical" verticalAlign="text-top" align="right" maxWidth="20px" />
                {barList};
            </BarChart>
        </ResponsiveContainer>

    )

};


// H O R I Z O N T A L   B A R   C H A R T
// ----------------------------------- 
export function HorizontalBarChart_B(props) {
    // create as many bars as dataKeys are provided 
    let barList;
    // use map if dataKeys is an array (object)
    if (typeof (props.dataKeys) === 'object') {
        barList = props.dataKeys.map((value, index) => {
            // stacked or not
            if (typeof props.stacked !== 'undefined') {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]} label={{ fill: "black", fontSize: 15, position: "right" }} stackId="a" />
                )
            }
            else {
                return (
                    <Bar key={index} dataKey={value} fill={props.colors[index]} label={{ fill: "black", fontSize: 15, position: "right" }} />
                )
            }
        }
        );
    }
    // only add 1 bar, with correct color (can be type string or object)
    else if (typeof (props.dataKeys) === 'string' && typeof (props.colors) === 'string') {

        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors} label={{ fill: "black", fontSize: 15, position: "right" }} stackId="a" />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors} label={{ fill: "black", fontSize: 15, position: "right" }} />
        }
    }
    else {
        // stacked or not
        if (typeof props.stacked !== 'undefined') {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]} label={{ fill: "black", fontSize: 15, position: "right" }} stackId="a" />
        }
        else {
            barList = <Bar dataKey={props.dataKeys} fill={props.colors[0]} label={{ fill: "black", fontSize: 15, position: "right" }} />
        }
    }

    const len = UserWindow() * 0.25;

    // const YaxistickFormatter = (value, index) => {

    //     const limit = 20; // set your maximum character
    //     if (value.length < limit) return value;//if text length larger than the max character length, break lines
    //     return `${value.substring(0, limit)}${value.substring(limit, value.length)}`
    // };

    return (

        // <div className='verticalchart' style={{ width: "80%", height: 500 }}>
            <ResponsiveContainer width="100%" height={600}  debounce={2}>
                <BarChart
                    //width={1000}
                    // height={600}
                    data={props.data}
                    layout="vertical"
                    // barSize={25}
                    // barGap="30%"

                    margin={{ top: 50, right: 50, left: 140, bottom: 50 }}
                >

                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey={props.xAxis} type="category" style = {{overflow: 'visible', overflowWrap: 'break-word'}} width={len * 0.7} tick={{ fontSize: axisFontSize }} />

                    {/* <YAxis dataKey={props.xAxis} type="category" width={len * 0.7} tick={{ fontSize: axisFontSize }} tick={<CustomizedYAxisTick />} /> */}
                    <XAxis type="number" tick={{ fontSize: axisFontSize }} padding={{right:50}}/>
                    <Tooltip />
                    <Legend layout="vertical" verticalAlign="text-bottom" align="right" padding={{ right: 16, left: 0 }} maxWidth="20px"/>
                    {barList};
                </BarChart>

            </ResponsiveContainer>
        // </div>
    )

};



// S I M P L E   L I N E   C H A R T  
export function SimpleLineChart_B(props) {

    // default arguments
    // props.width = (typeof props.width !== 'undefined') ?  props.width : 800;
    // props.height = (typeof props.height !== 'undefined') ?  props.height : 600;

    // create as many lines as dataKeys are provided 
    let lineList;
    let data = props.data

    // use map if dataKeys is an array (object)
    if (typeof (props.dataKeys) === 'object') {
        lineList = props.dataKeys.map((value, index) => {
            // remove missing values from data to prevent from gaps in line chart
            let data_dropna = []
            for (const i in data) {
                const datapoint = data[i]
                if (!isNaN(datapoint[value])) {
                    data_dropna.push(datapoint)
                }
            }
            data = data_dropna

            if (index === 0) {
                return (
                    <Line key={index} type="monotone" dataKey={value} stroke={props.colors[index]} strokeWidth={props.lineWidth} label={{ fill: "black", fontSize: 15, position: "right" }}  activeDot={{ r: 8 }} />
                )
            }
            else {
                return (
                    <Line key={index} type="monotone" dataKey={value} stroke={props.colors[index]} strokeWidth={props.lineWidth} label={{ fill: "black", fontSize: 15, position: "right" }} />
                )
            }
        }
        );
    }

    // only add 1 bar, with correct color (can be type string or object)
    else if (typeof (props.dataKeys) === 'string' && typeof (props.colors) === 'string') {

        lineList = <Line type="monotone" dataKey={props.dataKeys} stroke={props.colors} strokeWidth={props.lineWidth}label={{ fill: "black", fontSize: 15, position: "right" }}   />
    }
    else {

        lineList = <Line type="monotone" dataKey={props.dataKeys} stroke={props.colors[0]} strokeWidth={props.lineWidth}  label={{ fill: "black", fontSize: 15, position: "right" }}  />
    }

    return (

        <ResponsiveContainer width="100%" height={600} debounce={2}>
            <LineChart
                //width={1000}
                //height={600}
                data={data}
                margin={{ top: 50, right: 50, left: 140, bottom: 50 }}
                background="#D3D3D3"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.xAxis} tick={{ fontSize: axisFontSize }} />
                <YAxis label={<AxisLabel axisType='yAxis' x={25} y={125} width={0} height={0}>{props.xYaxis}</AxisLabel>} tick={{ fontSize: axisFontSize }} />
                <Tooltip />
                <Legend layout="vertical" verticalAlign="text-bottom" align="right"  maxWidth="20px"/>
                {lineList}
            </LineChart >
        </ResponsiveContainer>

    )
}







