import * as answers from './survey_matrix_answers';


export const thema_questions_werkomstandigheden1 = {
    type: "rating",
    "rateMin": 0,
    "rateMax": 10,
    name: "werkomstandigheden werkzekerheid",
    title: "Hoe bang zijn jouw medewerkers over werkzekerheid?",
    minRateDescription: "Zeer Negatief",
    maxRateDescription: "Zeer Positief"
}

export const thema_questions_werkomstandigheden2 = {
    type: "rating",
    "rateMin": 0,
    "rateMax": 10,
    name: "werkomstandigheden steun",
    title: "Hoe goed vinden jouw medewerkers de sociale steun op werk?",
    minRateDescription: "Zeer Negatief",
    maxRateDescription: "Zeer Positief"
}