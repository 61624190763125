// import survey modules
import { SurveyComponent } from '../components/survey'
import * as json from '../survey_input/surveys/survey1';
import * as bedrijf from '../survey_input/surveys/bedrijfgegevens1';

import * as standard_questions from '../survey_input/survey_questions'

import React from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useId } from 'react';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// the function which will be rendered
export function Survey_page2(props) {

    const navigate = useNavigate();
    const view_survey_page_employee = () => {
        navigate("/bekijkvragenlijst")
    }

    
    const [forms, setForms] = React.useState(null); // forms related to global Id from DB
    const [results, setResults] = React.useState(null); //pre-filled data from DB
    const [HRInfo, setHRInfo] = React.useState(null); 
    const [revokeChoice, setRevokeChoice] = React.useState(false); 
    const [HRFills, setHRFills] = React.useState('loading'); //
    const [employeeCode, setEmployeeCode] = React.useState(null); //
    const location = useLocation(); // also rerender results when switching pages

    // The questions will be pulled frm the DB
    let globalFormID;// = props.globalFormID; // pull active formID from properties 
    let userId = props.userID; // pull userID from the properties
    if (userId == 70 || userId == 91) {
        globalFormID = 2
    }
    else {
        globalFormID = props.globalFormID
    }

    // remove results from dashboard when they might change in questionnaire page. Also reload when going back to dashboard
    React.useEffect(() => {
        if (location.pathname === "/vragenlijst") {
            updateForms(globalFormID, HRFills)
        }
        else if (location.pathname === "/benchmark") {
        setForms(null)
        }
    }, [location]); // excecutes hook when arguments is [] changes

    // fetch survey IDs from data base (through api)
    const updateForms = async (idForm, HRFills) => {
        if (idForm !== null & typeof HRFills === 'boolean') {
            // console.log("fetch survey");
            await fetch(`/survey_forms?globalFormId=${idForm}&hr=${HRFills}`)
                .then((res) => res.json()) // this is the http response (required)
                .then((json) => setForms(json.result)); // this is the object retrieved (provided by the response in the server)
        }
    }

    React.useEffect(() => {
        updateForms(globalFormID, HRFills)
    }, [globalFormID, HRFills]); // excecutes hook when arguments is [] changes
    
    // 
    React.useEffect(() => {
        if (HRInfo) {
            if (HRInfo !== 'choose') {
                setEmployeeCode(HRInfo['employeeCode'])
                setHRFills(!!HRInfo['HRFillsSurvey'])
            }
            else {
                setHRFills('choose')
            }
        }

    }, [HRInfo]); // excecutes hook when arguments is [] changes

    // fetch survey results from data base (through api)
    React.useEffect(() => {
        if (userId !== null && globalFormID !== null & typeof HRFills === 'boolean') {
            // console.log("fetch results survey page");
            fetch(`/results_plain?organizationId=${userId}&globalFormId=${globalFormID}&hr=${HRFills}`)
                .then((res) => res.json()) // this is the http response (required)
                .then((json) => {
                    setResults(json.result)
                }); // this is the object retrieved (provided by the response in the server)
        }
    }, [userId, globalFormID, location, HRFills]); // executes hook when arguments is [] changes
    
    // fetch HRFills, but only if HR !== 'revoke', because then the result should first be remove from db and HR becomes 'loading'
    React.useEffect(() => {
        if (userId !== null && globalFormID !== null && HRFills !== 'revoke') {
            fetch(`/hrfills?organizationId=${userId}&globalFormId=${globalFormID}`)
                .then((res) => res.json()) // this is the http response (required)
                .then((json) => setHRInfo(json.result)); // this is the object retrieved (provided by the response in the server)
                // .then((json) => setEmployeeCode(json.employeeCode)); // this is the object retrieved (provided by the response in the server)
        }
    }, [userId, globalFormID, location, HRFills]); // executes hook when arguments is [] changes
    
    // update HRFills
    React.useEffect(() => {
        if ((typeof userId === 'number' && typeof globalFormID === 'number' && typeof HRFills === 'boolean' && (HRInfo === null | HRInfo === 'choose'))) {
            fetch(`/update_hrfills?organizationId=${userId}&globalFormId=${globalFormID}&hr=${HRFills}`)
            setHRFills('loading')
            setHRInfo(null)
        }
        else if (revokeChoice === true) {
            let result = window.confirm("Weet je het zeker? Hiermee worden opgeslagen resultaten voor deze vragenlijst verwijderd.");
            if (result) {
                setEmployeeCode(null)
                //Logic to delete the item
                fetch(`/update_hrfills?organizationId=${userId}&globalFormId=${globalFormID}&hr=revoke`)
                setHRFills('loading')
                setHRInfo(null)
                setRevokeChoice(false)
            }
            else {
                setRevokeChoice(false)
            }
        }
    }, [userId, globalFormID, location, HRFills, revokeChoice]); // executes hook when arguments is [] changes

    // show load until: results, and employeeCode (hr fills in or not) are in
    if (HRFills === 'loading' | HRFills === false & !employeeCode) {
        return (
            <div className="survey">
                <h1>Bezig met laden</h1>
            </div>
        )
    }
    else if (typeof HRFills !== 'boolean') {
        // if (userId == 70 || userId == 91) {  // hardcoded so that on VV's showcase account the HR fills in survey can still be revoked
        //     return (
        //         <div className="top_banner_buttons">
        //             <button id="button_hr_fills_survey"  type='button' onClick={() => { setHRFills(true) }}>HR vult de vragenlijst in</button>
        //             <button id="button_employees_fill_survey"  type='button' onClick={() => { setHRFills(false) }}>De medewerkers vullen de vragenlijst in</button>
        //         </div>
        //     )
        // }
        // else {
        return (
            <div className="top_banner_buttons">
                <button id="button_hr_fills_survey"  type='button' onClick={() => { setHRFills(true) }}>HR vult de vragenlijst in</button>
                {/* <button id="button_hr_fills_survey" disabled type='button' onClick={() => { setHRFills(true) }}>HR vult de vragenlijst in</button> */}
                <button id="button_employees_fill_survey"  type='button' onClick={() => { setHRFills(false) }}>De medewerkers vullen de vragenlijst in</button>
            </div>
        )
        // }
    }
    

    // create survey if forms are defined (otherwise will run into errors)
    else if (forms) {
        // Allow showcase account, Mirjam & our testing account to always view survey
        if (forms[0]['closed'] === 1) {
            if (![9,14,70,91,97, 99, 100, 101, 102, 103].includes(userId)) {
                return (
                    <div className="survey">
                        <h1>Er is momenteel geen vragenlijst beschikbaar </h1>
                    </div>
                )
            }
        }
        // if (forms & results & employeeCode) {
        // R E A D   S U R V E Y   F R O M   D B 
        // --------------------------------------
        let json_surveys = {};
        let currentTopic = null
        let json_data = {}  // Contains previous results: {questionId: 'previous result'}
        let questions = []  // list with survey.js elements. Cleared after each panel
        let surveyList = []
        let currentFormSection = null
        const formId = forms[0]['idForm']
        let firstRatingQuestion = true

        // loop over all questions
        for (const iQuestion in forms) {
            const question = forms[iQuestion];

            const questionType = question.questionType; // question type relates to type of graph
            const questionId = question.idQuestion; // the id of the question
            let questionTopic = question.topic;   // the topic of the question
            let questionTopicDescription = question.topicDescription;   // the topicDescription of the question
            const questionTitle = question.questionHR;  // the question for HR people
            const resultFormat = question.resultFormat; // not used
            const answers_json = question.answers_json; // only non-null for specific question types
            let QuestionFormSection = question.FormSection  // the formSection of the current question. e.g. 'Standaard vragen' or 'Thema vragen'
            let questionFormSectionDescription = question.formSectionDescription  // the formSectionDescription of the current question. e.g. 'Standaard vragen' or 'Thema vragen'
            let formTitle = question.name //title of the format
            let questionDescription = question.descriptionHR    //description of the question

            if (questionDescription === 'null') {
                questionDescription = null
            }
            if (HRFills === false) {
                formTitle = "Algemene vragen voor HR"
                // questionTopic = null
                // questionTopicDescription = null
                // QuestionFormSection = null
                // questionFormSectionDescription = null
            }

            // ============================== CREATE TITLES AND PANELS (STEP 2 - AFTER GATHERING DATA) =============================================

            if (!Object.keys(json_surveys).includes('elements')) {
                json_surveys['elements'] = []
            }
            // Update the current topic tracker. 
            if (!currentTopic) {
                currentTopic = questionTopic
            } 
            // If the topic from the new question is different from the current topic we're gathering, push the panel with the questions
            if (questionTopic !== currentTopic || currentFormSection !== QuestionFormSection) {
                json_surveys['elements'].push({ 'type': 'panel', 'elements': questions })
                questions = []
                if (questionTopicDescription !== null){
                    questions.push({type:'html', name:'box', html:{default:`<div class="survey_pgh"><p>${questionTopicDescription} </p></div>`}})
                }
                currentTopic = questionTopic
                firstRatingQuestion = true
            }
            if (currentFormSection !== QuestionFormSection) {           // Should be standaard vragen or thema vragen --------- does not work yet
                if (QuestionFormSection) {
                    // insert questionFormSection and description as html to panel
                    questions.push({type:'html', name:'box', html:{default:`<div class="survey_description">${QuestionFormSection}</div><br><div class="survey_pgh">${questionFormSectionDescription} </div>`}})
                }
                // else {
                //     questions.push({type:'html', name:'box', html:{default:`<div class="survey_topic_title">Thema vragen</div><br>Hello`}})
                // }
                
                currentFormSection = QuestionFormSection
            }
            // if (questionTopic !== currentTopic) {
            //     questions.push({type:'html', name:'box', html:{default:`<div class="description_topic">${questionTopicDescription}</div>`}})
            // }
            // Set overall formtitle. Will be rendered on top of page when form is created
            if (!Object.keys(json_surveys).includes('title')) {
                json_surveys['title'] = formTitle
            }
            
            // render description for the first rating/liker question 
            if (questionType === "likert" & firstRatingQuestion === true) {
                // hardcode lines
                if (questionTitle.startsWith("Ervaar jij problemen tijdens het samenwerken met collega’s die een stuk ouder en/of jonger zijn dan jou?")) {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Geef een cijfer tussen de 1 (heel veel) en de 10 (helemaal niet)</p></div>`}})
                }
                else if (questionTitle.startsWith("De sfeer op de werkvloer")) {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Geef een cijfer tussen de 1 (helemaal niet belangrijk) en de 10 (enorm belangrijk). Als een vraag niet van toepassing is voor jouw functie, vul dan 1 in.</p></div>`}})
                }
                else if (questionTitle.startsWith("Ik kan mijn werk") || questionTitle.startsWith("Mijn leidinggevende begrijpt mijn") || questionTitle.startsWith("Ik weet bij welke organisaties")) {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Hieronder staat een aantal zinnen.<br>Geef elke zin een cijfer tussen 1 en 10. Een 1 betekent dat je het <u>helemaal niet eens</u> bent met de zin. Een 10 betekent dat je het <u>helemaal eens</u> bent met de zin. Als de zin niet over jou gaat, vul dan 1 in.</p></div>`}})
                }
                else {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Hieronder staat een aantal zinnen.<br>Geef elke zin een cijfer tussen 1 en 10. Een 1 betekent dat je het <u>helemaal niet eens</u> bent met de zin. Een 10 betekent dat je het <u>helemaal eens</u> bent met de zin.</p></div>`}})
                }
                firstRatingQuestion = false
            }
            else if (questionType !== "likert") {
                firstRatingQuestion = true
            }
            if (questionDescription) {
                questions.push({type:'html', name:'box', html:{default:`<div class="description_position">${questionDescription} </div>`}}) 
            }
            // ============================== GATHERING DATA (SURVEY.JS ELEMENTS ARE MADE HERE) (STEP 1 - ) =============================================
            // questions are added to list questions, which is contains the survey.js elements
            if (questionType === "percentage") {
                questions.push(standard_questions.standard_question_number(questionId, questionTitle, questionDescription))
                
            }
            else if (questionType === "likert") {
                questions.push(standard_questions.standard_question_rating(questionId, questionTitle, questionDescription))
            }
            else if (questionType === "option") {
                // convert string with options to array
                let l = [];
                l = l.concat(answers_json.slice(2, -2).split(','))

                questions.push(standard_questions.standard_question_radio(questionId, questionTitle, l, questionDescription))
            }
            else if (questionType === "multiple_result") {
                // parse the title in correct format
                const questionTitle_object = JSON.parse(questionTitle);
                let questionTitle_multiple = Object.keys(questionTitle_object)[0];;
                let answers_json_multiple = questionTitle_object[questionTitle_multiple];
                questions.push(standard_questions.standard_questions_matrixNumbers(questionId, questionTitle_multiple, answers_json_multiple, question.startDate))
            }
            // If there are previous results, these have to be autofilled
            // These are added to json_data --> Contains previous results
            if (results) {
                for (let i in results) {
                    let result = results[i]
                    if (result.idQuestion === questionId) {
                        if (questionType === 'multiple_result') {
                            json_data[questionId] = [JSON.parse(result['results_json'])]
                        }
                        else {
                            json_data[questionId] = result['results_json']
                        }
                    }
                }
            
                // console.log(results)
                // console.log(iQuestion)

                // console.log(results[iQuestion]['results_json'])
                // json_data[questionId] = results[iQuestion]['results_json']
            }


        }
        // ================== ADD FINAL TOPIC PANEL AFTER LOOP HAS ENDED ========================
        json_surveys['elements'].push({ 'type': 'panel', 'elements': questions })

        surveyList.push(<SurveyComponent key={formId} json={json_surveys} surveyId={formId} userId={userId} surveyData={json_data} employee = {false} employeeCode = {employeeCode} />)            
        

        // R E T U R N  T H E   S U R V E Y
        // --------------------------------
        if (employeeCode) {
            if (userId == 70 || userId == 91) {  // hardcoded so that on VV's showcase account the HR fills in survey can still be revoked
                return (
                    // <div style="border-type=solid; border-color=white; border-right-width=50pt">
                    <div>
                        <div className="top_banner">De code waarmee de medewerkers de vragenlijst in kunnen vullen is: &nbsp;
                            {employeeCode}
                            <br></br>
                            Stuur hierbij ook de volgende link mee: <a>https://dashboard.vitaalvechtdal.nl/</a>
                            <br></br>
                            Vergeet niet om ook de vragen hieronder zelf in te vullen.
                        </div>
                        <div className="banner_survey_choice">
                        <div className="revoke_hr_choice">
                            Je kunt je keuze voor de manier waarop de vragenlijst wordt ingevuld intrekken. Let op: hiermee worden alle reeds ingevulde resultaten voor deze vragenlijst verwijderd.
                            <button id="button_hr_fills_survey_3"  type='button' onClick={() => { setRevokeChoice(true) }}>Keuze intrekken</button>
                            {/* <button id="button_hr_fills_survey_3" disabled  type='button' onClick={() => { setRevokeChoice(true) }}>Keuze intrekken</button> */}
                        </div>
                        <div className="view_employee_survey_2">
                            Je kunt de vragenlijst voor de medewerkers hier bekijken
                            <br></br>
                            <a className="button-view_employee_survey_1" onClick={view_survey_page_employee}> Bekijk de vragenlijst  </a>
                        </div>
                        
                        </div>
                        <br></br>
                        <br></br>
                        <div className="survey">
                            {surveyList}
                        </div>

                        <div className="bottom_bar_fixed">
            
                    <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
                    <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
                    <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
                    <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

                    </div>

                    </div>
                )
            }
            
            else {
                return (
                    // <div style="border-type=solid; border-color=white; border-right-width=50pt">
                    <div>
                        <div className="top_banner">De code waarmee de medewerkers de vragenlijst in kunnen vullen is: &nbsp;
                            {employeeCode}
                            <br></br>
                            Stuur hierbij ook de volgende link mee: <a>https://dashboard.vitaalvechtdal.nl/</a>
                            <br></br>
                            Vergeet niet om ook de vragen hieronder zelf in te vullen.
                        </div>
                        <div className="banner_survey_choice">
                        <div className="revoke_hr_choice">
                            Je kunt je keuze voor de manier waarop de vragenlijst wordt ingevuld intrekken. Let op: hiermee worden alle reeds ingevulde resultaten voor deze vragenlijst verwijderd.
                            <button id="button_hr_fills_survey_3"  type='button' onClick={() => { setRevokeChoice(true) }}>Keuze intrekken</button>
                            {/* <button id="button_hr_fills_survey_3" disabled  type='button' onClick={() => { setRevokeChoice(true) }}>Keuze intrekken</button> */}
                        </div>
                        <div className="view_employee_survey_2">
                            Je kunt de vragenlijst voor de medewerkers hier bekijken
                            <br></br>
                            <a className="button-view_employee_survey_1" onClick={view_survey_page_employee}> Bekijk de vragenlijst  </a>
                        </div>
                        
                        </div>
                        <br></br>
                        <br></br>
                        <div className="survey">
                            {surveyList}
                        </div>
                        <button className="Afdrukken" onClick={() => downloadFile("/vragenlijsten/Vragenlijst-Werk-en-mantelzorgtaken.pdf", "Vragenlijst Werk en mantelzorgtaken.pdf")}>Download vragenlijst </button>

                        <div className="bottom_bar_fixed">
            
            <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
            <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
            <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
            <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

        </div>

                    </div>
                )
            }
        }
        else {
            return (
                // <div style="border-type=solid; border-color=white; border-right-width=50pt">
                <div className="banner_survey_choice">
                    <div className="revoke_hr_choice">
                        Je keuze voor de manier waarop de vragenlijst wordt ingevuld terugtrekken. Let op: hiermee worden alle reeds ingevulde resultaten voor deze vragenlijst verwijderd.
                        <button id="button_hr_fills_survey_3"  type='button' onClick={() => { setRevokeChoice(true) }}>Keuze intrekken</button>
                    </div>
                    <div className="survey">
                        {surveyList}
                    </div>
                    <button className="Afdrukken" onClick={() => downloadFile("/vragenlijsten/vragenlijst-stress.pdf", "Vragenlijst Stress.pdf")}>Download vragenlijst</button>

                    <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

    </div>
    
                </div>
            )
        }
    }

    // R E T U R N   E R R O R    S U R V E Y   W H E N   N O   F O R M S   A R E   D E F I N E D
    // ------------------------------------------------------------------------------------------

    else {
        return (

            <div className="survey">
                <h1>Bezig met het laden van de vragenlijst. </h1>
            </div>
        )
    }
};


// B U S I N E S S   P A G E 
// -------------------------


// the function which will be rendered
export function Bedrijfsgegevens_page2(props) {

    const [business_data, setBusiness] = React.useState(null);
    const location = useLocation(); // also rerender results when switching pages


    let userId = props.userID; // pull userID from the properties


    // fetch business data from data base (through api)
    React.useEffect(() => {
        if (userId != null) {
            // console.log("fetch survey");
            fetch(`/business_data?organizationId=${userId}`)
                .then((res) => res.json()) // this is the http response (requiered)
                .then((json) => setBusiness(json.result)); // this is the object retrieved (provided by the response in the server)
        }
    }, [userId, location]); // excecutes hook when arguments is [] changes

    let json_data = {}; // survey prefilled data (if present in the DB)

    if (userId) {

        // get prefilled in survey (if exists)
        if (business_data) {
            if (business_data.length != 0) {

                // console.log(`from business page =`);
                // console.log(business_data);

                json_data['companyName'] = business_data[0].companyName;
                json_data['legalForm'] = business_data[0].legalForm;
                json_data['estYear'] = business_data[0].estYear;
                json_data['industry'] = business_data[0].industry;
                json_data['nEmployees'] = business_data[0].nEmployees;
                json_data['pctOfficeEmployees'] = business_data[0].pctOfficeEmployees;
                json_data['zipCode'] = business_data[0].zipCode;

            }
            // otherwise no data defined
            else {
                json_data = null;
            }
        }
        else {
            json_data = null;
        }
    }
    else {
        json_data = null;
    }


    // reset username/password type script 
    return (
        <div >

            <div className="accountsettings-container"> 
                <div className="accountsettings-heading"> Accountinstellingen  </div>
            {/* <div className='accountsettings_1'>
                <Link to="/gebruikersnaamwijzigen"> Gebruikersnaam wijzigen </Link>
            </div> */}
            <div className='accountsettings_2'>
                <Link to="/wachtwoordwijzigen"> Wachtwoord wijzigen </Link>
            </div>
            </div>

            <div className="survey">
                <SurveyComponent json={bedrijf.survey_json1} userId={userId} surveyData={json_data} businessSurvey={true} employee = {false} />
            </div>

            <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

    </div>


        </div>
    )
};
