export function standard_questions_verzuim(month) {

    return {
        "name": `verzuim ${month}`,
        "type": "text",
        "inputType": "number",
        "title": `Wat was het percentage verzuim in ${month}?:`,
        "isRequired": true
    };
};

export function standard_questions_gezondheid(topic) {
    return {
        type: "rating",
        "rateMin": 0,
        "rateMax": 10,
        name: `gezondheid ${topic}`,
        title: `Wat is de gemiddelde score van jouw werknemers op ${topic}?`,
        minRateDescription: "Zeer Negatief",
        maxRateDescription: "Zeer Positief"
    };
}

export const standard_questions_gezondheid1 = {
    "name": `gezondheid #werknemers`,
    "type": "text",
    "inputType": "number",
    "title": `Wat is het aantal werknemers dat meedoet aan het invullen van de tool?:`,
    "isRequired": true
}


export const standard_questions_gezondheid2 = {
    "name": `gezondheid percentage`,
    "type": "text",
    "inputType": "number",
    "title": `Hoeveel percentage zijn deze werknemers van het totaal?:`,
    "isRequired": true
}


export const standard_questions_gezondheid3 = {
    "name": `gezondheid factoren`,
    "type": "text",
    "inputType": "number",
    "title": `Wat is het percentage werknemer dat aangeeft dat aan individuele en leefstijlfactoren aandacht is besteed?:`,
    "isRequired": true
}


export const standard_questions_gezondheid4 = {
    "name": `gezondheid omstandigheden`,
    "type": "text",
    "inputType": "number",
    "title": `Wat is het percentage werknemer dat aangeeft dat leef, woon en werkomstandigheden zijn veranderd na het invullen van de vorige vragenlijst?:`,
    "isRequired": true
} 