export const questions_working1 = {
    type: "rating",
    "rateMin": 0,
    "rateMax": 10,
    name: "thuiswerken",
    title: "Hoe hebben je werknemers het thuiswerken ervaren?",
    minRateDescription: "Zeer Negatief",
    maxRateDescription: "Zeer Positief"
}

export const questions_working2 = {
    type: "rating",
    "rateMin": 0,
    "rateMax": 10,
    name: "fysiek",
    title: "Welke invloed heeft het gehad om de fysieke gezondheid van werknemers?",
    minRateDescription: "Zeer Negatief",
    maxRateDescription: "Zeer Positief"
}


export const questions_working3 = {
    type: "rating",
    "rateMin": 0,
    "rateMax": 10,
    name: "mentaal",
    title: "Welke invloed heeft het gehad om de mentale gezondheid van werknemers?",
    minRateDescription: "Zeer Negatief",
    maxRateDescription: "Zeer Positief"
}


export const questions_working4 = {
    type: "rating",
    "rateMin": 0,
    "rateMax": 10,
    name: "hulp",
    title: "Hoe scoren jouw werknemers de hulp die jouw bedrijf heeft gegeven voor het thuiswerken?",
    minRateDescription: "Zeer Negatief",
    maxRateDescription: "Zeer Positief"
}
