import { survey_json1 } from "./surveys/survey1";

export function standard_question_number(name, title, type) {
    let validators = [
        {
            "type": "numeric",
            "text": "Vul een positief getal in",
            "minValue": 0
        }
    ]
    if (type === 'zip') {
        validators = [
            {
                "type": "regex",
                "text": "Vul de vier cijfers van je postcode in",
                "regex": "^\\d{4}$"
            }
        ]
    }
    else if (type === 'year') {
        validators = [
            {
                "type": "regex",
                "text": "Vul een geldig jaar in",
                // "regex": "^\\d{4}$"
                "regex": "^[12][0-9]{3}$"
            }
        ]
    }
    if (title.includes('procent') | title.includes('percentage')) {
        validators = [
            {
                "type": "numeric",
                "text": "Vul een percentage tussen 0 en 100 in",
                "minValue": 0,
                "maxValue": 100
            }
        ]
    }
    return {
        "name": `${name}`,
        "type": "text",
        "inputType": "number",
        "title": `${title}`,
        "isRequired": true,
        "validators": validators
    };
};


/**
 * 
 * @param {String} name 
 * @param {String} title 
 * @param {String} type if "email" ensures email address input
 * @returns 
 */
export function standard_question_text(name, title, type) {
    let validators = []
    if (type === 'email') {
        validators = [
            {
                "type": "email"
            }
        ]
    }
    return {
        "name": `${name}`,
        "type": "text",
        "inputType": "text",
        "title": `${title}`,
        "isRequired": true,
        "validators": validators
    };
};


export function standard_question_rating(name, title) {
    return {
        // "type": "rating",
        // "rateMin": 0,
        // "rateMax": 10,
        // "name": `${name}`,
        // "title": `${title}`,
        // "isRequired": true,
        // "minRateDescription": "Zeer Negatief",
        // "maxRateDescription": "Zeer Positief"

        type: "rating",
        "rateMin": 0,
        "rateMax": 10,

        "rateValues": [
            // {      
            // "value": 0,
            // },
            {
            "value": 1,
            // "text": "😡"
            },
            {
            "value": 2,
            // "text": "🙁"
            },
            {
            "value": 3,
            // "text": "😐"
            },
            {
            "value": 4,
            // "text": "🙂"
            },  
            {      
            "value": 5,
            // "text": "😀"
            },
            {      
            "value": 6,
            },
            {      
            "value": 7,
            },
            {      
            "value": 8,
            },
            {      
            "value": 9,
            },
            {      
            "value": 10,
            }
            ],
        "name": `${name}`,
        "title": `${title}`,
        "isRequired": true,
        "minRateDescription": "",
        "maxRateDescription": ""


    };
}
export function standard_question_radio(name, title, choices) {
    return {
        "type": "radiogroup",
        "name": `${name}`,
        "title": `${title}`,
        "isRequired": true,
        "hasNone": false,
        "colCount": 1,
        "choices": choices
    };
}

export function standard_question_check(name, title, choices) {
    return {
        "type": "checkbox",
        "name": `${name}`,
        "title": `${title}`,
        "isRequired": true,
        "hasNone": true,
        "colCount": 1,
        "choices": choices,
    };
}

const monthDict = {
    1: "Januari",
    2: "Februari",
    3: "Maart",
    4: "April",
    5: "Mei",
    6: "Juni",
    7: "Juli",
    8: "Augustus",
    9: "September",
    10: "Oktober",
    11: "November",
    12: "December"
  }

  export function monthTitle(name, startDate = null) {
    if (!startDate) {
        return name
    }
    if (typeof startDate !== Date) {
        startDate = new Date(startDate)
    }
    const curMonth = startDate.getMonth()
    let year = startDate.getFullYear()
    let monthNumber
    if (name === "1 maand geleden") {
      monthNumber = curMonth
    }
    else if (name === "2 maanden geleden") {
      monthNumber = curMonth - 1
    }
    else if (name === "3 maanden geleden") {
      monthNumber = curMonth - 2
    }
    else if (name === "4 maanden geleden") {
      monthNumber = curMonth - 3
    }
    else {
      return name
    }
    if (monthNumber <= 0) {
      monthNumber += 12
      year -= 1
    }
    return `${monthDict[monthNumber]} ${year}`
  }

export function standard_questions_matrixNumbers(name, title, choices, startDate) {
// export function standard_questions_matrixNumbers(name, title, choices, monthDict) {
    let validators = []
    if (title.includes('procent') | title.includes('percentage')) {
        validators = [
            {
                "type": "numeric",
                "text": "Vul een percentage tussen 0 en 100 in",
                "minValue": 0,
                "maxValue": 100
            }
        ]
    }
    let columns;

    columns = Object.keys(choices).map((key, index) => {

        let title = monthTitle(choices[key], startDate)
        let value = choices[key]

        return (
            {
                "name": `${index}`, // does not work like this
                "title": title,
                "value": value,
                "isRequired": true,
                "inputType": "number",
                "validators": validators,
            }
        );
    });

    // console.log("from questions");
    // console.log(columns);

    return {
        "type": "matrixdynamic",
        "title": `${title}`,
        "name": `${name}`,
        // "isRequired": true,
        "cellType": "text",
        "allowAddRows": false,
        "allowRemoveRows": false,
        "rowCount": 1,
        "columns": columns 
    }
}

