import React, { useState } from 'react';
import './pages.css';

import { useNavigate } from "react-router-dom";

// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';
// import routes from '../../../back-end/config/routes';


// login pages that gets exported
export function How_to_use(props) {


  return (
    <body className="landing_page" >
      
      <div className="extra_about-title"> Hoe te gebruiken </div>


      <div className="about-container" > 
      

        <h1 className="extra_info_title">Mijn dashboard</h1>
        <p className="extra_info_pgh">Het dashboard toont de resultaten van de vragen over gezond en vitaal werken. Daarnaast worden de resultaten over een specifiek themadeel belicht. Dit themadeel zal per uitvraag een ander onderwerp onder de loep nemen. In mijn dashboard lees je de meest recente resultaten van eigen medewerkers. Elke 4 maanden komt er een factsheet Werk en Vitaliteit uit gebaseerd op de antwoorden van alle deelnemers.</p>
        <h1 className="extra_info_title">Thema</h1>
        <p className="extra_info_pgh">In iedere uitvraag zal eenmalig een specifiek, actueel thema onder de loep worden genomen. Voorbeelden zijn hybride werken of financiële stress.</p>
        <h1 className="extra_info_title">Benchmark</h1>
        <p className="extra_info_pgh">De prestaties van de organisatie kun je vergelijken met andere bedrijven uit het Vechtdal die meedoen met het monitoren van Vitaliteit op de werkvloer (alle sectoren). Je kunt ook de organisatie vergelijken met vergelijkbare partijen in dezelfde branche (indien er meerdere bedrijven uit zelfde branche meedoen). Door jezelf naast andere bedrijven te leggen, ontdek je wat er wel of niet goed gaat. En dus ook wat er beter kan.</p>
      </div>

      <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

    </div>
      </body>
  )
}
