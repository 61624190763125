import React, { useState } from 'react';
import './pages.css';

import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";


// login pages that gets exported
export function Change_username(props) {

  const navigate = useNavigate();
  const company_info_page = () => {
    navigate("/bedrijfsgegevens")
  }

    // state variables definitions and the functions how to assign their values
    const [username, setUsername] = React.useState(null); //pre-filled data from DB
    const [usernameNew, setUsernameNew] = React.useState(null); 
    const [error, setError] = useState("");

    const userId = props.userID

    // Retrieve the current username only once when loading the page
    if (username === null & userId !== null) {
        fetch(`/getusername?userId=${userId}`)
            .then((res) => res.json()) // this is the http response (requiered)
            .then((json) => setUsername(json.username)); // this is the object retrieved (provided by the response in the server)
    }

    // function that calls backend for updating the username
    async function updateUsername(credentials) {
        const username = credentials.username
        const userId = credentials.userId
        const message = await fetch(`/updateusername?userId=${userId}&username=${username}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        return message
    }   

  // function that handels the login button and sets the token if login is correct
  const handleSubmit = async (e) => {
    //   Make sure that no fields are empty
    e.preventDefault();
    const response = await updateUsername({'userId':userId, 'username':username});
    // if response is 200, status is success
    if (response.status === 200) {
        setError("Gebruikersnaam gewijzigd")
    }
    else {
        setError("Deze gebruikersnaam is niet beschikbaar")
    }
  }

    
  
  return (
    <body className="account-wrapper">
    <div className="login-wrapper">
 
      <div className="changes-background">  
      <div className="account-changes"> Gebruikersnaam wijzigen </div>
      <form  className="username-block" onSubmit={handleSubmit}>
        <label>
          <h3 className="username-title" style={{ 'textAlign': 'center' }}>Gebruikersnaam</h3>
          <input className="center-block"  autoFocus value={username} placeholder="Gebruikersnaam" required={true} onChange={e => setUsername(e.target.value)} />
        </label>
        <div>
          <br />
          <button className='button_login-account' type="submit">Gebruikersnaam wijzigen</button>
        </div>
        <div>
          <br />
          <a className="button-account_page" onClick={company_info_page}>   ←  Ga terug  </a>
        </div>
      </form>
      </div>

      {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
      <div  className="marginBottom" style={{ "marginBottom": "-9vw" }} >   </div>

      {(error !== "") ? (<div className='error'>
        <h3 className="error-username"> {error} </h3>
      </div>) : ""}

    </div>
    </body>

  )
}