import * as answers from './survey_matrix_answers';

export const questions_flexworking = {
    "type": "matrix",
    "title": "Geef antwoord op de volgende uitspraken:",
    "name": "question_flex",
    // "isRequired": true,
    "isAllRowRequired": true,
    "columns": answers.answers_ervaring5,
    "rows": [
        {
            "value": "ervaring",
            "text": "Hoe hebben je werknemers het thuiswerken ervaren?"
        },
        {
            "value": "fysieke_gezondheid",
            "text": "Welke invloed heeft het gehad om de fysieke gezondheid van werknemers?"
        },
        {
            "value": "metale_gezondheid",
            "text": "Welke invloed heeft het gehad om de mentale gezondheid van werknemers?"
        },
        {
            "value": "hulp",
            "text": "Hoe scoren jouw werknemers de hulp die jouw bedrijf heeft gegeven voor het thuiswerken?"
        }
    ]
};

export const questions_openInput = {
    "type": "matrixdynamic",
    "title": "Welk percentage van jouw werknemers wil:",
    "name": "question_open",
    // "isRequired": true,
    "cellType": "text",
    "allowAddRows": false,
    "allowRemoveRows": false,
    "rowCount": 1,
    "columns": [
        {
            "name": "volledig op kantoor werken",
            "isRequired": true,
            "inputType": "number",
        },
        {
            "name": "1 dag per week thuis werken",
            "isRequired": true,
            "inputType": "number",
        },
        {
            "name": "2 dagen per week thuis werken",
            "isRequired": true,
            "inputType": "number",
        },
        {
            "name": "3 dagen of meer per week thuis werken",
            "isRequired": true,
            "inputType": "number",
        },
        {
            "name": "anders",
            "isRequired": true,
            "inputType": "number",
        }
    ]
}

export const questions_beleid = {
    "type": "radiogroup",
            "name": "beleid",
            "title": "Wat wordt jouw toekomstige beleid voor thuiswerken?",
            "isRequired": true,
            "hasNone": false,
            "colCount": 1,
            "choices": [
                "Volledig op werk",
                "3 dagen werk, 2 dagen thuis",
"Volledig flexibel werken",
"Geen beleid",
"Anders",]}  

export const questions_thuis = {
    "type": "radiogroup",
            "name": "beleid",
            "title": "Konden mensen binnen jouw bedrijf thuiswerken?",
            "isRequired": true,
            "hasNone": false,
            "colCount": 1,
            "choices": [
                "Ja",
                "Nee",
"Een deel",]} 