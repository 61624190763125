// imports always at the top
import * as standard_questions from '../survey_questions'

export const survey_test = {
    // "title" : "hee1",
    "elements": [
        {
            "type": "panel",
            "title": "Verzuim",
            "elements": [
                standard_questions.standard_question_number("verzuim1", "Wat was het percentage verzuim in januari"),
                standard_questions.standard_question_rating("gezondheid1", "Wat is de gemiddelde score van jouw werknemers op dagelijks functioneren"),
                standard_questions.standard_questions_matrixNumbers("flex2", "Hoeveel uren per week willen jouw medewerkers op kantoor werken", ["40 uur", "32 uur", "24 uur", "<16 uur", "Anders"]),
                standard_questions.standard_question_radio("flex3", "Heb je al een beleid om flexibel te kunnen werken", ["Ja", "Nee", "Mee bezig"]),
            ]
        },
    ]
}


export const survey_json1 = {
    "title": "Standaard vragen",
    "elements": [
        {
            "type": "panel",
            "title": "Verzuim",
            "elements": [
                standard_questions.standard_question_number("verzuim1", "Wat was het percentage verzuim in januari"),
                standard_questions.standard_question_number("verzuim2", "Wat was het percentage verzuim in februari"),
                standard_questions.standard_question_number("verzuim3", "Wat was het percentage verzuim in maart"),
            ]
        },

        {
            "type": "panel",
            "title": "Positieve gezondheid ",
            "elements": [
                standard_questions.standard_question_rating("gezondheid1", "Wat is de gemiddelde score van jouw werknemers op dagelijks functioneren"),
                standard_questions.standard_question_rating("gezondheid2", "Wat is de gemiddelde score van jouw werknemers op meedoen"),
                standard_questions.standard_question_rating("gezondheid3", "Wat is de gemiddelde score van jouw werknemers op kwaliteit van leven"),
                standard_questions.standard_question_rating("gezondheid4", "Wat is de gemiddelde score van jouw werknemers op zingeving"),
                standard_questions.standard_question_rating("gezondheid5", "Wat is de gemiddelde score van jouw werknemers op mentaal welbevinden"),
                standard_questions.standard_question_rating("gezondheid6", "Wat is de gemiddelde score van jouw werknemers op lichaamsfuncties"),
            ]
        },
        {
            "type": "panel",
            "title": "Participerende werknemers",
            "elements": [

                standard_questions.standard_question_number("participatie1", "Wat is het aantal werknemers dat meedoet aan het invullen van de tool"),
                standard_questions.standard_question_number("participatie2", "Hoeveel percentage zijn deze werknemers van het totaal"),
                standard_questions.standard_question_number("participatie3", "Wat is het percentage werknemers dat aangeeft dat aan individuele en leefstijlfactoren aandacht is besteed"),
                standard_questions.standard_question_number("participatie4", "Wat is het percentage werknemers dat aangeeft dat leef, woon en werkomstandigheden zijn veranderd na het invullen van de vorige vragenlijst"),
            ]

        },

    ]
}


export const survey_json2 = {
    "title": "Thema vragen",
    "elements": [


        {
            // T H E M A 
            // ---------
            "type": "panel",
            "title": "Flexibel werken",
            "elements": [
                standard_questions.standard_questions_matrixNumbers("flex1", "Hoeveel uren per week werken jouw medewerkers nu op kantoor", ["40 uur", "32 uur", "24 uur", "<16 uur", "Anders"]),
                standard_questions.standard_questions_matrixNumbers("flex2", "Hoeveel uren per week willen jouw medewerkers op kantoor werken", ["40 uur", "32 uur", "24 uur", "<16 uur", "Anders"]),
                standard_questions.standard_question_radio("flex3", "Heb je al een beleid om flexibel te kunnen werken", ["Ja", "Nee", "Mee bezig"]),
            ]
        },
        {
            "type": "panel",
            "title": "Werkomstandigheden",
            "elements": [
                standard_questions.standard_question_rating("omstandigheden1", "Hoe bang zijn jouw medewerkers over werkzekerheid"),
                standard_questions.standard_question_rating("omstandigheden2", "Hoe goed vinden jouw medewerkers de sociale steun op werk"),
                standard_questions.standard_question_rating("omstandigheden3", "Hoe scoren jouw medewerkers de cultuur binnen het bedrijf"),
                standard_questions.standard_question_rating("omstandigheden4", "Hoe vinden jouw medewerkers de werkdruk"),
                standard_questions.standard_question_rating("omstandigheden5", "Hoe hoog is de werkdruk voor jouw medewerkers"),
                standard_questions.standard_question_rating("omstandigheden6", "Hoe hoog scoren jouw medewerkers het bedrijf"),
                standard_questions.standard_question_rating("omstandigheden7", "Hoe vaak maken jouw medewerkers regelmatig vervelende situaties mee op werk"),
            ]
        },
        {
            "type": "panel",
            "title": "Individuele factoren",
            "elements": [
                standard_questions.standard_question_rating("factoren1", "Hoe scoren jouw medewerkers hun financiële situatie"),
                standard_questions.standard_question_rating("factoren2", "Hoe goed slapen jouw medewerkers de afgelopen twee weken"),
                standard_questions.standard_question_rating("factoren3", "Hoe veel bewegen jouw medewerkers dagelijks"),
            ]
        },
    ]
}