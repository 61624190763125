import React, { useState } from 'react';
import './pages.css';

import logo from '../images/vitaal-vechtdal.svg'


// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';


// login pages that gets exported
export function ForgotPassword({ setRenderEmployeeLogin, setRenderHRLogin, setForgotPassword, setResetPassword, setRequestInformation }) {

  // state variables definitions and the functions how to assign their values
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [formEnabled, setFormEnabled] = useState('');
  const [formResponse, setFormResponse] = useState("");
  const [error, setError] = useState("");



  // function that handles the login button and sets the token if login is correct
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    document.getElementById("forgot_password_input").reset()
    setLoading(true)
   const response = await fetch(`/send_forgot_password`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'username': username}),
    })
    if (response.status === 200) {
      setResetPassword(true)
    }
    else {
      setError('Oeps, dit e-mailadres is onbekend')
      setLoading(false)
    }
    
  }

  React.useEffect(() => {
    if (loading === true) {
      setFormEnabled("disabled")
      setFormResponse(<p>Je verzoek wordt verwerkt</p>)
    }
    else {
      setFormEnabled("")
    }
}, [loading]); // re-excecute hook when changes

  return (

    <body className='body_login'>
  
      {/* turquoise bar at top of page  */}
      <div className="top_bar_login"> </div> 
  
      {/* white bar for logo  */}
      <div className="top_bar_logo">
        <img id="theImage_main" src={logo} alt="Vitaal Vechtdal" />
      </div>

      {/* title of device  */}
      <div className="login-page">
        <h1 className="title-login"> Vitaliteit op de werkvloer </h1>
      </div>


    <div class="login-group">
    {/* white block of information about the device  */}
      <div className="wrap_login">
        <div className="info-login-background_forgot">
          <div>
            <div className="info_login_position">
              <h3 className="headings-login-description"> Hoe vitaal is jouw bedrijf? </h3><br></br>
              <p className="info-login"> Bedrijven die een vitale en gezonde werkomgeving creëren voor hun medewerkers ervaren niet 
    alleen minder verzuim, maar ook meer werkplezier, tevreden klanten en betere dienstverlening. Een vitale organisatie 
    begint met een dialoog tussen medewerker en werkgever. 
    <br></br> <br></br>
    Het dashboard ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van jouw organisatie, de vitaliteit van de 
    medewerkers en in mogelijke verbeterpunten. </p> 
      {/* <br></br> */}
                  <h3 className="headings-login-description"> Meer weten of hulp nodig? </h3>
              <p className="info-login"> 
                  {/* Neem contact met ons op */}
            <button id="button_request_information"  type='button' onClick={() => { setRequestInformation(true) }}>Neem contact met ons op</button>
          </p>
        </div>
      </div>
    </div> 
{/* blue block of login options  */}
<div className="login-background_hr">
{/* <div className="wrap_hr_login">  */}
  <div className="wrap_forgot_login_organisation"> 
      <h1 className="login-box-title-forgot"> Wachtwoord herstellen </h1>







      <form onSubmit={handleForgotPassword} className="forgot-box-details_organisation"  id="forgot_password_input">
        <label>
          <fieldset className="add_user_input"disabled={formEnabled}>
            <h3 className="email_forgot_password"tyle={{ 'textAlign': 'center' }}>Je e-mail adres</h3>
            <input className="input_box" placeholder="naam@voorbeeld.nl" autoFocus type="email" required={true} onChange={e => setUsername(e.target.value)} />
          </fieldset>
        </label>
        <div>
          <br />
          <button id="button_back_to_login_page_1" type='button' onClick={() => { setForgotPassword(false); setResetPassword(false); setRenderHRLogin(true) }}> ← Ga terug</button>
          <button className='button_login_organisation_forgot' type="submit"> Verstuur e-mail </button>
        </div>
      </form>
    </div>
</div>


      {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
      <div  className="marginBottom"  style={{ "marginBottom": "-9vw" }} >   </div>

      

        {(error !== "") ? (<div className='error'>
          <h3 className="login-error">{error}</h3>
          </div>) : ""}
</div>
</div> 
{/* </div>  */}
</body>
)
}