export const kantoor_werken = [
    {
        name: '40 uur',
        'eigen bedrijf': 70,
        regio: 60,
        "jouw branche": 10,
    },
    {
        name: '32 uur',
        'eigen bedrijf': 20,
        regio: 25,
        "jouw branche": 10,
    },
    {
        name: '24 uur',
        'eigen bedrijf': 10,
        regio: 10,
        "jouw branche": 10,
    },
    {
        name: '<16 uur',
        'eigen bedrijf': 0,
        regio: 5,
        "jouw branche": 10,
    },
    {
        name: 'Anders',
        'eigen bedrijf': 0,
        regio: 0,
        "jouw branche": 10,
    },
];

export const kantoor_willen = [
    {
        name: '40 uur',
        'eigen bedrijf': 60,
        regio: 60,
        "jouw branche": 40,
    },
    {
        name: '32 uur',
        'eigen bedrijf': 15,
        regio: 20,
        "jouw branche": 15,
    },
    {
        name: '24 uur',
        'eigen bedrijf': 5,
        regio: 5,
        "jouw branche": 10,
    },
    {
        name: '<16 uur',
        'eigen bedrijf': 0,
        regio: 10,
        "jouw branche": 5,
    },
    {
        name: 'Anders',
        'eigen bedrijf': 0,
        regio: 0,
        "jouw branche": 10,
    },
];


export const beleid = [
    {
        name: 'Ja',
        regio: 60,
        "jouw branche": 10,
    },
    {
        name: 'Nee',
        regio: 15,
        'eigen bedrijf': 100,
        "jouw branche": 10,
    },
    {
        name: 'Mee bezig',
        regio: 10,
        "jouw branche": 10,
    },
];


export const werkomstandigheden = [
    {
        name: 'Hoe bang zijn jouw medewerkers over werkzekerheid?', 
        'eigen bedrijf': 7,
        regio: 6,
        "jouw branche": 5,
    },
    {
        name: 'Hoe goed vinden jouw medewerkers de sociale steun op werk?',
        'eigen bedrijf': 5,
        regio: 6,
        "jouw branche": 4,
    },
    {
        name: 'Hoe scoren jouw medewerkers de cultuur binnen het bedrijf?',
        'eigen bedrijf': 3,
        regio: 4,
        "jouw branche": 1,
    },
    {
        name: 'Hoe vinden jouw medewerkers de werkdruk?',
        'eigen bedrijf': 3,
        regio: 5,
        "jouw branche": 5,
    },
    {
        name: 'Hoe hoog is de werkdruk voor jouw medewerkers?',
        'eigen bedrijf': 5,
        regio: 6,
        "jouw branche": 4,
    },
    {
        name: 'Hoe hoog scoren jouw medewerkers het bedrijf?',
        'eigen bedrijf': 3,
        regio: 4,
        "jouw branche": 1,
    },
    {
        name:'Hoe vaak maken jouw medewerkers regelmatig vervelende situaties mee op werk?', 
        'eigen bedrijf': 3,
        regio: 5,
        "jouw branche": 5,
    },
];


export const factoren = [
    {
        name: 'Hoe scoren jouw medewerkers hun financiële situatie?',
        'eigen bedrijf': 7,
        regio: 6,
        "jouw branche": 5,
    },
    {
        name: 'Hoe goed slapen jouw medewerkers de afgelopen twee weken?',
        'eigen bedrijf': 5,
        regio: 6,
        "jouw branche": 4,
    },
    {
        name: 'Hoe veel bewegen jouw medewerkers dagelijks?',
        'eigen bedrijf': 3,
        regio: 4,
        "jouw branche": 1,
    },
];