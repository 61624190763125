// imports always at the top
import * as standard_questions from './survey_standard_questions'
import * as matrix_questions from './survey_matrix_questions';
import * as rating_questions from './survey_rating_questions';

export const survey_json1 = {
    // "title": "Flexible working",
    // "pages": [
    //     {
    //         "name": "page1",
    "elements": [
        {
            "type": "panel",
            "title": "Verzuim",
            "elements": [
                standard_questions.standard_questions_verzuim("januari"),
                standard_questions.standard_questions_verzuim("februari"),
                standard_questions.standard_questions_verzuim("maart"),
            ]
        },
        {
            "type": "panel",
            "title": "Positieve gezondheid ",
            "elements": [
                standard_questions.standard_questions_gezondheid("dagelijks functioneren"),
                standard_questions.standard_questions_gezondheid("meedoen"),
                standard_questions.standard_questions_gezondheid("kwaliteit van leven"),
                standard_questions.standard_questions_gezondheid("zingeving"),
                standard_questions.standard_questions_gezondheid("mentaal welbevinden"),
                standard_questions.standard_questions_gezondheid("lichaamsfuncties"),
                standard_questions.standard_questions_gezondheid1,
                standard_questions.standard_questions_gezondheid2,
                standard_questions.standard_questions_gezondheid3,
                standard_questions.standard_questions_gezondheid4,
            ]
        },
        // {
        //     "type": "panel",
        //     "title": "Thuiswerken",
        //     "elements": [
        //         matrix_questions.questions_thuis,
        //         rating_questions.questions_working1,
        //         rating_questions.questions_working2,
        //         rating_questions.questions_working3,
        //         rating_questions.questions_working4,
        //     ]
        // },
        // {
        //     "type": "panel",
        //     "title": "Naar kantoor",
        //     "elements": [
        //         matrix_questions.questions_openInput,
        //         matrix_questions.questions_beleid
        //     ]
        // }
    ]
};