
// print all keys and values inside an object
export function printObject(object) {

    if (typeof (object) === "object") {
        for (const [key, value] of Object.entries(object)) {
            console.log(`${key}: ${value}`);
        }
    } else {
        console.log("Provided argument is not an object!")
    }
}