// Necessary Imports 
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";


// Create About page 
export function Landing_page(props) {
  const navigate = useNavigate();
  const survey_page_employee = () => {
    navigate("/vragenlijst")
  }
  const nav_info_page = () => {
    navigate("/informatie_aanvraag")
  }

  const [organizationFormRel, setOrganizationFormRel] = React.useState(null); //  organization - form relationship. Can be 'choose' if there is none yet
  const [employeeCodeText, setEmployeeCodeText] = React.useState(null); // The html for showing the employee code
  const location = useLocation(); // also rerender results when switching pages
  
  const idForm = props.globalFormID;
  const idOrganization = props.userID;
  const companyName = props.companyName;

  // initial fetch employee code (linked with idForm & idOrganization (userId)) 
  React.useEffect(() => {
    if (idOrganization != null) {
      updateOrganizationFormRel(idOrganization, idForm)
    }
  }, [idOrganization]); // execute hook when arguments in [] changes
  
  // remove results from dashboard when they might change in questionnaire page. Also reload when going back to dashboard
  React.useEffect(() => {
    if (location.pathname === "/landing_page") {
      updateOrganizationFormRel(idOrganization, idForm)
    }
    else {
      setOrganizationFormRel(null)
      setEmployeeCodeText(null)
    }
  }, [location]); // excecutes hook when arguments is [] changes



  const updateOrganizationFormRel = async(idOrganization, idForm) => {
    // fetch HRFills, but only if HR !== 'revoke', because then the result should first be remove from db and HR becomes 'loading'
    if (idOrganization !== null && idForm !== null) {
      let hrFillsQuery = `/hrfills?organizationId=${idOrganization}&globalFormId=${idForm}`
      await fetch(hrFillsQuery)
        .then((res) => res.json()) // this is the http response (required)
        // .then((json) => setEmployeeCode(json.result)); // this is the object retrieved (provided by the response in the server)
        .then((json) => setOrganizationFormRel(json.result)); // this is the object retrieved (provided by the response in the server)
    }
  }
  
  
  // fetch employee code (linked with idForm & idOrganization (userId)) 
  React.useEffect(() => {
    if (organizationFormRel !== null & organizationFormRel !== 'choose') {
      if (organizationFormRel['employeeCode'] !== null) {
        if ([9,14,70,91,97, 99, 100, 101, 102, 103].includes(idOrganization) || Boolean(organizationFormRel['closed']) !== true) {
          setEmployeeCodeText(
            <p>
              De code waarmee de medewerkers de vragenlijst in kunnen vullen is: &nbsp; {organizationFormRel['employeeCode']}<br></br>
              Stuur hierbij ook de volgende link mee: <a style ={{color: "white"}}> https://dashboard.vitaalvechtdal.nl/ </a>
            </p>
          )
        }
      }
    }
  }, [organizationFormRel]); // execute hook when arguments in [] changes
  
  
  return (

    <body className="landing_page" >
      
      <div className="about-title"> Welkom {companyName}! </div>


      <div className="about-container" > 
      
        {/* <div className="welcome-wrapper">
          Welcome!
        </div> */}

        <p className="landing_page-info"> 
        {/* Employee Welcome Message  */}
        De gezondheid en vitaliteit van medewerkers zijn van grote invloed op de performance van een bedrijf. Of medewerkers met energie aan het werk zijn, heeft met veel aspecten te maken. Met het werk zelf, maar ook met collega’s om je heen, het werkklimaat, contact met de leidinggevende en natuurlijk de arbeidsomstandigheden, zoals de plek waar je werkt. ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van de organisatie en de medewerkers. Op basis van de resultaten kun je verbeterpunten signaleren en hier gericht aan werken.

        <br></br>


        {/* HR Welcome Message  */}
        {/* De gezondheid en vitaliteit van medewerkers zijn van grote invloed op de performance van een bedrijf. Of medewerkers met energie aan het werk zijn, heeft met veel aspecten te maken. Met het werk zelf, maar ook met collega’s om je heen, het werkklimaat, contact met de leidinggevende en natuurlijk de arbeidsomstandigheden, zoals de plek waar je werkt. ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van de organisatie en de medewerkers. Op basis van de resultaten kun je verbeterpunten signaleren en hier gericht aan werken. */}

        </p>
        <a className="button-landing_page" onClick={survey_page_employee}> Naar de vragenlijst  </a>
 
 
        <div className="employee_code_pgh">
          {employeeCodeText}
        </div>

      </div>

      <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank" > Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

        

    </div>
      </body>
  )

};

