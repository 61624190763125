// not used anymore!

import './pages.css'

import { useNavigate, Link } from "react-router-dom";

// the function which will be rendered
export function Main() {

  let navigate = useNavigate();
 

  const routeChange = (path) => {
    navigate(path);
  }

  const routeChange1 = () => {
    console.log('tapped');
    let path = '/monitor'; 
    navigate(path);
  }

  return (
    <div>
      <h1 className="pointer" onClick={() => routeChange('/vragenlijst')}>Vragenlijst</h1>
      <h1 className="pointer" onClick={routeChange1}>Monitor</h1>
    </div>
  );
}


// export function Main(props) {

//   return (
//       <div>
//           <h1> Hello </h1>
//           <p>{!props.data ? "Loading..." : props.data}</p>
//       </div>
//   );
// }