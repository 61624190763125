export const able = [
    {
        name: 'Ja',
        regio: 60,
        "jouw branche": 10,
    },
    {
        name: 'Nee',
        regio: 15,
        'eigen bedrijf': 100,
        "jouw branche": 10,
    },
    {
        name: 'Een deel',
        regio: 10,
        "jouw branche": 10,
    },
];

export const rating = [
    {
        name: 'Hoe hebben je werknemers het thuiswerken ervaren?',
        'eigen bedrijf': 7,
        regio: 6,
        "jouw branche": 1,
    },
    {
        name: 'Welke invloed heeft het gehad om de fysieke gezondheid van werknemers?',
        'eigen bedrijf': 5,
        regio: 6,
        "jouw branche": 4,
    },
    {
        name: 'Welke invloed heeft het gehad om de mentale gezondheid van werknemers?',
        'eigen bedrijf': 3,
        regio: 4,
        "jouw branche": 1,
    },
    {
        name: 'Hoe scoren jouw werknemers de hulp die jouw bedrijf heeft gegeven voor het thuiswerken?',
        'eigen bedrijf': 3,
        regio: 5,
        "jouw branche": 5,
    },
];


export const home = [
    {
        name: 'Alle dagen',
        'eigen bedrijf': 70,
        regio: 60,
        "jouw branche": 10,
    },
    {
        name: '4 dagen',
        'eigen bedrijf': 20,
        regio: 25,
        "jouw branche": 10,
    },
    {
        name: '3 dagen',
        'eigen bedrijf': 10,
        regio: 10,
        "jouw branche": 10,
    },
    {
        name: '<2 dagen',
        'eigen bedrijf': 0,
        regio: 5,
        "jouw branche": 10,
    },
    {
        name: 'Anders',
        'eigen bedrijf': 0,
        regio: 0,
        "jouw branche": 10,
    },
];

export const beleid = [
    {
        name: 'Volledig op werk',
        regio: 60,
        "jouw branche": 10,
    },
    {
        name: '3 dagen werk, 2 dagen thuis',
        regio: 15,
        'eigen bedrijf': 100,
        "jouw branche": 10,
    },
    {
        name: 'Volledig flexibel werken',
        regio: 10,
        "jouw branche": 10,
    },
    {
        name: 'Geen beleid',
        regio: 5,
        "jouw branche": 0,
    },
    {
        name: 'Anders',
        regio: 0,
        "jouw branche": 0,
    },
];
