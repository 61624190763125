import React, { useState } from 'react';
import './pages.css';

import { useNavigate } from "react-router-dom";

// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';
// import routes from '../../../back-end/config/routes';


// login pages that gets exported
export function Add_user(props) {

    // state variables definitions and the functions how to assign their values
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState("");
    const [sent, setSent] = useState(false);
    const [formEnabled, setFormEnabled] = useState('');
    const [formResponse, setFormResponse] = useState("");
    const [error, setError] = useState("");

    
    React.useEffect(() => {
      if (sent) {
        setFormEnabled("")
        setFormResponse("Gebruiker succesvol toegevoegd")
      }
    }, [sent]); // re-excecute hook when changes
  


  // function that handels the login button and sets the token if login is correct
  const handleSubmit = async (e) => {
    //   Make sure no fields are left empty
    e.preventDefault();
    setError("")
    setFormResponse("")
    document.getElementById("addUserForm").reset()
    const response = await fetch(`/create_new_user`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'email': email, 'companyname': companyName}),
    })
    //   status =200 means successful
    if (response.status === 200) {
      setSent(true)
      setFormResponse("Gebruiker succesvol toegevoegd")
    }
    // Else, the current password is wrong, or something went wrong
    else {
        setError("E-mailadres is al in gebruik/ongeldig")
        setFormResponse('')
    }
  }
    

  return (
    <body className="wrapper_add_user">
    <div className="login-wrapper">


    <div className="add_user_background">  
      <div className="add_user_title">Voeg een nieuwe gebruiker toe </div>
      <p className="add_user_pgh"> Hier kan je nieuwe gebruikers aanmaken. Vul het e-mailadres en de bedrijfsnaam in en er wordt een e-mail naar de nieuwe gebruiker gestuurd met de inloggegevens.</p>
        <form className="password-block" onSubmit={handleSubmit} id="addUserForm">
          <label>
            <h3 className="password-title" style={{ 'textAlign': 'center' }}>E-mail adres</h3>
            <input className="center-block" autoFocus required={true} type="email" placeholder="E-mail" onChange={e => setEmail(e.target.value)} />
          </label>
          <label>
            <h3 className="password-title" style={{ 'textAlign': 'center' }}>Bedrijfsnaam</h3>
            <input className="center-block" required={true} type="text" placeholder="Bedrijfsnaam" onChange={e => setCompanyName(e.target.value)} />
          </label>
        <div>
          <br />
          <button className='button_add_user'  type="submit">Voeg gebruiker toe</button>
        </div>
        </form>
      </div>


      {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
      <div  className="marginBottom" style={{ "marginBottom": "-9vw" }} >   </div>

      {(formResponse !== "") ? (<div className='formResponse'>
        <h3 className="addUserResponse"> {formResponse} </h3>
      </div>) : ""}

      {(error !== "") ? (<div className='error'>
        <h3 className="error-password_new_user"> {error} </h3>
      </div>) : ""}

    </div>

    <div className="bottom_bar_fixed">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target= "blank"> Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target= "blank"> Privacy </a> 
        <a className= "footer_link_contact" href="/informatie_aanvraag" target= "_blank">Contact</a>

    </div>
    
    </body> 
  )
}
