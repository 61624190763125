import React, { useState } from 'react';
import './pages.css';

import logo from '../images/vitaal-vechtdal.svg'


// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';


// login pages that gets exported
export function ResetPassword({ setRenderEmployeeLogin, setRenderHRLogin, setForgotPassword, setResetPassword, setRequestInformation }) {

  // state variables definitions and the functions how to assign their values
  const [resetPasswordCode, setResetPasswordCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [formResponse, setFormResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [formEnabled, setFormEnabled] = useState('');
  const [error, setError] = useState("");

  React.useEffect(() => {
    if (loading) {
      setFormEnabled("disabled")
      setFormResponse(<p>Je verzoek wordt verwerkt</p>)
    }
}, [loading]); // re-excecute hook when changes


  // function that handels the login button and sets the token if login is correct
  const handleResetPassword = async (e) => {
    e.preventDefault();

    const pwdRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/;

    if (newPassword !== newPasswordConfirmation | resetPasswordCode === 'null') {
      setError('Wachtwoorden komen niet overeen')
      return
    }
    // check if password is strong
    else if (pwdRegex.exec(newPassword)=== null){
      setError(`Gebruik een wachtwoord van 8 tot 15 karakters, waarvan 1 hoofdletter, 1 kleine letter en een speciaal teken`)
      return
    }
    else {
      setLoading(true)
      const response = await fetch(`/reset_password`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'code': resetPasswordCode, 'newpassword': newPassword}),
      })
      if (response.status === 200) {
        setFormResponse('Je wachtwoord is gewijzigd')
        setForgotPassword(false)
        setResetPassword(false)
        setRenderHRLogin(true)
      }
      else {
        setError("Oeps, er is iets misgegaan")
      }
      
    
    }
    

    


    // setToken(token);
  }


  return (


<body className='body_login'>

  {/* turquoise bar at top of page  */}
  <div className="top_bar_login"> </div> 

  {/* white bar for logo  */}
  <div className="top_bar_logo">
    <img id="theImage_main" src={logo} alt="Vitaal Vechtdal" />
  </div>

  {/* title of device  */}
  <div className="login-page">
    <h1 className="title-login"> Vitaliteit op de werkvloer </h1>
  </div> 



  <div class="login-group">

  {/* white block of information about the device  */}
  <div className="wrap_login">
    <div className="info-login-background_forgot">
      <div>
        <div className="info_login_position">
          <h3 className="headings-login-description"> Hoe vitaal is jouw bedrijf? </h3><br></br>
            <p className="info-login"> Bedrijven die een vitale en gezonde werkomgeving creëren voor hun medewerkers ervaren niet 
                alleen minder verzuim, maar ook meer werkplezier, tevreden klanten en betere dienstverlening. Een vitale organisatie 
                begint met een dialoog tussen medewerker en werkgever. 
                <br></br> <br></br>
                Het dashboard ‘Vitaliteit op de werkvloer’ geeft inzicht in de vitaliteit van jouw organisatie, de vitaliteit van de 
                 medewerkers en in mogelijke verbeterpunten. </p> 
                {/* <br></br> */}
                <h3 className="headings-login-description"> Meer weten of hulp nodig? </h3>
                <p className="info-login"> 
                  {/* Neem contact met ons op */}
                  <button id="button_request_information"  type='button' onClick={() => { setRequestInformation(true) }}> Neem contact met ons op </button>
                </p>
        </div>
      </div>
    </div> 



{/* blue block of login options  */}
<div className="login-background_hr">
<div className="wrap_hr_login"> 


  <div className="wrap_forgot_login_1"> 
      <h1 className="login-box-title-forgot_1"> Wachtwoord herstellen </h1>

        <form onSubmit={handleResetPassword} className="login-box-details_reset">

          <label>
          <fieldset disabled={formEnabled}>
            <h3 className="reset_password_boxes" style={{ 'textAlign': 'center' }}>Vul hier de code in die naar je email is verstuurd</h3>
            <input className="input_box_1" placeholder="code" autoFocus type="text" required={true} onChange={e => setResetPasswordCode(e.target.value)} />
            <h3 className="reset_password_boxes" style={{ 'textAlign': 'center' }}>Nieuw wachtwoord</h3>
            <input className="input_box_1" placeholder="Nieuw wachtwoord" type="password" required={true} onChange={e => setNewPassword(e.target.value)} />
            <h3 className="reset_password_boxes" style={{ 'textAlign': 'center' }}>Wachtwoord bevestigen</h3>
            <input className="input_box_1" placeholder="Bevestiging" type="password" required={true} onChange={e => setNewPasswordConfirmation(e.target.value)} />
            </fieldset>
          </label>
            <div>
              <br />
              {/* <button id="button_organisation_login_forgot" type='button' onClick={() => { setRenderEmployeeLogin(false); setRenderHRLogin(true) }}>HR-login pagina</button> */}
              <button className='button_login_forgot' type="submit"> Wijzig wachtwoord </button>
              <button id="button_back_to_previous_page_reset" type='button' onClick={() => { setForgotPassword(true); setResetPassword(false) }}>  &larr;  Ga terug </button>
           </div>
        </form>
  </div>
</div>


    {/* error message */}
    {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
    <div  className="marginBottom"  style={{ "marginBottom": "-9vw" }} >   </div>

    {(formResponse !== "") ? (<div className='formResponse'>
        <h3 className="formResponse"> {formResponse} </h3>
      </div>) : ""}
    <button id="button_back_to_login_page" type='button' onClick={() => { setForgotPassword(false); setResetPassword(false); setRenderHRLogin(true) }}> ← Ga terug </button>

    {(error !== "") ? (<div className='error'>
    <h3 className="login-error_forgot">{error}</h3>
    </div>) : ""}

</div>
</div> 
</div> 
</body>

)
}