import './pages.css'

// import data
import * as thuiswerken from '../dummy_data/flexibleWorking' // static data
import * as verzuim from '../dummy_data/verzuim' // static data

// import graph modules
import { SimpleLineChart, SimpleBarChart, HorizontalBarChart, SingleBarChart } from '../components/graphs'
import * as standard_questions from '../survey_input/survey_questions'
import React from 'react';
import { useLocation } from "react-router-dom";

// define different dataKeys, changable by onClick
const colors = ["#41337A", "#3CB9D5", "#3897AC"]

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

// S T A R T   O F   T H E   F U N C T I O N 
// -----------------------------------------

// the function which will be rendered
export function Dashboard_page2(props) {


  // state variables definitions and the functions how to assign their values
  const [dataKeys, setDataKeys] = React.useState(["eigen bedrijf", "benchmark"])
  const [lineChart, setLineChart] = React.useState(<SimpleLineChart xAxis="name" xYaxis="% werknemers" data={verzuim.verzuim} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />);
  const [results, setResults] = React.useState(null);
  const [dashboard, setDashboard] = React.useState(null);
  const [benchMarkData, setBenchMarkData] = React.useState(null);
  const [filter, setFilter] = React.useState(null);
  const [business_data, setBusiness] = React.useState(null);
  const location = useLocation(); // also rerender results when switching pages
  const [previousLocation, setPreviousLocation] = React.useState(null)
  const [formSelect, setFormSelect] = React.useState(null)
  const [availableForms, setAvailableForms] = React.useState(null)

  // function that constructs the filter select box
  function FilterSelect(props) {
    let filter = props.filter
    return (
      <div className="select">
        {/* region selector */}
        <label htmlFor="filter">Kies een filter </label>
        <select key="filter" name="filter" id="filter" defaultValue={filter} onChange={handleFilterSelect}>
          <option key="none" value="none">Geen</option>
          <option key="region" value="jouw regio">Jouw regio</option>
          <option key="industry" value="jouw branche">Jouw branche</option>
        </select>
      </div>
    )
  }


  // importing state variables from the passed arguments
  const userID = props.userID;
  let originalGlobalFormID;
  if (userID == 70 || userID == 91) {
    originalGlobalFormID = 2;
  }
  else {
    originalGlobalFormID = props.globalFormID;
  }
  // use globalFormID as an copy of the original so that its global state cannot be altered when selecting a form
  const [globalFormID, setGlobalFormID] = React.useState(originalGlobalFormID)



  // initial data fetch
  React.useEffect(() => {
    if (userID != null) {
      // fetch business data from data base (through api)
      fetch(`/business_data?organizationId=${userID}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => setBusiness(json.result)); // this is the object retrieved (provided by the response in the server)
      // fetch benchMarkData
      if (userID === 70 || userID === 91) {
        setGlobalFormID(2);
        updateBenchmark(2, filter, business_data)
        updateResults(2, userID)
      }
      else {
        updateBenchmark(globalFormID, filter, business_data)
        updateResults(globalFormID, userID)
      }
    }
  }, [userID]); // excecutes hook when arguments is [] changes

  // initialization globalFormId can be changed with formSelect. OriginalGlobalFormId comes from app.js
  React.useEffect(() => {
    setGlobalFormID(originalGlobalFormID)
    updateAvailableForms(originalGlobalFormID, userID)
  }, [originalGlobalFormID]); // excecutes hook when arguments is [] changes
  
  // remove results from dashboard when they might change in questionnaire page. Also reload when going back to dashboard
  React.useEffect(() => {
    if (location.pathname !== "/monitor") {
      setGlobalFormID(originalGlobalFormID)
      setResults(null)
      setBenchMarkData(null)
      setAvailableForms(null)
      setFormSelect(null)
    }
    else if (previousLocation !== "/monitor" && location.pathname === "/monitor") {
      updateResults(originalGlobalFormID, userID)
      updateBenchmark(originalGlobalFormID, filter, business_data)
      updateAvailableForms(originalGlobalFormID, userID)
    }
    setPreviousLocation(location.pathname)
  }, [location]); // excecutes hook when arguments is [] changes
  
  
  
  React.useEffect(() => {
    if (filter != null) {
      setDataKeys(['eigen bedrijf', filter])
    }
    else {
      setDataKeys(['eigen bedrijf', 'benchmark'])
    }
  }, [filter]); // excecutes hook when arguments is [] changes

  // fetch benchmark from data base (through api) 
  const updateBenchmark = async (idForm, filter, business_data) => {
    if (filter === 'jouw regio' & business_data !== null) {
      let zipLower = parseInt(business_data[0].zipCode.toString().substring(0, 2) + '00')
      let zipUpper = parseInt(business_data[0].zipCode.toString().substring(0, 2) + '00') + 99
      await fetch(`/benchmarkapi?globalFormId=${idForm}&zipLower=${zipLower}&zipUpper=${zipUpper}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
    }
    else if (filter === 'jouw branche' & business_data !== null) {
      let industry = business_data[0].industry
      await fetch(`/benchmarkapi?globalFormId=${idForm}&industry=${industry}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)
    }
    else if (globalFormID != null) {
      await fetch(`/benchmarkapi?globalFormId=${idForm}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setBenchMarkData(json.benchmark);
        }); // this is the object retrieved (provided by the response in the server)

    }
  }

  // fetch survey results from data base (through api)
  const updateResults = async (idForm, idUser) => {
    if (idUser != null && idForm != null) {
      await fetch(`/results?organizationId=${idUser}&globalFormId=${idForm}`)
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => {
          setResults(json.result)
        }); // this is the object retrieved (provided by the response in the server)
    }
  }

  // fetch possible forms from database (through api)
  const updateAvailableForms = async (idForm, userID) => {
    let vvAdmin = ""
    let showcaseAccount = ""
    if ([9, 14, 97, 70, 91].includes(userID)) {
      vvAdmin = "&vvAdmin=true"
    }
    if ([99, 100, 101, 102, 103].includes(userID)) {
      showcaseAccount = "&showcaseAccount=true"
    }
    if (idForm !== null && idForm.length !== 0 && userID) {
      await fetch(`/availableForms?globalFormId=${idForm}${vvAdmin}&idUser=${userID}${showcaseAccount}`)
        .then((res) => res.json()) // this is the http response (required)
        .then((json) => {
          updateAvailableFormsSelect(json.availableForms, userID)
          // setAvailableForms(json.availableForms)

        }); // this is the object retrieved (provided by the response in the server)
    }
  }

  const updateAvailableFormsSelect = async (availableForms, idUser) => {
    if (availableForms !== null && availableForms.length > 1) {
      let options = []
      let formIds = []
      for (let i in availableForms) {
        let form = availableForms[i]
        options.push(<option key={form.startDate} value={form.idForm}>{`${form.name} ${new Date(form.startDate).getFullYear()}`}</option>)
        formIds.push(form.idForm)
      }
      let defaultFormId;
      if (formIds.indexOf(originalGlobalFormID)) {
        defaultFormId = originalGlobalFormID
      }
      else {
        defaultFormId = Math.max(...formIds);
      }
      setFormSelect(
        <div className="select">
          {/* form selector */}
          <label htmlFor="region">Selecteer een vragenlijst </label>
          <select key="form" name="form" id="form" defaultValue={defaultFormId} onChange={(event) => handleFormSelect(event, idUser)}>
            {options}
          </select>
        </div>
      )
    }
  }

  // used for creating an html select for selecting a form from the available forms
  React.useEffect(() => {
    if (userID === 70 || userID === 91) {
      updateAvailableForms(2, userID)
    }
    else {
      updateAvailableForms(globalFormID, userID)
    }
  }, [userID]); // executes hook when arguments is [] changes

  // used for creating an html select for selecting a form from the available forms
  React.useEffect(() => {
    updateAvailableFormsSelect(availableForms, userID)
  }, [availableForms, userID]); // executes hook when arguments is [] changes

  // setGlobalFormID when form is selected with the form select box
  function handleFormSelect(e, idUser) {
    setGlobalFormID(e.target.value)
    setFilter(null)

    updateResults(e.target.value, idUser)
    updateBenchmark(e.target.value, filter, business_data)
  }

  function handleFilterSelect(e) {      // todo
    let selection = e.target.value
    if (selection === 'none') {
      setFilter(null)
    }
    else {
      setFilter(selection)
    }
  }

  // function that creates the dashboard with graphs based on the results in the DB
  React.useEffect(() => {
    // check if results is not full of empty lists. This happens when a survey has not been filled in yet
    // if noResults remains true the survey hasn't been filled in yet
    let noResults = true
    if (results !== null) {
      for (let result in results) {
        // If there is one resultlist in results that has content, set noResults = false
        if (Object.keys(results[result]).length) {
          noResults = false
        }
      }
    }
    let noBenchmark = false
    if (benchMarkData) {
      if (Object.keys(benchMarkData) === 0) {
        noBenchmark = true
      }
      // check if results and benchmark have the same questions (--> thus the same form)
      else if (results !== null) {
        for (let question in results) {
          if (!Object.keys(benchMarkData).indexOf(results[question]["idQuestion"])) {
            noBenchmark = true
          }
        }
      }
    }
    // if noResults, set message for fill in survey
    if (noResults) {
      setDashboard(
        <div className="tot">
          {<h1> Je hebt nog geen resultaten beschikbaar. Vul eerst de vragenlijst in. </h1>}
        </div>
      )
    }

    else if (benchMarkData === null || noBenchmark === true || results[0]['published'] === 0 && ![9, 14, 70, 91, 97, 99, 100, 101, 102, 103].includes(userID)) {
      setDashboard(
        <div className="tot">
          {<h1> Er is nog geen data voor de monitor beschikbaar </h1>}
        </div>
      )
    }

    // if there are results (present AND loaded) and there is benchmarkdata loaded, create json_dashboard
    // if (!noResults & benchMarkData !== null & noBenchmark === false) {
    else {
      // TO DO
      // check results and benchmarks! 
      // size and form + question IDs!
      // console.log(results)

      // C R E A T E   J S O N   W I T H   G R O U P E D   T H E M E S   A N D   Q U E S T I O N   T Y P E S
      // ---------------------------------------------------------------------------------------------------

      let json_dashboard = {};
      let formTitle = results[0].name; // only title from first question in the form is needed
      let theDashboardPage = [];  // array that contains all dashboard components 
      let currentTopic = null
      let currentType = ''
      let currentQuestionFormSection = null
      let currentQuestionId = -1
      let currentQuestionTitle = ''
      let graph = null
      let margin = 0
      let key = null
      let dataQuest = []
      let initiatives = []
      let formSectionCounter = 1
      let questionsInGraphCounter = 0   // counts how many questions are in a single graph. Graphs are limited to three questions.
      let graphCounter = 0  // Counts the number of graphs. Helps with ensuring graphs get unique ids (Because graphs are limited to three questions)

      let benchmarkName = 'benchmark'
      if (filter !== null) {
        benchmarkName = filter
      }

      // loop over subforms in results
      for (const iQuestion in results) {
        let question = results[iQuestion];  // Dict containing all information of the question, including results of the current user  

        const questionType = question.questionType; // question type relates to type of graph
        const questionId = question.idQuestion; // the id of the question
        const questionTopic = question.topic;   // the topic of the question
        const questionTitle = question.questionHR;  // the question for HR people
        const resultFormat = question.resultFormat; // not used
        const results_json = question.results_json; // contains the filled in data
        const answers_json = question.answers_json; // only non-null for specific question types
        const QuestionFormSection = question.formSection  // the formSection of the current question. e.g. 'Standaard vragen' or 'Thema vragen'

        // add initiative from question to initiatives
        let currentQuestion;
        if (results[iQuestion-1]) {
          currentQuestion = results[iQuestion-1]
        }
        if (currentQuestion && currentQuestion.idInitiative) {
          initiatives.push({ 'name': currentQuestion.initiativeName, 'url': currentQuestion.initiativeUrl, 'description': currentQuestion.initiativeDescription, 'buttonText': currentQuestion.buttonText })
        }

        // ============================= CREATE GRAPHS WITH GATHERED DATA (STEP 2) =============================

        if (questionType !== currentType || questionTopic !== currentTopic || (currentTopic === "Gezond en vitaal werken" && questionsInGraphCounter >= 3) || questionsInGraphCounter >= 4) {
          // add graph for each questionType once the questionType changes and dataQuest has been filled, 
          if (currentType === 'likert' | currentType === 'percentage') {
            graph = <HorizontalBarChart xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
          }
        }
        if (questionId !== currentQuestionId) {
          if (currentType === 'multiple_result') {
            currentQuestionTitle = Object.keys(JSON.parse(currentQuestionTitle))[0]
            // line graph for "Verzuim" topic
            if (currentTopic === "Verzuim" | currentTopic === "verzuim" | currentTopic === "Gezond en vitaal werken") {
              graph = <SimpleLineChart xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
            }
            else {
              margin = -3.5;
              graph = <SimpleBarChart xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
            }
          }
          else if (currentType === 'option') {
            graph = <SimpleBarChart xAxis="name" xYaxis="% bedrijven" data={dataQuest} dataKeys={dataKeys} colors={colors} />
            // graph = <SingleBarChart xAxis="name" xYaxis="% bedrijven" data={dataQuest} dataKeys={dataKeys} colors={colors} />
          }
        }
        if (graph) {
          // add graph to dashboard
          key = `${currentTopic}_${currentType}_${currentQuestionTitle}_${graphCounter}`;
          // theDashboardPage.push(<FilterSelect filter={filter}/>) // filters for the dashboard
          theDashboardPage.push(
            <div className='whiteblock' key={key}>
              <h3 className="dashboard-h3">{currentQuestionTitle}</h3>
              <div className="marginBottom" style={{ "marginBottom": `${margin}vw` }} >   </div>
              {graph}
            </div>);

          for (let i in initiatives) {
            const initiative = initiatives[i]
            let buttonText = initiative.buttonText
            if (!buttonText) {
              buttonText = "Klik voor meer info"
            }
            theDashboardPage.push(
              // <div style={{ "marginBottom": "-1vw" }} ></div>
              <div className="outer">
                <div className="banner_link" data-html2canvas-ignore="true">
                  <div className="p_link">
                    <div>
                      <strong className="link_title">{initiative.name}</strong> </div> <div className="div_link" dangerouslySetInnerHTML={{ __html: initiative.description }}>
                    </div>
                    <button className='link' onClick={() => openInNewTab(initiative.url)}>{buttonText}</button>
                  </div>
                </div>
              </div>
            )
          }

          // add spacing between questions
          key = `${currentTopic}_${currentType}_${currentQuestionTitle}_${graphCounter}_spacing`;
          theDashboardPage.push(
            <div className="marginBottom" key={key} style={{ "marginBottom": "2vw" }} ></div>
          )
          graph = null
          margin = 0
          // reset list of questions when topic has changed and graph has been created
          dataQuest = []
          initiatives = []  // reset initiatives for next graph
          questionsInGraphCounter = 0 // reset questionsInGraphCounter
          graphCounter += 1 //increment graphCounter
        }

        if (questionTopic !== currentTopic) {
          // add spacing between themes
          key = `${QuestionFormSection}_${questionTopic}_spacing`;
          theDashboardPage.push(
            <div className="marginBottom" key={key} style={{ "marginBottom": "5vw" }} ></div>
          )
        }

        // ================================= CREATE FORMSECTION TITLE AND TOPIC TITLE =======================
        if (QuestionFormSection !== currentQuestionFormSection) {
          // add topic title to dashboard
          if (QuestionFormSection) {
            theDashboardPage.push(<h2 className="dashboard_main_title" key={QuestionFormSection}>{QuestionFormSection + ' - Deel ' + formSectionCounter}</h2>);
          }
          else {
            theDashboardPage.push(<h2 key={QuestionFormSection}>{'Thema vragen - Deel ' + formSectionCounter}</h2>);
          }
          formSectionCounter += 1
        }

        // if (!currentTopic) {
        //   currentTopic = questionTopic
        //   // add topic to dashboard on first run through loop
        //   theDashboardPage.push(<h1 key={`${QuestionFormSection}_${currentTopic}`}>{currentTopic}</h1>)
        // }
        // // when the topic changes and/or questionType, render previous question(s) graph 
        // if (questionTopic !== currentTopic) {
        //   // add topic to dashboard
        //   theDashboardPage.push(<h1 key={`${QuestionFormSection}_${questionTopic}`}>{questionTopic}</h1>)
        // }

        // =========== UPDATE LAST-QUESTION TRACKERS ==============
        // these are used for maintaining data of the last question
        // this is required for creating the graph, which will only be done once we encounter a new question,
        // indicating that the data is gathered and the graph is ready to be made
        // then, update the 'current' trackers
        currentTopic = questionTopic
        currentType = questionType
        currentQuestionId = questionId
        currentQuestionTitle = questionTitle
        currentQuestionFormSection = QuestionFormSection
        questionsInGraphCounter += 1  // increment questionsInGraphCounter

        // ============================= GATHERING DATA FOR GRAPHS (STEP 1) =============================

        // within the following conditions the dataQuest is filled. Questions are already ordered.
        // Once it is filled, the condition above will be triggered and the graphs will be created 
        // with the gathered data.
        // for Likert questions

        // extra check added because the benchmark is not directly updated when changing forms
        if (questionId in benchMarkData) {

          if (questionType === 'likert' | questionType === 'percentage') {
            // fill the list of dicts with with question results. One questionType (multiple questions) 
            // goes into this list before it gets cleared


            dataQuest.push({
              'name': questionTitle,
              'eigen bedrijf': results_json,
              [benchmarkName]: Math.round(benchMarkData[questionId] * 100) / 100,
              'benchmarkName': benchmarkName
            })
            // these graphs have to be displayed without a title on top
            currentQuestionTitle = null
          }
          else if (questionType === 'multiple_result') {
            let dataCol = results_json
            let titles = Object.values(JSON.parse(questionTitle))[0]
            // fill the list of dicts with with question results. Only one question (multiple columns)
            // goes into this list before it gets cleared

            const keys = Object.keys(dataCol)
            for (let i in keys) {
              let key = parseInt(keys[i])
              dataQuest.push({
                'name': standard_questions.monthTitle(titles[key], question.startDate),
                'eigen bedrijf': dataCol[key],
                [benchmarkName]: Math.round(benchMarkData[questionId][key] * 100) / 100,
                'benchmarkName': benchmarkName
              })
            }
          }
          else if (questionType === 'option') {
            // fill the list of dicts with with question results. Only one question (multiple columns) 
            // goes into this list before it gets cleared
            let dataCol = results_json
            let answers = answers_json.slice(2, -2).split(',')
            answers = answers.map(word => { return word.replace('\"', "").trim() })
            for (let i in answers) {
              let answer = answers[i]
              let chosenPct = dataCol[answer]

              dataQuest.push({
                'name': answer,
                'eigen bedrijf': chosenPct,
                [benchmarkName]: Math.round(benchMarkData[questionId][answer] * 100) / 100,
                'benchmarkName': benchmarkName
              })
            }
          }

        } // end of extra check

      } // end of the loop

      // ================== CREATE FINAL GRAPH AFTER LOOP HAS ENDED ========================

      // create the last graph because it is not triggered when the loop ends
      if (currentType === 'multiple_result') {
        // line graph for "Verzuim" topic
        if (currentTopic === "Verzuim" | currentTopic === "verzuim") {
          graph = <SimpleLineChart xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} lineWidth="0.1cm" />
        }
        else {
          margin = -3.5;
          graph = <SimpleBarChart xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
        }
      }
      else if (currentType === 'likert' | currentType === 'percentage') {
        graph = <HorizontalBarChart xAxis="name" xYaxis="% werknemers" data={dataQuest} dataKeys={dataKeys} colors={colors} />
      }
      else if (currentType === 'option') {
        // graph = <SingleBarChart xAxis="name" xYaxis="% bedrijven" data={dataQuest} dataKeys={dataKeys} colors={colors} />
        graph = <SimpleBarChart xAxis="name" xYaxis="% bedrijven" data={dataQuest} dataKeys={dataKeys} colors={colors} />
      }
      if (graph) {
        // add graph to dashboard
        key = `${currentTopic}_${currentType}_${currentQuestionTitle}`;
        // theDashboardPage.push(<FilterSelect filter={filter}/>) // dashboard filter select
        theDashboardPage.push(
          <div className='whiteblock' key={key}>
            <h3 className="dashboard-h3">{currentQuestionTitle}</h3>
            <div className="marginBottom" style={{ "marginBottom": `${margin}vw` }} >   </div>
            {graph}
          </div>
          );

        // add initiative from question to initiatives
        let currentQuestion;
        if (results[results.length - 1]) {
          currentQuestion = results[results.length - 1]
        }
        if (currentQuestion && currentQuestion.idInitiative) {
          initiatives.push({ 'name': currentQuestion.initiativeName, 'url': currentQuestion.initiativeUrl, 'description': currentQuestion.initiativeDescription, 'buttonText': currentQuestion.buttonText })
        }
        for (let i in initiatives) {
          const initiative = initiatives[i]
          let buttonText = initiative.buttonText
            if (!buttonText) {
              buttonText = "Klik voor meer info"
            }
            theDashboardPage.push(
              // <div style={{ "marginBottom": "-1vw" }} ></div>
              <div className="outer">
                <div className="banner_link" data-html2canvas-ignore="true">
                  <div className="p_link">
                    <div>
                      <strong className="link_title">{initiative.name}</strong> </div> <div className="div_link" dangerouslySetInnerHTML={{ __html: initiative.description }}>
                    </div>
                    <button className='link' onClick={() => openInNewTab(initiative.url)}>{buttonText}</button>
                  </div>
                </div>
              </div>
            )
        }

        // add spacing between questions
        key = `${currentTopic}_${currentType}_${currentQuestionTitle}_spacing`;
        theDashboardPage.push(
          <div className="marginBottom" key={key} style={{ "marginBottom": "2vw" }} ></div>
        )

        graph = null
        margin = 0
        // reset list of questions when topic has changed and graph has been created
        dataQuest = []
        initiatives = []
      }

      // set the dashboard state
      setDashboard(theDashboardPage);
    }
  }, [benchMarkData, results, filter]); // end of hook to create dashboard

  // if there is no dashboard created yet, show loading message
  var dashboardPage = dashboard;
  if (!dashboard) {
    dashboardPage = <h1>Dashboard wordt geladen</h1>
  }
  if (benchMarkData) {
    if (Object.keys(benchMarkData).length === 0) {
      dashboardPage = <h1>Er is nog geen dashboard beschikbaar</h1>
    }
  }

  // return the dashboard in a div
  return (
    <div className="tot">

      {formSelect}

      {dashboardPage}

      <div className="bottom_bar_fixed">

        <p className="title_footer"> Vitaliteit op de werkvloer 2023 </p>
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/" target="blank"> Vitaal Vechtdal</a>
        <a className="footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy" target="blank"> Privacy </a>
        <a className="footer_link_contact" href="https://www.vitaalvechtdal.nl/contact" target="blank"> Contact </a>

      </div>

    </div>
  )
}