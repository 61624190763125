import './App.css';

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Routes, Route, Link, useLocation } from "react-router-dom";

// import pages
import { Login } from './pages/login';
import { EmployeeLogin } from './pages/employee_login';
import { ForgotPassword } from './pages/forgot_password';
import { ResetPassword } from './pages/reset_password';
import { RequestInformation } from './pages/request_information';
import { HRLogin } from './pages/hr_login';
import { Change_pwd } from './pages/change_pwd';
import { Change_username } from './pages/change_username';
import { Dashboard_page2 } from './pages/dashboard_page2';
import { Survey_page2, Bedrijfsgegevens_page2 } from './pages/survey_page2';
import { Employee_survey_page } from './pages/employee_survey_page';
import { Hr_view_employee_survey_page } from './pages/hr_view_employee_survey_page';
import { Benchmark_page } from './pages/benchmark_page';
import { History_benchmark_page } from './pages/history_benchmark_page';
import { Add_user } from './pages/add_user';
import { How_to_use } from './pages/how_to_use';
// import {About} from './pages/survey_page';
import { Landing_page } from './pages/landing_page';

function App() {

  // global form connected to survey questions and dashboard
  const globalFormID = 7; // set manually here (999 for testing)


  // S T A T E   V A R I A B L E S
  // -----------------------------

  // state variables definitions and the functions how to assign their values
  const [token, setToken] = useState();
  const [userID, setUserID] = React.useState(null); // 9 or 10 is used for testing but before was null (do not push to master like this) 
  const [logo, setLogo] = React.useState(null); 
  const [employee, setEmployee] = React.useState(false); 
  const [companyName, setCompanyName] = React.useState(false); 
  const [renderEmployeeLogin, setRenderEmployeeLogin] = React.useState(false); 
  const [renderHRLogin, setRenderHRLogin] = React.useState(true); 
  const [requestInformation, setRequestInformation] = React.useState(false); 
  const [forgotPassword, setForgotPassword] = React.useState(false); 
  const [resetPassword, setResetPassword] = React.useState(false); 



  // R E A C T   H O O K S
  // ---------------------
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  // makes sure that every page starts at the top (useLocation needed)
const location = useLocation();
React.useEffect(() => {
// window.scrollTo({ top: 0, behavior : "smooth" });
// window.scrollTo({ top: 0 });
window.scrollTo(0,0);
},[location]);

  const navigate = useNavigate();

  const home_page = () => {
    navigate("/landing_page")
  }

  // change userID the moment token is defined (and changed)
  React.useEffect(() => {
    if (token != null) {
      const token_values = Object.values({ token })[0]; // get the value of the first key in token 
      setUserID(token_values.idOrganization)
      setEmployee(token_values.employee)
      setCompanyName(token_values.companyName)
    }
  }, [token]); // re-excecute hook when token changes

  // pick out the right logo
  React.useEffect(() => {
    if (userID != null) {

      fetch(`/logo?userId=${userID}`)  // HARDCODED input
        .then((res) => res.json()) // this is the http response (requiered)
        .then((json) => { setLogo(json.logo); }); // this is the object retrieved (provided by the response in the server)
    }
  }, [userID]); // re-excecute hook when token changes

  // // change userID the moment token is defined (and changed)
  // React.useEffect(() => {
  //   console.log(employee, userID)
  //   if (employee === true & userID !== null) {
  //     console.log('==================')

  // fetch(`/information_request`, {
  //   method: "POST",
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({'message': 'message uit de front-end', 'companyname': 'TestCompany'}),
  // })

  //     // survey_page_employee()
  //   }
  // }, [employee, userID]); // re-excecute hook when token changes


  // R O U T E S
  // -----------

  // need to define all pages here, because that way we can use hooks inside the pages (needed for data handling)
  const myRoutes =
    <Routes> {/* acts as a container/parent for all the individual routes that will be created in our app */}
      <Route path="/" element={Landing_page({ companyName, userID, globalFormID })} />
      <Route path="/monitor" element={Dashboard_page2({ userID, globalFormID })} />
      <Route path="/benchmark" element={Benchmark_page({ userID, globalFormID })} />
      <Route path="/benchmarkgeschiedenis" element={History_benchmark_page({ userID, globalFormID })} />
      <Route path="/vragenlijst" element={Survey_page2({ userID, globalFormID })} />
      <Route path="/medewerkervragenlijst" element={Employee_survey_page({ userID, globalFormID })} />
      <Route path="/bekijkvragenlijst" element={Hr_view_employee_survey_page({ userID, globalFormID })} />
      <Route path="/bedrijfsgegevens" element={Bedrijfsgegevens_page2({ userID })} />
      <Route path="/login" element={<Login setToken={setToken} globalFormID={globalFormID} />} />
      <Route path="/wachtwoordwijzigen" element={Change_pwd({ userID })} />
      <Route path="/gebruikersnaamwijzigen" element={Change_username({ userID })} />
      <Route path="/gebruikertoevoegen" element={Add_user({  })}  />
      <Route path="/how_to_use" element={How_to_use({  })}  />
      <Route path="/informatie_aanvraag" element={RequestInformation({  })}  />
      <Route path="*" element={Landing_page({ userID, globalFormID })} />
    </Routes>


  // L O G I N  
  // ---------
  // request_information page
  if (!token & requestInformation) {
    return (
      <RequestInformation setRequestInformation={setRequestInformation} />
    )
  }
  else if (location.pathname === "/informatie_aanvraag") {
    return (
      <RequestInformation setRequestInformation={false} />
    )
  }
  // render employee login page
  else if (renderEmployeeLogin & !renderHRLogin & !token) {
    return (
      <EmployeeLogin setToken={setToken} setRenderEmployeeLogin={setRenderEmployeeLogin} setRenderHRLogin={setRenderHRLogin} setRequestInformation={setRequestInformation} />
    )
  }
  // HR loginpage
  else if (renderHRLogin & !renderEmployeeLogin & !token) {
    return (
      <HRLogin setToken={setToken} setRenderHRLogin={setRenderHRLogin} setRenderEmployeeLogin={setRenderEmployeeLogin} setForgotPassword={setForgotPassword} setRequestInformation={setRequestInformation} />
    )
  }
  // forgot password page
  else if (!renderHRLogin & !renderEmployeeLogin & !token & forgotPassword & !resetPassword) {
    return (
      <ForgotPassword setResetPassword={setResetPassword} setForgotPassword={setForgotPassword} setRenderHRLogin={setRenderHRLogin} setRenderEmployeeLogin={setRenderEmployeeLogin} setRequestInformation={setRequestInformation} />
    )
  }
  // reset reset password page
  else if (!renderHRLogin & !renderEmployeeLogin & !token & resetPassword) {
    return (
      <ResetPassword setResetPassword={setResetPassword} setForgotPassword={setForgotPassword} setRenderHRLogin={setRenderHRLogin} setRenderEmployeeLogin={setRenderEmployeeLogin} setRequestInformation={setRequestInformation} />
    )
  }
  // upon employee login, directly go to employee survey page
  else if (employee === true & userID !== null) {
    return (
      <Employee_survey_page globalFormID={globalFormID} userID={userID} setToken={setToken} setEmployee={setEmployee} setRenderEmployeeLogin={setRenderEmployeeLogin} setRenderHRLogin={setRenderHRLogin} />
    )

    // survey_page_employee()
  }
  // else the login page to choose HR/employee login
  if (!token) {
    return (
      <Login setRenderEmployeeLogin={setRenderEmployeeLogin} setRenderHRLogin={setRenderHRLogin} setRequestInformation={setRequestInformation} />
    )
  }


  // N A V I G A T I O N   B A R
  // --------------------------- 
  let myNav =
      <div className="navbar">
        {/* <Link to="/">Home</Link> */}
        {/* <Link to="/landing_page"> Over </Link> */}
        {/* <Link to="/medewerkervragenlijst"> Vragenlijst </Link> */}
        {/* <button id="button_logout"  type='button' onClick={() => { setToken(null); setEmployee(false); setRenderEmployeeLogin(false); }}>Uitloggen </button> */}


        {/* <Link to="/test">Test</Link> */}
      </div>

  const logout = () => {
    setToken(null)
    setUserID(null)
    setRenderHRLogin(true)
    navigate("/")
  }


  if (employee === false) { 
    // create navigation bar
    myNav =
      <div className="navbar">
        {/* <Link to="/">Home</Link> */}
        {/* <Link to="/landing_page"> Over </Link> */}
        <Link to="/monitor"> Mijn dashboard </Link>
        <Link to="/vragenlijst"> Vragenlijst </Link>
        <Link to="/bedrijfsgegevens"> Mijn bedrijf </Link>
        <Link to="/how_to_use"> Hoe te gebruiken  </Link>
        <button id="button_logout"  type='button' onClick={() => { logout() }}>Uitloggen </button>

        {/* <Link to="/test">Test</Link> */}
      </div>
  }
  // if VV (userID === 14) logs in, monitor refers to benchmark_page and vragenlijst to survey_read_only
  if (userID === 14 | userID === 9) {
    myNav =
      <div className="navbar">
        {/* <Link to="/">Home</Link> */}
        {/* <Link to="/landing_page"> Over </Link> */}
        <Link to="/gebruikertoevoegen"> Gebruiker toevoegen </Link>
        <Link to="/monitor"> Mijn dashboard </Link>
        <Link to="/benchmark"> Benchmark </Link>
        <Link to="/vragenlijst"> Vragenlijst </Link>
        <Link to="/bedrijfsgegevens"> Mijn bedrijf  </Link>
        <Link to="/how_to_use"> Hoe te gebruiken  </Link>
        <button id="button_logout"  type='button' onClick={() => { logout() }}>Uitloggen </button>
 
        {/* <Link to="/test">Test</Link> */}
      </div>
  }
  

  // L O G O
  // ------- 

  // get the logo path from the DB, default is white
  // var logoPath = './images/white.png';
  let logoPath = './images/VV_logo.png';
  // if (logo) {
  //   logoPath = `./images/${logo[0].logoPath}`;
  // }


  // T H E   A P P
  // ------------- 

  return (

    <body>
    <div className="App">
    <div id="navbar-id">
      <div className="navbar-container">
        <a className="home_page" onClick={home_page}> 
        <img id="business" className="navbar-logo home-link" src={require(`${logoPath}`)} alt="Jouw Bedrijfs Logo" />
        </a>
        {myNav}
    
        </div>
    
          <div id="navbar-menu" aria-labelledby="navbar-toggle"> </div>
      
      </div>
    
    {/* <div className="routing"> */}
      {myRoutes}
    {/* </div> */}
    

   
    
     </div> 

     {/* <div className="bottom_bar">
        
        <p className="title_footer"> Vitaliteit op de werkvloer 2022 </p> 
        <a className="footer_link_home" href="https://www.vitaalvechtdal.nl/"> Go to Vitaal Vechtdal</a>
        <a className= "footer_link_privacy" href="https://www.vitaalvechtdal.nl/over/privacy"> Privacy </a> 
        <a className= "footer_link_contact" href="https://www.vitaalvechtdal.nl/contact"> Contact </a> 

    </div> */}

     </body>
     
     
  );
}

export default App;
