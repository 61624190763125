export const verzuim = [
    {
        name: 'Oktober 2021',
        'eigen bedrijf': 3.5,
        regio: 1.5,
        "jouw branche": 1.0,
    },
    {
        name: 'November 2021',
        'eigen bedrijf': 3.5,
        regio: 1.5,
        "jouw branche": 1.0,
    },
    {
        name: 'December 2021',
        'eigen bedrijf': 4.0,
        regio: 2.5,
        "jouw branche": 2.0,
    },
    {
        name: 'Januari 2022',
        'eigen bedrijf': 3.0,
        regio: 2.1,
        "jouw branche": 1.0,
    },
    {
        name: 'Februari 2022',
        'eigen bedrijf': 2.5,
        regio: 2.0,
        "jouw branche": 2.0,
    },
    {
        name: 'Maart 2022',
        'eigen bedrijf': 2.0,
        regio: 1.5,
        "jouw branche": 1.0,
    },
];

export const positieve_gezondheid = [
    {
        name: 'Dagelijks functioneren',
        'eigen bedrijf': 7,
        regio: 6,
        "jouw branche": 1,
    },
    {
        name: 'Meedoen',
        'eigen bedrijf': 5,
        regio: 6,
        "jouw branche": 4,
    },
    {
        name: 'Kwaliteit van leven',
        'eigen bedrijf': 3,
        regio: 4,
        "jouw branche": 1,
    },
    {
        name: 'Zingeving',
        'eigen bedrijf': 6,
        regio: 6,
        "jouw branche": 7,
    },
    {
        name: 'Mentaal welbevinden',
        'eigen bedrijf': 8,
        regio: 5,
        "jouw branche": 4,
    },
    {
        name: 'Lichaamsfuncties',
        'eigen bedrijf': 4,
        regio: 8,
        "jouw branche": 7,
    },
];

export const participerende_werknemers = [
    {
        name: 'Aantal werknemers dat meedoet bij het invullen van de tool?',
        'eigen bedrijf': 15,
        regio: 20,
        "jouw branche": 10,
    },
    {
        name: 'Percentage werknemers van het totaal',
        'eigen bedrijf': 50,
        regio: 60,
        "jouw branche": 40,
    },
    {
        name: 'Percentage werknemers waar aan individuele en leefstijlfactoren aandacht is besteed',
        'eigen bedrijf': 30,
        regio: 40,
        "jouw branche": 10,
    },
    {
        name: 'Percentage werknemer waar leef, woon en werkomstandigheden zijn veranderd',
        'eigen bedrijf': 60,
        regio: 60,
        "jouw branche": 70,
    },
];