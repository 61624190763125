import React, { useState } from 'react';
import './pages.css';

import { useNavigate } from "react-router-dom";

// import { Alert, AlertTitle } from '@mui/material/';
// import FlashMessage from 'react-flash-message';

import PropTypes from 'prop-types';
// import routes from '../../../back-end/config/routes';


// login pages that gets exported
export function Change_pwd(props) {

  const navigate = useNavigate();
  const company_info_page = () => {
    navigate("/bedrijfsgegevens")
  }

    // state variables definitions and the functions how to assign their values
    const [pwdCurrent, setPwdCurrent] = useState();
    const [pwdNew, setPwdNew] = useState();
    const [pwdConfirm, setPwdConfirm] = useState();
    const [error, setError] = useState("");

    const userId = props.userID
    

    // function that calls backend for login (backend path hardecoded for now)
    async function changePwd(credentials) {
        // changePwd api function checks if current password is correct and then updates with new password
        const message = await fetch('/changePwd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        return message
    }   

  // function that handels the login button and sets the token if login is correct
  const handleSubmit = async (e) => {
    //   Make sure no fields are left empty
    e.preventDefault();

    const pwdRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/;

    // Check if new password and confirmation are equal
    if (pwdNew !== pwdConfirm) {
        setError(`Wachtwoorden komen niet overeen`)
    }
    // check if password is strong
    else if (pwdRegex.exec(pwdNew)=== null){
      setError(`Gebruik een wachtwoord van 8 tot 15 karakters, waarvan 1 hoofdletter, 1 kleine letter en een speciaal teken`)
    }
    else {
        const response = await changePwd({
            userId,
            pwdCurrent,
            pwdNew
          });
        //   status =200 means successful
        if (response.status === 200) {
            setError("Wachtwoord gewijzigd")
        }
        // Else, the current password is wrong, or something went wrong
        else {
            setError("Onjuist huidig wachtwoord")
        }
    }
  }

  return (
    <body className="account-wrapper">
    <div className="login-wrapper">


    <div className="changes-background-password">  
      <div className="account-changes-password">Wachtwoord wijzigen </div>
      <form className="password-block" onSubmit={handleSubmit}>
        <label>
          <h3 className="password-title" style={{ 'textAlign': 'center' }}>Huidig wachtwoord</h3>
          <input className="center-block" autoFocus required={true} type="password" placeholder="Huidig Wachtwoord" onChange={e => setPwdCurrent(e.target.value)} />
        </label>
        <label>
          <h3 className="password-title" style={{ 'textAlign': 'center' }}>Nieuw wachtwoord</h3>
          <input className="center-block" required={true} type="password" placeholder="Nieuw Wachtwoord" onChange={e => setPwdNew(e.target.value)} />
        </label>
        <label>
          <h3 className="password-title" style={{ 'textAlign': 'center' }}>Bevestig wachtwoord</h3>
          <input className="center-block" type="password" placeholder="Bevestig Wachtwoord" onChange={e => setPwdConfirm(e.target.value)} />
        </label>
        <div>
          <br />
          <button className='button_password-account'  type="submit">Wachtwoord wijzigen</button>
        </div>
        <div>
          <br />
          <a className="button-password_page" onClick={company_info_page}>   ←  Ga terug   </a>
        </div>
      </form>
      </div>


      {/* <div style={{ "marginBottom": "-2.5cm" }} >   </div> */}
      <div  className="marginBottom" style={{ "marginBottom": "-9vw" }} >   </div>

      {(error !== "") ? (<div className='error'>
        <h3 className="error-password_1"> {error} </h3>
      </div>) : ""}

    </div>
    </body> 
  )
}
