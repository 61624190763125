// imports always at the top
import * as standard_questions from '../survey_questions'

const branches = ['Gezondheidszorg en welzijn',
    'Handel en dienstverlening',
    'ICT',
    'Justitie, veiligheid en openbaar bestuur',
    'Landbouw, natuur en visserij',
    'Media en communicatie',
    'Onderwijs, cultuur en wetenschap',
    'Techniek, productie en bouw',
    'Toerisme, recreatie en horeca',
    'Transport en logistiek',
    'Industrie',
    'Anders'
]
export const survey_json1 = {
    focusFirstQuestionAutomatic: true,
    "elements": [
        {
            "type": "panel",
            "title": "Bedrijfsgegevens",
            
            "elements": [
                standard_questions.standard_question_text("companyName", "Naam bedrijf"),
                standard_questions.standard_question_number("zipCode", "Eerste 4 cijfers van de postcode van het bedrijf", "zip"),
                standard_questions.standard_question_number("estYear", "Jaar van oprichting", 'year'),
                standard_questions.standard_question_radio("industry", "Het bedrijf is actief in de branche:", branches),
            ]
        },
    ]
}