// import survey modules
import { SurveyComponent } from '../components/survey'

import * as standard_questions from '../survey_input/survey_questions'

import logo from '../images/vitaal-vechtdal.svg'

import React from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const downloadFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


// the function which will be rendered
export function Hr_view_employee_survey_page({globalFormID, userID, setToken, setEmployee, setRenderEmployeeLogin, setRenderHRLogin}) {
// setToken, setEmployee, setRenderEmployeeLogin

    const navigate = useNavigate();
    const back_to_hr = () => {
        navigate("/vragenlijst")
    }

    // question themes that will appear at the top of the survey 

    const [forms, setForms] = React.useState(null); // forms related to global Id from DB
    const location = useLocation(); // also rerender results when switching pages

    // The questions will be pulled frm the DB
    // let globalFormID = props.globalFormID; // pull active formID from properties 
    // let userId = props.userID; // pull userID from the properties
    let userId = userID; // pull userID from the properties
    let formId;
    if (userId == 70 || userId == 91) {
        formId = 2
    }
    else { 
        formId = globalFormID
    }

    // fetch survey IDs from data base (through api)
    React.useEffect(() => {
        if (formId != null) {
            // console.log("fetch survey");
            fetch(`/survey_forms?globalFormId=${formId}`)
                .then((res) => res.json()) // this is the http response (required)
                .then((json) => setForms(json.result)); // this is the object retrieved (provided by the response in the server)
        }
    }, [formId]); // excecutes hook when arguments is [] changes


    // create survey if forms are defined (otherwise will run into errors)
    if (forms) {
        // R E A D   S U R V E Y   F R O M   D B 
        // --------------------------------------
        let json_surveys = {};
        let currentTopic = null
        let questions = []  // list with survey.js elements. Cleared after each panel
        let surveyList = []
        let currentFormSection = null
        const formId = forms[0]['idForm']
        let firstRatingQuestion = true

        questions.push({type:'html', name:'box', html:{default:`<div class="survey_pgh_note">Let op, je bevindt je in de alleen-lezen modus van de medewerkersvragenlijst </div>`}})

        // loop over all questions
        for (const iQuestion in forms) {
            const question = forms[iQuestion];
            const questionTopicDescriptionEmployee = question.topicDescriptionEmployee;   // the topicDescription of the question
            const questionType = question.questionType; // question type relates to type of graph
            const questionId = question.idQuestion; // the id of the question
            const questionTopic = question.topic;   // the topic of the question
            const questionTitle = question.questionEmployee;  // the question for HR people
            const resultFormat = question.resultFormat; // not used
            const answers_json = question.answers_json; // only non-null for specific question types
            const QuestionFormSection = question.FormSection  // the formSection of the current question. e.g. 'Standaard vragen' or 'Thema vragen'
            const questionFormSectionDescription = question.formSectionDescriptionEmployee  // the formSectionDescription of the current question. e.g. 'Standaard vragen' or 'Thema vragen'
            const formTitle = question.name
            let questionDescription = question.descriptionEmployee

            if (questionDescription === 'null') {
                questionDescription = null
            }
            // ============================== CREATE TITLES AND PANELS (STEP 2 - AFTER GATHERING DATA) =============================================

            if (!Object.keys(json_surveys).includes('elements')) {
                json_surveys['elements'] = []
            }
            // Update the current topic tracker. 
            if (!currentTopic) {
                currentTopic = questionTopic
            }
            // If the topic from the new question is different from the current topic we're gathering, push the panel with the questions
            if (questionTopic !== currentTopic) {
                json_surveys['elements'].push({ 'type': 'panel', 'elements': questions })
                questions = []
                if (questionTopicDescriptionEmployee !== null){
                    questions.push({type:'html', name:'box', html:{default:`<div class="survey_pgh"><p>${questionTopicDescriptionEmployee} </p></div>`}})
                }
                currentTopic = questionTopic
                firstRatingQuestion = true
            }
            if (currentFormSection !== QuestionFormSection) {           // Should be standaard vragen or thema vragen --------- does not work yet
                if (QuestionFormSection) {
                    // insert questionFormSection and description as html to panel
                    questions.push({type:'html', name:'box', html:{default:`<div class="survey_description">${QuestionFormSection}</div><br><div class="survey_pgh">${questionFormSectionDescription} </div>`}})
                }
                // else {
                //     questions.push({type:'html', name:'box', html:{default:`<div class="survey_topic_title">Thema vragen</div>`}})
                // }
                
                currentFormSection = QuestionFormSection
            }
            // if (questionTopic !== currentTopic) {
            //     questions.push({type:'html', name:'box', html:{default:`<div class="survey_topic_title">${questionTopic}</div>`}})
            // }
            // Set overall formtitle. Will be rendered on top of page when form is created
            if (!Object.keys(json_surveys).includes('title')) {
                json_surveys['title'] = formTitle
            }
            // render description for the first rating/liker question 
            if (questionType === "likert" & firstRatingQuestion === true) {
                // hardcode lines
                if (questionTitle.startsWith("Ervaar jij problemen tijdens het samenwerken met collega’s die een stuk ouder en/of jonger zijn dan jou?")) {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Geef een cijfer tussen de 1 (heel veel) en de 10 (helemaal niet)</p></div>`}})
                }
                else if (questionTitle.startsWith("De sfeer op de werkvloer")) {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Geef een cijfer tussen de 1 (helemaal niet belangrijk) en de 10 (enorm belangrijk). Als een vraag niet van toepassing is voor jouw functie, vul dan 1 in.</p></div>`}})
                }
                else if (questionTitle.startsWith("Ik kan mijn werk") || questionTitle.startsWith("Mijn leidinggevende begrijpt mijn") || questionTitle.startsWith("Ik weet bij welke organisaties")) {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Hieronder staat een aantal zinnen.<br>Geef elke zin een cijfer tussen 1 en 10. Een 1 betekent dat je het <u>helemaal niet eens</u> bent met de zin. Een 10 betekent dat je het <u>helemaal eens</u> bent met de zin. Als de zin niet over jou gaat, vul dan 1 in.</p></div>`}})
                }
                else {
                    questions.push({type:'html', name:'box', html:{default:`<div class="description_topic"><p className="top_box">Hieronder staat een aantal zinnen.<br>Geef elke zin een cijfer tussen 1 en 10. Een 1 betekent dat je het <u>helemaal niet eens</u> bent met de zin. Een 10 betekent dat je het <u>helemaal eens</u> bent met de zin.</p></div>`}})
                }
                firstRatingQuestion = false
            }
            else if (questionType !== "likert") {
                firstRatingQuestion = true
            }
            if (questionDescription) {
                questions.push({type:'html', name:'box', html:{default:`<div class="description_position">${questionDescription} </div>`}}) 
            }
            // ============================== GATHERING DATA (SURVEY.JS ELEMENTS ARE MADE HERE) (STEP 1 - ) =============================================
            // questions are added to list questions, which is contains the survey.js elements
            if (questionType === "percentage") {
                questions.push(standard_questions.standard_question_number(questionId, questionTitle, questionDescription))
                
            }
            else if (questionType === "likert") {
                questions.push(standard_questions.standard_question_rating(questionId, questionTitle, questionDescription))
            }
            else if (questionType === "option") {
                // convert string with options to array
                let l = [];
                l = l.concat(answers_json.slice(2, -2).split(','))

                questions.push(standard_questions.standard_question_radio(questionId, questionTitle, l, questionDescription))
            }
            else if (questionType === "multiple_result") {
                // parse the title in correct format
                const questionTitle_object = JSON.parse(questionTitle);
                let questionTitle_multiple = Object.keys(questionTitle_object)[0];;
                let answers_json_multiple = questionTitle_object[questionTitle_multiple];
                questions.push(standard_questions.standard_questions_matrixNumbers(questionId, questionTitle_multiple, answers_json_multiple, question.startDate))
            }

            // hardcode for 'generaties verbinden
            if (questionTitle.startsWith("Hieronder staan verschillende onderwerpen op het gebied van persoonlijke ontwikkeling.")) {
                questions.push({type:'html', name:'box', html:{default:`<div class="description_position"><em>Deze vragenlijst is anoniem en wordt gebruikt om een beeld te krijgen van mogelijke aandachtsgebieden. Wanneer persoonlijke ontwikkeling bijdraagt aan jouw werkgeluk, bespreek dit dan met jouw leidinggevende.</em> </div>`}}) 
            }


        }
        // ================== ADD FINAL TOPIC PANEL AFTER LOOP HAS ENDED ========================
        json_surveys['elements'].push({ 'type': 'panel', 'elements': questions })

        if (forms[0].name === "Vragenlijst Werk en Mantelzorgtaken") {
            questions = []
            questions.push({type:'html', name:'box', html:{default:`<div class="survey_pgh"><p>Tot slot. Ken jij een mantelzorger in jouw omgeving? Praat eens met hem/haar over mantelzorgtaken. En geef hiervoor een compliment. </p></div>`}})
            json_surveys['elements'].push({ 'type': 'panel', 'elements': questions })
        }
        if (forms[0].name === "Vragenlijst Stress, wat doet het met jou?") {
            questions = []
            questions.push({type:'html', name:'box', html:{default:`<div class="survey_pgh"><p>Je lichaam geeft het aan als het niet goed gaat. Vaak weet je dit wel. Maar doen wat voor je lichaam het beste is, is niet altijd makkelijk. Vooral niet als je al (te) lang aan het ‘overleven’ bent. Toch willen we je aanmoedigen om in actie te komen. Bij chronische stress put je je lichaam uit. Dit kan uiteindelijk leiden tot blijvende schade.  <br><br>Nu al weten wat je kunt doen? Kijk dan op <a href="https://www.vitaalvechtdal.nl/helpmeopweg" target="_blank" rel="noopener noreferrer">www.vitaalvechtdal.nl/helpmeopweg</a>.</p></div>`}})
            json_surveys['elements'].push({ 'type': 'panel', 'elements': questions })
        }

        surveyList.push(<SurveyComponent key={formId} json={json_surveys} surveyId={formId} userId={userId} employee = {true} />)            
        

        // R E T U R N  T H E   S U R V E Y
        // --------------------------------

        return (
            // <div style="border-type=solid; border-color=white; border-right-width=50pt">
            <div>
                <div className='back_to_hr_1'>
                    <a className="button-view_employee_survey_2" onClick={back_to_hr}> ←  Ga terug </a>
                </div>
                <div className='tot'>


                    <div className="survey">
                    <fieldset disabled="disabled">
                        {surveyList}
                        </fieldset>
                    </div>
                </div>
                <button className="Afdrukken" onClick={() => downloadFile("/vragenlijsten/vragenlijst-stress.pdf", "Vragenlijst Stress.pdf")}>Download vragenlijst</button>


                
            </div>
        )
    }

    // R E T U R N   E R R O R    S U R V E Y   W H E N   N O   F O R M S   A R E   D E F I N E D
    // ------------------------------------------------------------------------------------------

    else {
        return (
            <div className="survey">
                <h1>Bezig met het laden van de vragenlijst. </h1>
            </div>
        )
    }
};


